<template>
    <v-container class="w-container-pdf v-overflow piano_orario" style="padding-top:59px">

        <div>
            <canvas ref="pdfCanvas"></canvas>
        </div>

    </v-container>
</template>

<script>

import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.loadPdf("http://emtool.local/public/eu_gdpr_2024.pdf");

    },

    data: () => ({

        pdfUrl: "http://emtool.local/public/documents/QR_USER_Mauro_Cipolla.pdf",
        pageNumber: "1",

    }),

    methods: {

        async loadPdf(src) {


            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
            const loadingTask = pdfjsLib.getDocument(src);
            const pdf = await loadingTask.promise;
            const page = await pdf.getPage(1);

            const scale = 1.5;
            const viewport = page.getViewport({ scale });

            const canvas = this.$refs.pdfCanvas;
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            await page.render(renderContext).promise;





        }

    }

})
</script>

<style>
.w-container-pdf {
    width: 1400px !important;
    min-width: 1400px !important;
    max-width: 1400px !important;
    padding: 0px;
    z-index: 3;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}


.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetAziendeClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetDipartimento {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetUtentiAssociati {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetPianoOrario {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemAziendaCliente {
    margin-left: 15px;
}

.itemCliente {
    margin-left: 15px;
}

.itemDipartimento {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.itemUtenteAssociato {
    margin-left: 15px;
}


.itemPianoOrarioCodice {
    margin-left: 15px;
}

.itemPianoOrarioDescrizione {
    margin-left: 15px;
}



.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}

.piano_orario .v-label {
    font-size: 1em !important
}

.piano_orario .v-input {
    font-size: 10px !important;
    padding: 10px !important;
}

.piano_orario ion-select {
    font-size: 10px !important;
}



.cont_piano_orario #myTablePianoOrario {
    margin-right: 3px;
}

.cont_piano_orario table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_piano_orario table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_piano_orario th {
    padding-left: 5px !important;
}

.cont_piano_orario ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_piano_orario .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}

.cont_piano_orario #myTablePianoOrario_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
}

.cont_piano_orario div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_piano_orario #myTablePianoOrario_wrapper {
    padding-top: 0px;
}

.text_title {
    background-color: #00a6ff;
    color: white;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 82vw
}

.cont_piano_orario .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.first_column_piano_orario {
    width: 4%;
    text-align: left;
}

.second_column_piano_orario {
    width: 32%;
    text-align: left;
    font-weight: bold;
    font-size: 10px;
}

.third_column_piano_orario {
    width: 32%;
    text-align: left;
    margin-left: -6px;
    font-weight: bold;
    font-size: 10px;
}

.fourth_column_piano_orario {
    margin-left: -5px;
    font-weight: bold;
    font-size: 10px;
}


@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-pdf {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

    .cont_piano_orario .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_piano_orario #myTablePianoOrario {
        /* margin-top:54px; */
    }

    .cont_piano_orario #myTablePianoOrario_filter {
        top: 56px;
        position: fixed;

    }


    .first_column_piano_orario {
        width: 19%;
        text-align: left;
    }

    .second_column_piano_orario {
        width: 28%;
        text-align: left;
        font-weight: bold;
        font-size: 10px;
    }

    .third_column_piano_orario {
        width: 28%;
        text-align: left;
        margin-left: -6px;
        font-weight: bold;
        font-size: 10px;
    }

    .fourth_column_piano_orario {
        margin-left: -5px;
        font-weight: bold;
        font-size: 10px;
    }




}


@media screen and (max-width: 1024px) {


    .cont_piano_orario #myTablePianoOrario {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_piano_orario #myTablePianoOrario_filter {
        top: 56px;
        position: fixed;

    }

    .cont_piano_orario .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_piano_orario #myTablePianoOrario_wrapper {
        min-width: initial;
        min-width: 95vw !important;
        max-width: 95vw !important;
        width: 95vw !important;
        overflow-x: hidden !important;
    }

    .cont_piano_orario .cl_num {
        margin-left: 16px !important;
    }





}
</style>
