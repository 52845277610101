<template>
    <v-container class="w-container" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont editDomandeCorsoOnline' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                                                                                    background-color: white;
                                                                                    color: black;
                                                                                    font-weight: bold;
                                                                                    padding: 4px;
                                                                                    font-size: 10px;
                                                                                    min-width:100vw;
                                                                                    border-color: #0084f3">{{ titolo }}
                </div>

            </v-col>

        </v-row>


        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">


                    <v-textarea v-model="domanda.descrizione" label="Descrizione" maxlength="500">
                    </v-textarea>



                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_id_step" style="cursor:pointer">
                        <ion-item id="itemStep"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Tipologia </ion-label>

                            <ion-select id="id_tipologia" name="id_tipologia" class='id_tipologia' cancel-Text="Chiudi"
                                done-Text="" :value="domanda.id_tipologia" interface="action-sheet">

                                <ion-select-option v-for="item in tipologie" :key="item.id" :value='item.id'> {{
                                                                                        item.descrizione }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>






        </v-form>

        <div class="contpopupore">

            <template>
                <modal name="popupOre" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Ore</label>

                            <div id="d_ore_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="oreModulo" :options="{
                                                                                        digitGroupSeparator: '.',
                                                                                        decimalCharacter: ',',
                                                                                        decimalCharacterAlternative: '.',
                                                                                        currencySymbol: '',
                                                                                        currencySymbolPlacement: 's',
                                                                                        roundingMethod: 'U',
                                                                                        minimumValue: '0'
                                                                                    }" ref="fldOreModulo" id="fldOreModulo"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-ore" contain src="@/assets/btn_confirm3.png"
                                    max-width="32" max-height="32" style="cursor:pointer;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-ore" contain src="@/assets/btn_cancel3.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupposizione">

            <template>
                <modal name="popupPosizione" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Posizione</label>

                            <div id="d_ore_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="posizioneModulo" :options="'integer'"
                                                id="fldPosizioneModulo"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-posizione" contain src="@/assets/btn_confirm3.png"
                                    max-width="32" max-height="32" style="cursor:pointer;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-posizione" contain src="@/assets/btn_cancel3.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



    </v-container>
</template>

<script>

import apicorsi from "../utils/corsi/apicorsi";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import Vue from "vue";
import VModal from 'vue-js-modal';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        Vue.use(VModal, {
            dialog: true
        });

        this.$root.$children[0].checkLogout();

        /*eslint-disable no-undef*/

        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;

        // this.cur_id = this.$route.params.id;

        // this.$root.$children[0].initDivError("#d_ore", "Ore richieste");

        this.setupButtons();

        if (this.$route.params.id == 0) {

            this.titolo = "Aggiungi Domanda";

            setTimeout(() => {
                this.initInsert();
            }, 100);

        }



        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Domanda";

            setTimeout(() => {
                this.syncDomanda();
            }, 100);

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/domandeCorsiOnline"
                    });
                    break;

                default:
                    break;
            }
        });



        $(document).ready(function () {


            $('body').on('ionChange', '.id_tipologia', function (event) {
                console.log(event);

                pointerVue.domanda.id_tipologia = this.value;


            });

        });





    },

    components: {
        VueAutonumeric,
    },


    data: () => ({




        tipologie: [],

        fasi: [],

        showPicker2: false,

        oreModulo: null,
        posizioneModulo: null,

        currentFile: null,
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",
        titolo: "Aggiungi Corso Online",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        modulo: {},

        step_flusso: { id_corso: "", id_step_flusso: "", ordinamento: "" },

        corso: { codice: "", nome: "", data_inizio: "", data_fine: "", descrizione: "", luogo: "" },


        domanda: { id_corso: "", descrizione: "", id_tipologia: "" },



        luoghi: [
            { id: "Ufficio", name: "Ufficio" },
            { id: "Smartworking", name: "Smartworking" }
        ],

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        codeRules: [
            (v) => !!v || "Codice richiesto",
        ],

        descRules: [
            (v) => !!v || "Descrizione richiesta",
        ],

        select: null,
        checkbox: false,
    }),

    methods: {

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/alertduedate/" + nome_metodo;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/salva_smartform.png",
                    title: "salva",
                    width: 30
                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/back_smartform.png",
                    title: "Indietro",
                    width: 30
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            var errore = "";

            that.domanda.id_corso = window.$cookies.get("sel_filter_corsi");

            console.log("DOMANDA: ", that.domanda);


            if (that.domanda.descrizione == "") {
                errore = "Specificare il valore della descrizione";
            }

            if (errore == "") {

                if (that.domanda.id_tipologia == "") {
                    errore = "Specificare il valore della tipologia";
                }

            }



            if (errore == "") {

                console.log("ok");


                that.saveData();


            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }



        },

        saveData: async function () {


            var that = this;

            var id_rec = that.$route.params.id;


            if (id_rec == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apicorsi.insertDomanda(
                    that.domanda
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertDomanda", res);


                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/domandeCorsiOnline"
                            });

                        }, 200);

                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });


                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }

            if (id_rec > 0) {

                console.log("ok");

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response2 = await apicorsi.updateDomanda(
                    that.domanda,
                    id_rec
                ).then((res) => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateDomanda", res);

                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/domandeCorsiOnline"
                            });

                        }, 200);

                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });


                    }

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }



        },

        initInsert: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.initInsertDomanda(
                v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsertDomanda", res);

                that.tipologie = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncDomanda: async function () {


            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.editDomanda(
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editDomanda", res);

                that.tipologie = res.data.Tipologie;

                setTimeout(() => {

                    that.domanda = res.data.Result;

                }, 100);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>


textarea {
    resize: none!important;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
