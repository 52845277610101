import axios from 'axios'

function getUrlDomainGeneral(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


}

var main_domain = getUrlDomainGeneral("");

class APIModuli {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    async insertModulo(modulo, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in modulo) {
            formData.append(key, modulo[key]);
        }

        return axios.post(this.getUrlDomain("SFAddModuliApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async editModulo(id, user)
    {
  
      return axios.get(this.getUrlDomain("SFEditModuliApi") + "/" + id + "?username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;
  
    }
  
    async updateModulo(modulo, id, user)
    {
  
      let formData = new FormData();
  
      for ( var key in modulo ) {
         formData.append(key, modulo[key]);
      }
  
      return axios.post(this.getUrlDomain("SFUpdateModuliApi") + "/" + id + "?username=" + user, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;
  
    }
  
    async deleteModulo(id, user)
    {
  
      return axios.get(this.getUrlDomain("SFDeleteModuliApi") + "?id=" + id + "&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;
  
    }
  
    async getFilters(user)
    {
  
      let formData = new FormData();
  
      formData.append("username", user);

      return axios.post(this.getUrlDomain("SFGetFiltersModuliApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;
  
    }


    async getNomiByCodice(id_codice, user)
    {
  
      let formData = new FormData();
  
      formData.append("username", user);
      formData.append("id_codice", id_codice);

      return axios.post(this.getUrlDomain("SFGetNomiByCodiceModuliApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;
  
    }

    async getTotalHour(id_codice, nome, user)
    {
  
      let formData = new FormData();
  
      formData.append("username", user);
      formData.append("id_codice", id_codice);
      formData.append("nome", nome);

      return axios.post(this.getUrlDomain("SFGetTotHoursModuliApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;
  
    }

    async updatePosition(id, v_posizione)
    {
  
      let formData = new FormData();
  
      formData.append("v_id", id);
      formData.append("v_posizione", v_posizione);

      return axios.post(this.getUrlDomain("SFUpdPosModuliApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;
  
    }

}

export default new APIModuli({
    url: main_domain
})
