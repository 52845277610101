<template>
    <v-container class="w-container-pdf-sf v-overflow-view-pdf" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                                                                                    background-color: white;
                                                                                    color: black;
                                                                                    font-weight: bold;
                                                                                    padding: 4px;
                                                                                    font-size: 10px;
                                                                                    min-width:100vw;
                                                                                    border-color: #0084f3">{{ titolo }}
                </div>

            </v-col>

        </v-row>



        <v-form style="" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12" style="display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center">

                    <div style="width: 94%;height: 75vh;overflow-y: auto!important;overflow-x: auto!important;">

                        <div id="pdf-container" @wheel="handleMouseScroll"></div>


                    </div>


                </v-col>

            </v-row>



        </v-form>




    </v-container>
</template>

<script>

import Vue from "vue";
import VModal from 'vue-js-modal';

import apicorsi from "../utils/corsi/apicorsi";

import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';



import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        Vue.use(VModal, {
            dialog: true
        });

        window.countScroll = 0;



        /*eslint-disable no-undef*/

        var pointerVue = this;

        this.verifyStep();

        this.setupButtons(false);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {


                case "btn_back":

                    history.back();

                    break;

                case "btn_next_pdf":

                    this.goNextStep();

                    break;


                default:
                    break;
            }
        });



        $(window).resize(function () {

            pointerVue.centerCanvas();


        });


        setTimeout(() => {

            this.initViewPdf();

        }, 100);


    },


    data: () => ({


        titolo: "Presentazione Pdf",

        pdfUrl: "",

    }),

    methods: {


        centerCanvas: function () {

            var windowWidth = $(window).width();


            console.log("WINDOW_WIDTH: ", windowWidth);

            var canvasWidth = Math.ceil((69 / 100) * windowWidth);


            $(".clCanvas").css("width", canvasWidth + "px");

        },



        handleMouseScroll(event) {






            // Ottenere l'informazione dello scorrimento
            const deltaY = event.deltaY;


            // console.log("EVENT: ", event);


            //console.log("DELTA: ", deltaY);

            //console.log("CLIENT: ", event.clientY);

            // var scrollY = window.scrollY || window.pageYOffset;
            // console.log("Posizione Y dello scroll: " + scrollY);

            // Esegui la logica in base allo scorrimento
            if (deltaY > 0) {
                //console.log("Scroll verso il basso");

                if (window.countScroll < 191) {

                    window.countScroll = window.countScroll + 1;


                }


            } else if (deltaY < 0) {
                //console.log("Scroll verso l'alto");

                if (window.countScroll > 0) {


                    window.countScroll = window.countScroll - 1;


                }


            }

            console.log("SCROLL: ", window.countScroll);


            if (window.countScroll < 191) {

                this.setupButtons(false);

            }
            else {
                this.setupButtons(true);


            }



        },


        async loadPdf() {

            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

            const pdfContainer = document.getElementById('pdf-container');
            pdfContainer.innerHTML = '';

            try {
                const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1.5 });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');


                    canvas.classList.add('clCanvas');

                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };

                    await page.render(renderContext).promise;

                    pdfContainer.appendChild(canvas);
                }
            } catch (error) {
                console.error('Error loading PDF: ', error);
            }
        },

        verifyStep: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.verifyStep(
                window.tokenuser
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from verifyStep", res);

                if (res.data.Result == "ERROR") {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });


                    setTimeout(() => {


                        if (res.data.PageNextStep == "vediDomande") {

                            var token_step = window.$cookies.get("username");
                            token_step = token_step + "|" + res.data.IdCorso.toString();
                            token_step = token_step + "|" + res.data.IdNextStep.toString();
                            token_step = btoa(token_step);


                            router.push({
                                path: "/vediDomande/" + token_step
                            });


                        }

                        if (res.data.PageNextStep == "vediPdf") {

                            var token_step2 = window.$cookies.get("username");
                            token_step2 = token_step2 + "|" + res.data.IdCorso.toString();
                            token_step2 = token_step2 + "|" + res.data.IdNextStep.toString();
                            token_step2 = btoa(token_step2);


                            router.push({
                                path: "/vediPdf/" + token_step2
                            });


                        }

                        if (res.data.PageNextStep == "corsiOnLine") {

                            router.push({
                                path: "/corsiOnLine"
                            });

                        }

                    }, 10);




                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



        goNextStep: async function () {



            var that = this;

            that.$swal
                .fire({

                    title: "Conferma",
                    html: "Prima di confermare assicurati di aver recepito correttamente tutte le informazioni in quanto, una volta cliccato sulla spunta di conferma ti verranno presentate le domande di verifica apprendimento e le slides non saranno più fruibili.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicorsi.goNextStep(
                            window.tokenuser
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from goNextStep", res);

                            if (res.data.Result == "OK") {

                                var token_step = window.$cookies.get("username");
                                token_step = token_step + "|" + res.data.IdCorso.toString();
                                token_step = token_step + "|" + res.data.IdNextStep.toString();
                                token_step = btoa(token_step);

                                window.tokenuser = token_step;

                                if (res.data.PageNextStep == "vediDomande") {



                                    setTimeout(() => {

                                        router.push({
                                            path: "/vediDomande"
                                        });

                                    }, 100);



                                }

                                if (res.data.PageNextStep == "vediPdf") {



                                    setTimeout(() => {

                                        router.push({
                                            path: "/vediPdf"
                                        });

                                    }, 100);


                                }

                            }
                            else {

                                that.$swal({
                                    icon: "warning",
                                    text: res.data.ErrDetails,
                                    showConfirmButton: false,
                                    timer: 3000
                                });

                                setTimeout(() => {

                                    router.push({
                                        path: "/" + res.data.PageNextStep
                                    });

                                }, 3000);

                            }


                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("ERrori", "Non è stato possibile salvare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });



        },

        initViewPdf: async function () {

            var that = this;

            var v_id = window.tokenuser;

            var v_id_dec = atob(v_id);

            var elements = v_id_dec.split("|");

            console.log("ELEMENTS: ", elements);


            //$("#iframe-pdf-page").attr("src", elements[3]);

            that.pdfUrl = elements[3];


            setTimeout(() => {

                that.loadPdf();

            }, 100);


        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },


        setupButtons: async function (v_enable) {

            setTimeout(() => {



                var pulsantis = [];

                if (v_enable) {


                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_next_pdf",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png",
                        title: "Conferma e vai allo step successivo",
                        width: 30
                    });


                }




                pulsantis.push({
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/back_smartform.png",
                    title: "Indietro",
                    width: 30

                });


                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },




    }

})
</script>

<style>
.v-overflow-view-pdf {

    overflow-y: auto !important;
    padding-bottom: 115px !important;
    max-height: 100vh !important;
    overflow-x: auto !important;

}


canvas {
    border: 1px solid #c7c4a9;
    margin-top: 10px;
}

#pdf-container {
    display: flex !important;
    flex-direction: column !important;
    /* align-items: center !important; */
}


.style-iframe-contenitor {
    display: flex;
    justify-content: center;
}

.style-iframe {
    width: 94vw;
    height: 73vh;
    min-width: 94vw;
    max-width: 94vw;
    min-height: 73vh;
    max-height: 73vh;
}

.w-container-pdf-sf {
    width: 1400px !important;
    min-width: 1400px !important;
    max-width: 1400px !important;
    padding: 0px;
    z-index: 3;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-pdf-sf {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
