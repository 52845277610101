<template>
    <v-container class="w-container edit-revisore" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                                                                                    background-color: white;
                                                                                    color: black;
                                                                                    font-weight: bold;
                                                                                    padding: 4px;
                                                                                    font-size: 10px;
                                                                                    min-width:100vw;
                                                                                    border-color: #0084f3">{{ titolo }}
                </div>

            </v-col>

        </v-row>


        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="documento.data" label="Data" persistent-hint readonly v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="documento.data" no-title @input="showPicker = false"
                            locale="it"></v-date-picker>
                    </v-menu>

                </v-col>

            </v-row>






            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field v-model="documento.descrizione" label="Descrizione"></v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <span class="files">
                        <label style="margin-left:11px!important;font-size:11px">Documento</label>
                        <input type="file" id="input-file-logo" name="logo" accept=".pdf,.doc,.docx,.xls,.xlsx"
                            class="dropify" data-max-width="6610" data-max-height="6200" />
                    </span>

                </v-col>


            </v-row>



        </v-form>

        <div class="contpopupore">

            <template>
                <modal name="popupOre" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Ore</label>

                            <div id="d_ore_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="oreModulo" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldOreModulo" id="fldOreModulo"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-ore" contain src="@/assets/btn_confirm3.png" max-width="32"
                                    max-height="32" style="cursor:pointer;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-ore" contain src="@/assets/btn_cancel3.png" max-width="30"
                                    max-height="30" style="cursor:pointer;color:black" transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupposizione">

            <template>
                <modal name="popupPosizione" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Posizione</label>

                            <div id="d_ore_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="posizioneModulo" :options="'integer'"
                                                id="fldPosizioneModulo"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-posizione" contain src="@/assets/btn_confirm3.png"
                                    max-width="32" max-height="32" style="cursor:pointer;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-posizione" contain src="@/assets/btn_cancel3.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



    </v-container>
</template>

<script>

import apicorsi from "../utils/corsi/apicorsi";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import Vue from "vue";
import VModal from 'vue-js-modal';
import apirevisore from "../utils/revisore/apirevisore";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;

        // this.cur_id = this.$route.params.id;

        // this.$root.$children[0].initDivError("#d_ore", "Ore richieste");

        this.setupButtons();

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Documento Revisore";

            setTimeout(() => {
                this.syncDocRevisore();
            }, 100);

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/revisore"
                    });
                    break;

                default:
                    break;
            }
        });



        $(document).ready(function () {


            $('body').on('change', '#input-file-logo', function () {

                console.log(("EVENTO CHANGE INPUTFILELOG"));

                pointerVue.toDoValidate = 1;


            });





            $('body').on('ionChange', '.luogo', function (event) {
                console.log(event);

                pointerVue.corso.luogo = this.value;


            });


            $('body').on('click', '#d_ore', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupOre');

            });

            $('body').on('click', '.btn-confirm-ore', function (event) {
                console.log(event);

                pointerVue.modulo.ore_totali = pointerVue.oreModulo;

                pointerVue.$root.$children[0].setFieldActive("#d_ore");

                pointerVue.$modal.hide('popupOre');

            });

            $('body').on('click', '.btn-cancel-ore', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupOre');

            });



            $('body').on('click', '#d_posizione', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPosizione');

            });


            $('body').on('click', '.btn-confirm-posizione', function (event) {
                console.log(event);

                pointerVue.modulo.position = pointerVue.posizioneModulo;

                pointerVue.$root.$children[0].setFieldActive("#d_posizione");

                pointerVue.$modal.hide('popupPosizione');

            });

            $('body').on('click', '.btn-cancel-posizione', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPosizione');

            });


            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                //$(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")

                $(".dropify-clear").remove();
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);


                });

            }, 100);



        });





    },

    components: {
        VueAutonumeric,
    },


    data: () => ({

        toDoValidate: 1,

        showPicker2: false,

        oreModulo: null,
        posizioneModulo: null,

        currentFile: null,
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",
        titolo: "Aggiungi Documento Revisore",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        modulo: {},

        documento: { data: "", descrizione: "" },

        luoghi: [
            { id: "Ufficio", name: "Ufficio" },
            { id: "Smartworking", name: "Smartworking" }
        ],

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        codeRules: [
            (v) => !!v || "Codice richiesto",
        ],

        descRules: [
            (v) => !!v || "Descrizione richiesta",
        ],

        select: null,
        checkbox: false,
    }),

    methods: {

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/alertduedate/" + nome_metodo;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/salva_smartform.png",
                    title: "salva",
                    width: 30
                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/back_smartform.png",
                    title: "Indietro",
                    width: 30
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            var errore = "";

            if (that.documento.data == "") {
                errore = "Specificare una data";
            }

            if (errore == "") {

                if (that.documento.descrizione == "") {
                    errore = "Specificare una descrizione";
                }

            }

            if (that.toDoValidate == 1) {

                if (errore == "") {

                    if ($('#input-file-logo')[0].files.length == 0) {

                        errore = "Specificare un documento";

                    }

                }

                // if (errore == "") {

                //     if ($('#input-file-logo')[0].files.length > 0) {

                //         var v_file = $('#input-file-logo')[0].files[0];

                //         var elements = v_file.name.split('.');
                //         var extension = elements[elements.length - 1];

                //         if (extension.toString().toUpperCase() != "PDF") {

                //             errore = "Formati del documento supportati PDF";


                //         }

                //     }

                // }

                if (errore == "") {

                    if ($('#input-file-logo')[0].files.length > 0) {

                        var v_file = $('#input-file-logo')[0].files[0];

                        var elements = v_file.name.split('.');
                        var extension = elements[elements.length - 1];

                        if ((extension.toString().toUpperCase() != "PDF") && (extension.toString().toUpperCase() != "XLS") && (extension.toString().toUpperCase() != "XLSX") && (extension.toString().toUpperCase() != "DOC") && (extension.toString().toUpperCase() != "DOCX")) {

                            errore = "Formati del documento supportati PDF, XLS, XLSX, DOC, DOCX";


                        }

                    }

                }


            }

            if (errore == "") {

                console.log("ok");

                that.saveData();


            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }



        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            var id_rec = that.$route.params.id;

            that.documento.id_corso = window.$cookies.get("sel_corso_revisore");
            that.documento.companyid = window.$cookies.get("sel_azienda_revisore");

            if (id_rec == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apirevisore.insertDocumento(
                    that.documento,
                    v_token,
                    $('#input-file-logo')[0].files[0]
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertDocumento", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/revisore"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }

            if (id_rec > 0) {

                console.log("ok");

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var v_file = undefined;

                if ($('#input-file-logo')[0].files.length > 0) {

                    v_file = $('#input-file-logo')[0].files[0];
                }

                var response2 = await apirevisore.updateDocumento(
                    that.documento,
                    v_token,
                    v_file,
                    id_rec
                ).then((res) => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateCorso", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/revisore"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }



        },

        syncCorso: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.editCorso(
                that.$route.params.id,
                v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editCorso", res);

                that.corso = res.data.corso;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncDocRevisore: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apirevisore.editDocumento(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editDocumento", res);

                that.documento = res.data.Result;

                that.toDoValidate = 0;


                var allegato = res.data.Result.allegato;

                var elements = allegato.split(".");

                console.log("ELEMENTS: ", elements);


                setTimeout(() => {

                    $('.dropify-render').append("<i class='dropify-font-file'></i>");
                    $('.dropify-render').append("<span class='dropify-extension'>" + elements[1] + "</span>");

                    // dropify-font-file
                    $(".dropify-wrapper").addClass("has-preview");
                    $(".dropify-preview").show("slow");



                }, 200);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.edit-revisore .dropify-wrapper {
    width: 482px !important
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .edit-revisore .dropify-wrapper {
        width: 339px !important
    }


}
</style>
