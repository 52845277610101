<template>
    <div>
      <h2>Survey</h2>
      <form @submit.prevent="submitForm">
        <div v-for="(question, index) in questions" :key="index" class="question">
          <p>{{ question.text }}</p>
          <div v-for="option in question.options" :key="option.text" class="option">
            <input
              type="radio"
              :name="`question-${index}`"
              :value="option.text"
              v-model="answers[index]"
            >
            <label>{{ option.text }}</label>
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        questions: [
          {
            text: "Qual è il tuo colore preferito?",
            options: [
              { text: "Rosso" },
              { text: "Blu" }
            ]
          },
          {
            text: "Preferisci il giorno o la notte?",
            options: [
              { text: "Giorno" },
              { text: "Notte" }
            ]
          }
        ],
        answers: ["", ""]
      };
    },
    methods: {
      submitForm() {
        console.log("Risposte selezionate:", this.answers);
        // Qui potresti voler inviare le risposte al server o gestirle in altro modo
      }
    }
  };
  </script>
  
  <style>
  .question {
    margin-bottom: 20px;
  }
  
  .option {
    margin-left: 20px;
  }
  </style>  