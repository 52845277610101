import axios from 'axios'

function getUrlDomainGeneral(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


}

var main_domain = getUrlDomainGeneral("");

class APIPartecipanti {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    async initInsert(user) {

        let formData = new FormData();

        formData.append("username", user);


        return axios.post(this.getUrlDomain("SFPartecipantiInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getResourceDetails(user, id_risorsa) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id_res", id_risorsa);


        return axios.post(this.getUrlDomain("SFPartecipantiGetResourceApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }





    async setPerc(ids, valore) {

        let formData = new FormData();

        formData.append("ids", ids);
        formData.append("valore", valore);


        return axios.post(this.getUrlDomain("SFPartecipantiSetPercApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }













    async insertPartecipante(partecipante, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in partecipante) {
            formData.append(key, partecipante[key]);
        }

        return axios.post(this.getUrlDomain("SFPartecipantiInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async initEdit(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("SFPartecipantiInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updatePartecipante(partecipante, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);

        for (var key in partecipante) {
            formData.append(key, partecipante[key]);
        }

        return axios.post(this.getUrlDomain("SFPartecipantiUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async deletePartecipanti(id) {

        let formData = new FormData();

        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("SFPartecipantiDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getCorsiAssoc(id) {

        let formData = new FormData();

        formData.append("id", id);

        return axios.post(this.getUrlDomain("SFPartecipantiGetCorsiAssocApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async saveCorsiAssoc(id_corsi_sel, id_partecipante) {

        let formData = new FormData();

        formData.append("v_id_corsi", id_corsi_sel);
        formData.append("v_id_parte", id_partecipante);

        return axios.post(this.getUrlDomain("SFPartecipantiSaveCorsiAssocApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }




}

export default new APIPartecipanti({
    url: main_domain
})
