import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIMessaggi {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getInfoMessaggioRef(user, id) {

    let formData = new FormData();

    formData.append("id", id);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("AlertMsgGetInfoMsgRef"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getAttachment(v_path, v_user, v_file_name, v_id_message) {

    let formData = new FormData();

    formData.append("v_path", v_path);
    formData.append("v_user", v_user);
    formData.append("v_file_name", v_file_name);
    formData.append("v_id_message", v_id_message);
 
    return axios.post(this.getUrlDomain("AlertMsgGetAttachment"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveSignedDocument(v_id_messaggio, v_data_signed, v_user) {

    let formData = new FormData();

    formData.append("v_id_messaggio", v_id_messaggio);
    formData.append("v_data_signed", v_data_signed);
    formData.append("v_user", v_user);
 
    return axios.post(this.getUrlDomain("AlertMsgSaveSignedDocument"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getDataForOtp(v_user) {

    let formData = new FormData();

    formData.append("v_user", v_user);
 
    return axios.post(this.getUrlDomain("AlertMsgGetDataForOtp"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getValueForOtp(username, name_product, mobile, prefix_mobile) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("name_product", name_product);
    formData.append("mobile", mobile);
    formData.append("prefix_mobile", prefix_mobile);
 
    return axios.post(this.getUrlDomain("generateOtpAndSendSMS"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async sendMailDocSigned(username, v_id) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("v_id", v_id);
 
    return axios.post(this.getUrlDomain("AlertMsgGetSendMailDocSigned"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async uploadFileForSetSign(username, v_file) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("v_file", v_file);
 
    return axios.post(this.getUrlDomain("AlertMsgUploadFileForSetSign"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveDataSigns(v_user, v_id_message, v_info_signs) {

    let formData = new FormData();

    formData.append("v_user", v_user);
    formData.append("v_id_message", v_id_message);
    formData.append("v_info_signs", v_info_signs);
 
    return axios.post(this.getUrlDomain("AlertMsgSaveDataSigns"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async uploadFileForSetSign2Sign(username, v_file) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("v_file", v_file);
 
    return axios.post(this.getUrlDomain("DocSignUploadFileForSetSign"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getAttachmentDocSign(v_user, v_file_name, v_id_message) {

    let formData = new FormData();

    formData.append("v_user", v_user);
    formData.append("v_file_name", v_file_name);
    formData.append("v_id_message", v_id_message);
 
    return axios.post(this.getUrlDomain("DocSignGetAttachment"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getAttachmentSmartform(v_id_message) {

    let formData = new FormData();

     formData.append("v_id_message", v_id_message);
 
    return axios.post(this.getUrlDomain("SmartformGetAttachment"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveDataSignsDocSign(v_user, v_id_message, v_info_signs, v_mail) {

    let formData = new FormData();

    formData.append("v_user", v_user);
    formData.append("v_id_message", v_id_message);
    formData.append("v_info_signs", v_info_signs);
    formData.append("v_mail", v_mail);
 
    return axios.post(this.getUrlDomain("AlertMsgSaveDataSignsDocSign"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveDataSignsSmartForm(v_user, v_id_message, v_info_signs, v_mail) {

    let formData = new FormData();

    formData.append("v_user", v_user);
    formData.append("v_id_message", v_id_message);
    formData.append("v_info_signs", v_info_signs);
    formData.append("v_mail", v_mail);
 
    return axios.post(this.getUrlDomain("AlertMsgSaveDataSignsSmartform"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  async sendMailDocSignedDocSign(username, v_id) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("v_id", v_id);
 
    return axios.post(this.getUrlDomain("AlertMsgGetSendMailDocSignedDocSign"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async sendMessage(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);
 
    return axios.post(this.getUrlDomain("DocSignSendMessage"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getValueForOtpWithMail(v_mail, name_product) {

    let formData = new FormData();

    formData.append("v_mail", v_mail);
    formData.append("name_product", name_product);
  
    return axios.post(this.getUrlDomain("generateOtpAndSendMailDocSign"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateRowVer(v_id, type_ver, v_prefix, v_mobile) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("type_ver", type_ver);
    formData.append("v_prefix", v_prefix);
    formData.append("v_mobile", v_mobile);
  
    return axios.post(this.getUrlDomain("updateRowVer"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveConfMail(v_id, v_mail) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("v_mail", v_mail);
   
    return axios.post(this.getUrlDomain("saveConfMailDocSign"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getConfMail(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);
   
    return axios.post(this.getUrlDomain("getConfMailDocSign"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteConfMail(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);
   
    return axios.post(this.getUrlDomain("deleteConfMailDocSign"), formData, { 'Content-Type': 'multipart/form-data' });

  }

}

export default new APIMessaggi({
  url: "https://services.ebadge.it/public/api/"
})
