import axios from 'axios'

function getUrlDomainGeneral(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


}

var main_domain = getUrlDomainGeneral("");

class APICalendario {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    async getCorsi(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("SFCalendarioGetCorsiApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getInfoDays(id_corso) {

        let formData = new FormData();

        formData.append("id_corso", id_corso);

        return axios.post(this.getUrlDomain("SFCalendarioGetInfoDaysApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }
  
    async saveDays(id_corso, giorni) {

        let formData = new FormData();

        formData.append("id_corso", id_corso);
        formData.append("days", giorni);

        return axios.post(this.getUrlDomain("SFCalendarioSaveDaysApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }




}

export default new APICalendario({
    url: main_domain
})
