import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import timesheet from './components/timesheet.vue'
import profile from './components/profile.vue'
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import filterusers from './components/filterusers.vue'
import users from './components/users.vue'
import editUser from './components/editUser.vue'
import changepasswordadmin from './components/changepasswordadmin.vue'
import settings from './components/settings.vue'
import invioPresenze from './components/invioPresenze.vue'
import timesheetGrid from './components/timesheetGrid.vue'
import pianoOrario from './components/pianoOrario.vue'
import editPianoOrario from './components/editPianoOrario.vue'
import copyPianoOrario from './components/copyPianoOrario.vue'
import risorse from './components/risorse.vue'
import editRisorsa from './components/editRisorsa.vue'
import filterRisorse from './components/filterRisorse.vue'
import company from './components/company.vue'
import editCompany from './components/editCompany.vue'
import filterPianoOrario from './components/filterPianoOrario.vue'
import companyBasic from './components/companyBasic.vue'
import editCompanyBasic from './components/editCompanyBasic.vue'
import moduli from './components/moduli.vue'
import editModulo from './components/editModulo.vue'
import copyModulo from './components/copyModulo.vue'
import partecipanti from './components/partecipanti.vue'
import editPartecipante from './components/editPartecipante.vue'
import filterCorsi from './components/filterCorsi.vue'
import corsi from './components/corsi.vue'
import editCorso from './components/editCorso.vue'
import corsiPartecipantiAssociati from './components/corsiPartecipantiAssociati.vue'
import filterCalendario from './components/filterCalendario.vue'
import calendario from './components/calendario.vue'
import filterPartecipanti from './components/filterPartecipanti.vue'
import editCorsoCappello from './components/editCorsoCappello.vue'
import filterPrevisionale from './components/filterPrevisionale.vue'
import filterUtentiAziende from './components/filterUtentiAziende.vue'
import filterUtentiAziendeAssoc from './components/filterUtentiAziendeAssoc.vue'
import filterOreLUL from './components/filterOreLUL.vue'
import oreLUL from './components/oreLUL.vue'
import filterRevisore from './components/filterRevisore.vue'
import revisore from './components/revisore.vue'
import editRevisore from './components/editRevisore.vue'
import filterCostiAziendali from './components/filterCostiAziendali.vue'
import costiAziendali from './components/costiAziendali.vue'
import filterDocumenti from './components/filterDocumenti.vue'
import documenti from './components/documenti.vue'
import editDocumento from './components/editDocumento.vue'
import reservate from './components/reservate.vue'
import filterCorsiOnline from './components/filterCorsiOnline.vue'
import corsiOnline from './components/corsiOnline.vue'
import editCorsoOnline from './components/editCorsoOnline.vue'
import invitoUtenti from './components/invitoUtenti.vue'
import filterCorsiFlusso from './components/filterCorsiFlusso.vue'
import corsiOnlineFlusso from './components/corsiOnlineFlusso.vue'
import editFlussoCorsoOnline from './components/editFlussoCorsoOnline.vue'
import testPdf from './components/testPdf.vue'
import vediPdf from './components/vediPdf.vue'
import vediDomande from './components/vediDomande.vue'
import filterCorsiDomande from './components/filterCorsiDomande.vue'
import domandeCorsiOnline from './components/domandeCorsiOnline.vue'
import editDomandaCorsoOnline from './components/editDomandaCorsoOnline.vue'
import filterCorsiRisposte from './components/filterCorsiRisposte.vue'
import risposteCorsiOnline from './components/risposteCorsiOnline.vue'
import editRispostaCorsoOnline from './components/editRispostaCorsoOnline.vue'
import filterCorsiPesiRisposte from './components/filterCorsiPesiRisposte.vue'
import pesiRisposteCorsiOnline from './components/pesiRisposteCorsiOnline.vue'
import editPesoRispostaCorsoOnline from './components/editPesoRispostaCorsoOnline.vue'
import testDomande from './components/testDomande.vue'
import reservateCorsoOnline from './components/reservateCorsoOnline.vue'
import initCorsiOnline from './components/initCorsiOnline.vue'
import initCorsiOnlineUser from './components/initCorsiOnlineUser.vue'
import verifiyMobile from './components/verifiyMobile.vue'
import testFile from './components/testFile.vue'

import vediAllegato from './components/vediAllegato.vue'




Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login
        },

        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


        {
            path: '/timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/category/edit-timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/profile',
            name: 'profile',
            component: profile
        },

        {
            path: '/register',
            name: 'register',
            component: register
        },

        {
            path: '/category/modfiy-account',
            name: 'profile',
            component: profile
        }
        ,
        {
            path: '/category/timesheet',
            name: 'timesheet',
            component: timesheet
        }
        ,
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/changepassword',
            name: 'changepassword',
            component: changepassword
        }

        ,
        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/filterusers',
            name: 'filterusers',
            component: filterusers
        }


        ,
        {
            path: '/users',
            name: 'users',
            component: users
        }

        ,
        {
            path: '/editUser/:id',
            name: 'editUser',
            component: editUser,
            props: true
        }

        ,

        {
            path: '/changepassword/:id',
            name: 'changepassword',
            component: changepasswordadmin,
            props: true
        },

        {
            path: '/settings',
            name: 'settings',
            component: settings
        },

        {
            path: '/invioPresenze',
            name: 'invioPresenze',
            component: invioPresenze
        },


        {
            path: '/timesheetGrid',
            name: 'timesheetGrid',
            component: timesheetGrid
        },

        {
            path: '/pianoOrario',
            name: 'pianoOrario',
            component: pianoOrario
        }

        ,
        {
            path: '/editPianoOrario/:id',
            name: 'editPianoOrario',
            component: editPianoOrario,
            props: true
        }

        ,
        {
            path: '/copyPianoOrario/:id',
            name: 'copyPianoOrario',
            component: copyPianoOrario,
            props: true
        },


        {
            path: '/risorse',
            name: 'risorse',
            component: risorse
        }

        ,
        {
            path: '/editRisorsa/:id',
            name: 'editRisorsa',
            component: editRisorsa,
            props: true
        },


        {
            path: '/filterRisorse',
            name: 'filterRisorse',
            component: filterRisorse,
            props: true
        },


        {
            path: '/company',
            name: 'company',
            component: company,
            props: true
        },


        {
            path: '/editCompany/:id',
            name: 'editCompany',
            component: editCompany,
            props: true
        },


        {
            path: '/filterPianoOrario',
            name: 'filterPianoOrario',
            component: filterPianoOrario,
            props: true
        },


        {
            path: '/companyBasic',
            name: 'companyBasic',
            component: companyBasic,
            props: true
        },


        {
            path: '/editCompanyBasic/:id',
            name: 'editCompanyBasic',
            component: editCompanyBasic,
            props: true
        },

        {
            path: '/moduli',
            name: 'moduli',
            component: moduli,
            props: true
        },


        {
            path: '/editModulo/:id',
            name: 'editModulo',
            component: editModulo,
            props: true
        },

        {
            path: '/copyModulo/:id',
            name: 'copyModulo',
            component: copyModulo,
            props: true
        },

        {
            path: '/partecipanti',
            name: 'partecipanti',
            component: partecipanti,
            props: true
        },

        {
            path: '/editPartecipante/:id',
            name: 'editPartecipante',
            component: editPartecipante,
            props: true
        },

        {
            path: '/filterCorsi',
            name: 'filterCorsi',
            component: filterCorsi,
            props: true
        },

        {
            path: '/corsi',
            name: 'corsi',
            component: corsi,
            props: true
        },

        {
            path: '/editCorso/:id',
            name: 'editCorso',
            component: editCorso,
            props: true
        },

        {
            path: '/corsiPartecipantiAssociati',
            name: 'corsiPartecipantiAssociati',
            component: corsiPartecipantiAssociati,
            props: true
        },

        {
            path: '/filterCalendario',
            name: 'filterCalendario',
            component: filterCalendario,
            props: true
        },

        {
            path: '/calendario',
            name: 'calendario',
            component: calendario,
            props: true
        },

        {
            path: '/filterPartecipanti',
            name: 'filterPartecipanti',
            component: filterPartecipanti,
            props: true
        },

        {
            path: '/editCorsoCappello/:id',
            name: 'editCorsoCappello',
            component: editCorsoCappello,
            props: true
        },

        {
            path: '/filterPrevisionale',
            name: 'filterPrevisionale',
            component: filterPrevisionale,
            props: true
        },

        {
            path: '/filterUtentiAziende',
            name: 'filterUtentiAziende',
            component: filterUtentiAziende,
            props: true
        },

        {
            path: '/filterUtentiAziendeAssoc',
            name: 'filterUtentiAziendeAssoc',
            component: filterUtentiAziendeAssoc,
            props: true
        },

        {
            path: '/filterOreLUL',
            name: 'filterOreLUL',
            component: filterOreLUL,
            props: true
        },

        {
            path: '/oreLUL',
            name: 'oreLUL',
            component: oreLUL,
            props: true
        },

        {
            path: '/filterRevisore',
            name: 'filterRevisore',
            component: filterRevisore,
            props: true
        },

        {
            path: '/revisore',
            name: 'revisore',
            component: revisore,
            props: true
        },

        {
            path: '/editRevisore/:id',
            name: 'editRevisore',
            component: editRevisore,
            props: true
        },

        {
            path: '/filterCostiAziendali',
            name: 'filterCostiAziendali',
            component: filterCostiAziendali,
            props: true
        },

        {
            path: '/costiAziendali',
            name: 'costiAziendali',
            component: costiAziendali,
            props: true
        },

        {
            path: '/filterDocumenti',
            name: 'filterDocumenti',
            component: filterDocumenti,
            props: true
        },

        {
            path: '/documenti',
            name: 'documenti',
            component: documenti,
            props: true
        },

        {
            path: '/editDocumento/:id',
            name: 'editDocumento',
            component: editDocumento,
            props: true
        },

        {
            path: '/reservate/:id',
            name: 'reservate',
            component: reservate,
            props: true
        },

        {
            path: '/filterCorsiOnline',
            name: 'filterCorsiOnline',
            component: filterCorsiOnline,
            props: true
        },

        {
            path: '/corsiOnline',
            name: 'corsiOnline',
            component: corsiOnline,
            props: true
        },

        {
            path: '/editCorsoOnline/:id',
            name: 'editCorsoOnline',
            component: editCorsoOnline,
            props: true
        },

        {
            path: '/invitoUtenti',
            name: 'invitoUtenti',
            component: invitoUtenti,
            props: true
        },

        {
            path: '/filterCorsiFlusso',
            name: 'filterCorsiFlusso',
            component: filterCorsiFlusso,
            props: true
        },

        {
            path: '/corsiOnlineFlusso',
            name: 'corsiOnlineFlusso',
            component: corsiOnlineFlusso,
            props: true
        },

        {
            path: '/editFlussoCorsoOnline/:id',
            name: 'editFlussoCorsoOnline',
            component: editFlussoCorsoOnline,
            props: true
        },

        {
            path: '/testPdf',
            name: 'testPdf',
            component: testPdf,
            props: true
        },
       
        // {
        //     path: '/vediPdf/:id',
        //     name: 'vediPdf',
        //     component: vediPdf,
        //     props: true
        // },

        {
            path: '/vediPdf',
            name: 'vediPdf',
            component: vediPdf,
            props: true
        },

        // {
        //     path: '/vediDomande/:id',
        //     name: 'vediDomande',
        //     component: vediDomande,
        //     props: true
        // },

        {
            path: '/vediDomande',
            name: 'vediDomande',
            component: vediDomande,
            props: true
        },

        {
            path: '/filterCorsiDomande',
            name: 'filterCorsiDomande',
            component: filterCorsiDomande,
            props: true
        },

        {
            path: '/domandeCorsiOnline',
            name: 'domandeCorsiOnline',
            component: domandeCorsiOnline,
            props: true
        },

        {
            path: '/editDomandaCorsoOnline/:id',
            name: 'editDomandaCorsoOnline',
            component: editDomandaCorsoOnline,
            props: true
        },

        {
            path: '/filterCorsiRisposte',
            name: 'filterCorsiRisposte',
            component: filterCorsiRisposte,
            props: true
        },

        {
            path: '/risposteCorsiOnline',
            name: 'risposteCorsiOnline',
            component: risposteCorsiOnline,
            props: true
        },

        {
            path: '/editRispostaCorsoOnline/:id',
            name: 'editRispostaCorsoOnline',
            component: editRispostaCorsoOnline,
            props: true
        },

        {
            path: '/filterCorsiPesiRisposte',
            name: 'filterCorsiPesiRisposte',
            component: filterCorsiPesiRisposte,
            props: true
        },

        {
            path: '/pesiRisposteCorsiOnline',
            name: 'pesiRisposteCorsiOnline',
            component: pesiRisposteCorsiOnline,
            props: true
        },

        {
            path: '/editPesoRispostaCorsoOnline/:id',
            name: 'editPesoRispostaCorsoOnline',
            component: editPesoRispostaCorsoOnline,
            props: true
        },

        {
            path: '/testDomande',
            name: 'testDomande',
            component: testDomande,
            props: true
        },

        {
            path: '/reservateCorsoOnline/:id',
            name: 'reservateCorsoOnline',
            component: reservateCorsoOnline,
            props: true
        },

        {
            path: '/initCorsiOnline',
            name: 'initCorsiOnline',
            component: initCorsiOnline,
            props: true
        },

        {
            path: '/initCorsiOnlineUser',
            name: 'initCorsiOnlineUser',
            component: initCorsiOnlineUser,
            props: true
        },

        {
            path: '/verifiyMobile',
            name: 'verifiyMobile',
            component: verifiyMobile,
            props: true
        },


        {
            path: '/testFile',
            name: 'testFile',
            component: testFile,
            props: true
        },


        {
            path: '/vediAllegato',
            name: 'vediAllegato',
            component: vediAllegato,
            props: true
        },

        
     

    ]
})


router.afterEach((to, from) => {
    console.log("from", from);
    console.log("going to " + to.fullPath)

})


export default router
