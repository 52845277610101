<template>
    <v-container class="w-container v-overflow editParente"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);overflow-x:hidden">
    </v-container>
</template>

<script>

import router from ".././router";
import apicorsi from "../utils/corsi/apicorsi";
import axios from 'axios';

export default ({
    mounted() {

        window.tokenuser = "YWRtaW4yX2N8Nw==";
        window.resCorso = "PASSED";
        window.nDomande = 10;
        window.nRispOk = 10;

        this.createDocToSign();


    },


    data: () => ({
        titolo: "Corsi Online",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selAzienda: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetAziende: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        // nucleiRules

        select: null,

        //gruppi: window.$cookies.get("gen_settings").gruppi,

        checkbox: false,
    }),

    methods: {

        compileDocumentWithPython: async function (v_companyid, val_name_surname, val_title, val_date, input_pdf_path, output_pdf_path) {

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            const formData = new FormData();
            formData.append('v_companyid', v_companyid);
            formData.append('val_name_surname', val_name_surname);
            formData.append('val_title', val_title);
            formData.append('val_date', val_date);
            formData.append('input_pdf_path', input_pdf_path);
            formData.append('output_pdf_path', output_pdf_path);




            var v_url = "http://127.0.0.1:8000/complate_pdf_smartform/";
            //var v_url = "https://filestransfer.it:8000/edit_pdf_smartform/";

            try {


                const response = await axios.post(v_url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                console.log("res from edit-pdf", response);


                that.$root.$children[0].showProgress = false;

                setTimeout(() => {

                    router.push({
                        path: "/verifiyMobile"
                    });

                }, 200);

            } catch (error) {

                that.$root.$children[0].showProgress = false;

                that.$swal({
                    icon: "error",
                    text: "Error signing document",
                    showConfirmButton: false,
                    timer: 3000
                });

            }


        },


        createDocToSign: async function () {

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.createDocToSign(
                window.tokenuser
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createDocToSign", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    window.idDoc = res.data.ValIdRec;



                    that.compileDocumentWithPython(res.data.v_companyid, res.data.full_name, res.data.title, res.data.v_date, res.data.input_pdf_path, res.data.output_pdf_path);

                    // setTimeout(() => {

                    //     router.push({
                    //         path: "/verifiyMobile"
                    //     });

                    // }, 3000);


                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 2000
                    });

                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.selAzienda = azienda.companyid;

            this.hideShowButtons(true);

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {

                    pulsantis = [{
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: "https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png",
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        // class: "inverted",
                        title: "Conferma",
                        width: 30
                    },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/conferma_smartform.png",
                    link: "/timesheet",
                    id: "btn_confirm",
                    disabled: false,
                    //class: "inverted",
                    title: "Conferma",
                    width: 30
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            console.log("AZIENDA: ", that.selAzienda);

            window.$cookies.set("sel_filter_corsi", that.selAzienda, "9y");


            var desc_azi_sel = "";

            for (var xx = 0; xx <= that.aziende.length - 1; xx++) {


                if (parseInt(that.aziende[xx].companyid) == parseInt(that.selAzienda)) {




                    desc_azi_sel = that.aziende[xx].fld_name;
                }


            }


            console.log("AZI: ", desc_azi_sel);


            window.$cookies.set("sel_azi_name", desc_azi_sel, "9y");




            router.push({
                path: "/corsiOnline"
            });

        },




    }

})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editParente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editParente .v-input {
    font-size: 1.2em;
}

.editParente .v-select {
    font-size: 1.2em;
}

.editParente .v-label {
    font-size: 1em;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
