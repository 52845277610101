<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_corsi_smartform">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Nome</th>
                                        <th class='all'>Data Inizio</th>
                                        <th class='all'>Data Fine</th>
                                        <th class='all' style="text-align: center">GG Cal.</th>
                                        <th class='all' style="text-align: center">GG Mod.</th>
                                        <th class='all' style="text-align: center">Relazione</th>
                                        <!-- <th class='all' style="text-align: center">Documento</th> -->



                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="8">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/smartform_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende_smartform.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


        <div class="contpopupmoduligiornate" style="display:none">

            <template>
                <modal name="popupModuliGiornate" :clickToClose="false" :width="369" :height="480">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Associazione Moduli Giornate

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:3px">

                        <v-col cols="3" md="3">

                            <ion-item id="DataSel"
                                style="min-width:116px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Data</ion-label>

                                <ion-select id="dateSel" name="dateSel" class='dateSel' cancel-Text="Chiudi" done-Text=""
                                    :value="dateSel" interface="action-sheet">

                                    <ion-select-option v-for="item in dateCorso" :key="item.id" :value='item.id'> {{
                                        item.data
                                    }}
                                    </ion-select-option>

                                </ion-select>
                            </ion-item>

                        </v-col>

                        <v-col cols="3" md="3">&nbsp;</v-col>
                        <v-col cols="3" md="3">&nbsp;</v-col>
                        <v-col cols="3" md="3">&nbsp;</v-col>

                    </v-row>

                    <v-row v-if="viewRow">

                        <div style="font-weight:bold;margin-left: 23px;width:90px">Data</div>
                        <div style="font-weight:bold;width:90px;text-align: center;">Ore Da</div>
                        <div style="font-weight:bold;width:90px;text-align: center">A</div>
                        <div style="font-weight:bold;width:50px;text-align: center">Tot. Ore</div>


                    </v-row>

                    <v-row v-if="viewRow">
                        <div style="font-weight:bold;margin-left: 23px;width:90px">{{ dateSelected }}</div>
                        <div style="font-weight:bold;width:90px;text-align: center">{{ timeFrom }}</div>
                        <div style="font-weight:bold;width:90px;text-align: center">{{ timeTo }}</div>
                        <div style="font-weight:bold;width:50px;text-align: center">{{ totalHours }}</div>
                    </v-row>


                    <v-row v-if="viewRow">

                        <v-col cols="12" md="12">


                            <v-list style="overflow-y:auto;max-height:224px;min-height:224px;">


                                <v-list-tile v-for="el in argomenti" :key="el.id" :name="el.id">

                                    <div v-if="el.testata.length > 0" class="contSecCheck"
                                        style="display:flex!important;height:30px;margin-left: 12px;">

                                        <div style="font-weight: bold;">{{ el.testata }}</div>

                                    </div>

                                    <div v-if="el.testata.length > 0"
                                        style="display:flex!important;height:30px;margin-left: 12px">

                                        <div style="width:26px">&nbsp;</div>
                                        <div style="width:229px;font-weight: bold;">Descrizione</div>
                                        <div style="width:60px;font-weight: bold;text-align: right;">Ore Totali</div>

                                    </div>

                                    <div class="contCheck" style="display:flex!important;height:30px">

                                        <div style="width:40px">
                                            <v-checkbox v-model="idPermSel" multiple class="cl-checkbox chk_sel_arg"
                                                style="margin-top:-14px!important" :value="el.id" :data_tot="el.ore_totali"
                                                @click="chkSelArg" :disabled="disChk" />
                                        </div>

                                        <div style="width:229px">
                                            {{ el.descrizione }}
                                        </div>

                                        <div style="width:60px;text-align: right;">
                                            {{ el.ore_totali }}
                                        </div>

                                    </div>





                                </v-list-tile>







                            </v-list>




                        </v-col>

                    </v-row>

                    <v-row v-if="viewRow">
                        <v-col cols="12" md="12" style="display:flex">

                            <div style="font-weight: bold;margin-left:12px;">Totale Selezionato</div>

                            <div style="font-weight: bold;margin-left:199px;">
                                {{ totalHoursSelected }}
                            </div>


                        </v-col>
                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelModuliGiornate" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupcorsomoduli" style="display:none">

            <template>
                <modal name="popupCorsoModuli" :clickToClose="false" :width="339" :height="335">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Associazione Corso Moduli

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">


                            <v-list style="overflow-y:auto;max-height:224px;min-height:224px;">

                                <div style="display:flex!important;height:30px;margin-left: 12px">

                                    <div style="width:26px">&nbsp;</div>
                                    <!-- <div style="width:229px;font-weight: bold;">Modulo</div> -->

                                    <div style="width:115px;font-weight: bold;">Modulo</div>

                                    <div style="width:114px;font-weight: bold;">Posizione</div>

                                    <div style="width:60px;font-weight: bold;text-align: right;">Ore Totali</div>

                                </div>

                                <v-list-tile v-for="el in moduli" :key="el.codice">

                                    <div class="contCheck" style="display:flex!important;height:30px">

                                        <div style="width:40px">
                                            <v-checkbox v-model="idModuloSel" multiple class="cl-checkbox chk_sel_arg"
                                                style="margin-top:-14px!important" :value="el.codice" :id="el.id"
                                                @click="chkSelMod" />
                                        </div>

                                        <!-- <div style="width:229px">
                                            {{ el.codice }}
                                        </div> -->


                                        <div style="width:115px">
                                            {{ el.codice }}
                                        </div>

                                        <div style="width:114px;margin-left:-15px;margin-top:-29px">
                                            <v-text-field label="" :id="el.codice" @keypress="filterKeys(event)"
                                                @blur="handleUpdateItem($event)">
                                            </v-text-field>
                                        </div>



                                        <div style="width:60px;text-align: right">
                                            {{ el.v_tot }}
                                        </div>

                                    </div>




                                </v-list-tile>



                            </v-list>



                        </v-col>


                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-assoc-moduli" contain
                                    src="@/assets/btn_cancel_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelAssocModuli" />


                            </div>

                        </v-col>

                    </v-row>




                </modal>
            </template>

        </div>



        <div class="contpopupuploadpdf" style="display:none">

            <template>
                <modal name="popupUploadPdf" :clickToClose="false" :width="400" :height="415">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-left: 10px;margin-top: 10px;
font-weight: bold;">
                            Upload PDF
                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="max-width: 378px;">


                                <span class="files">
                                    <input type="file" id="input-file-logo" name="logo" accept=".pdf" class="dropify"
                                        data-max-width="6610" data-max-height="6200" />
                                </span>

                            </div>



                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload-relazione" contain
                                    src="@/assets/btn_cancel_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelUploadRel" />

                                <v-img alt="" class="btn-confirm-upload-relazione" contain
                                    src="@/assets/btn_confirm_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmUploadRel" />

                                <v-img v-if="viewDeleteRel" alt="" class="btn-delete-file-relazione" contain
                                    src="@/assets/delete_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Elimina"
                                    @click="btnDeleteFileRel" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




        <div class="contpopupuploaddoc" style="display:none">

            <template>
                <modal name="popupUploadDoc" :clickToClose="false" :width="400" :height="415">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-left: 10px;margin-top: 10px;
font-weight: bold;">
                            Upload Documento
                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="max-width: 378px;">


                                <span class="files">
                                    <input type="file" id="input-file-logo" name="logo" accept=".pdf" class="dropify"
                                        data-max-width="6610" data-max-height="6200" />
                                </span>

                            </div>



                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload-doc" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelUploadDoc" />

                                <v-img alt="" class="btn-confirm-upload-doc" contain
                                    src="@/assets/btn_confirm_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmUploadDoc" />

                                <v-img v-if="viewDeleteDoc" alt="" class="btn-delete-file-doc" contain
                                    src="@/assets/delete_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Elimina"
                                    @click="btnDeleteFileDoc" />



                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contanni">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetAnni">
                        <v-sheet class="text-center" height="530px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">
                                <v-btn class='closeTypesSheet' icon @click="sheetAnni = !sheetAnni">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>


                            <div class="py-3 c_title_tipi">
                                Anni
                            </div>

                            <v-list-item v-for="tile in anni" :key="tile.id" @click="manageClickAnni(tile.id)">

                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                    tile.nome
                                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>




    </div>
</template>

<script>

import apirisorse from "../utils/risorse/apirisorse";
import apiusers from "../utils/users/apiusers";
import apicorsi from "../utils/corsi/apicorsi";

import {
    bus
} from "../main";
import router from ".././router";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        var pointerVue = this;



        this.isReadOnly = this.$root.$children[0].getValueReadOnly("corsi_smartform_ro");

        console.log("IS RO: ", this.isReadOnly);




        window.curAnno = 2022; // default 2002



        window.curSrcText = "";
        //window.curCompany = "";
        window.curCompany = window.$cookies.get("sel_filter_corsi");

        window.NomeAziendaSel = window.$cookies.get("sel_azi_name");


        window.perpage = 30;
        bus.$off("bottomIconClickEvent");


        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {


            $('body').on('click', '.selanno', function () {

                console.log(("EVENTO CLICK SELCORSO"));

                pointerVue.sheetAnni = true;

            });




            $('body').on('change', '#input-file-logo', function () {

                console.log(("EVENTO CHANGE INPUTFILELOG"));


                pointerVue.editPdfRel = 0;

                pointerVue.editPdfDoc = 0;


            });




            $('body').on('ionChange', '.dateSel', function () {

                console.log(("EVENTO CLICK DATESEL"));

                console.log("VAL DATESEL: ", this.value);
                console.log("DATE CORSO: ", pointerVue.dateCorso);

                var txtDataSel = "";

                for (var x = 0; x < pointerVue.dateCorso.length; x++) {

                    if (parseInt(pointerVue.dateCorso[x].id) == parseInt(this.value)) {
                        txtDataSel = pointerVue.dateCorso[x].data;
                    }

                }

                console.log("TXT DATESEL: ", txtDataSel);



                pointerVue.assocModuliGetArgs(this.value, txtDataSel);


                // pointerVue.dateSelected = txtDataSel;

                // pointerVue.viewRow = true;






            });








            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {

            this.syncAziende();

        }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;

            var ids = "";
            var ispadre = 0;

            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editCorso/0"
                    });
                    break;


                case "btn_main_corso":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    window.$cookies.set("id_corsi_sel_main", ids, "9y");

                    router.push({
                        path: "/editCorsoCappello/0"
                    });
                    break;



                case "btn_edit":

                    ids = "";


                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");


                            ispadre = $(this).attr("is-padre");


                        }

                    });

                    console.log("IDS SEL:", ids);
                    console.log("IS PADRE:", ispadre);

                    if (ispadre == 0) {

                        router.push({
                            path: "/editCorso/" + ids
                        });

                    }

                    if (ispadre == 1) {

                        router.push({
                            path: "/editCorsoCappello/" + ids
                        });

                    }




                    break;



                case "btn_report":

                    this.reportPdf();

                    break;


                case "btn_back":
                    router.push({
                        path: "/filterRisorse"
                    });
                    break;


                case "btn_part":

                    ids = "";

                    var ele_sel = null;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            ele_sel = $(this);

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    window.$cookies.set("id_sel_corso", ids, "9y");

                    var v_row = ele_sel.parent().parent();

                    console.log("ROW SEL: ", v_row);

                    var item2 = window.table.row(v_row).data();

                    console.log("ITEM ROW: ", item2);

                    window.$cookies.set("code_sel_corso", item2.codice, "9y");
                    window.$cookies.set("name_sel_corso", item2.nome, "9y");
                    window.$cookies.set("data_inizio_sel_corso", item2.data_inizio, "9y");
                    window.$cookies.set("data_fine_sel_corso", item2.data_fine, "9y");

                    window.$cookies.set("nome_azi_sel_corso", window.NomeAziendaSel, "9y");


                    router.push({
                        path: "/corsiPartecipantiAssociati"
                    });



                    break;


                case "btn_assoc":

                    this.assocModuliGiorni();

                    break;

                case "btn_assoc_moduli":

                    this.getModuliCorso();

                    break;


                case "btn_upload_pdf":

                    this.viewDeleteRel = false;

                    this.editPdfRel = 0;

                    $(".contpopupuploadpdf").show();

                    this.$modal.show('popupUploadPdf');

                    setTimeout(() => {

                        var oggDrop = $('.dropify').dropify({
                            messages: {
                                default: 'Drag',
                                replace: /* s */ '',
                                remove: 'Rimuovi',
                                error: 'File troppo grande'
                            }
                        });

                   
                        $(".dropify-clear").remove();

                        $(".dropify-render").append("<img>");

                        oggDrop.on('dropify.afterClear', function (event, element) {

                            console.log(event, element);

                        });



                        var val_url2 = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                val_url2 = $(this).attr("url-id");

                            }

                        });

                        console.log("PDF RELAZIONE: ", val_url2);


                        if (val_url2 != "") {

                            $('.dropify-render').append("<i class='dropify-font-file'></i>");
                            $('.dropify-render').append("<span class='dropify-extension'>pdf</span>");

                            // dropify-font-file
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                            this.viewDeleteRel = true;

                            this.editPdfRel = 1;


                        }





                    }, 200);



                    break;

                case "btn_down_pdf":


                    var val_url = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            val_url = $(this).attr("url-id");

                        }

                    });

                    console.log("URL VALUE: ", val_url);


                    // var url_call = this.getUrlDomain("TwoRecruitCvRicDownloadApi") + "?f=" + val_url;

                    // console.log("URL TO CALL: ", url_call);


                    // window.open(url_call, "_blank");

                    var file_path = val_url;
                    var a = document.createElement('A');
                    a.href = file_path;
                    a.target = "_blank";
                    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);


                    break;





                case "btn_upload_doc":

                    this.viewDeleteDoc = false;

                    this.editPdfDoc = 0;

                    $(".contpopupuploaddoc").show();

                    this.$modal.show('popupUploadDoc');

                    setTimeout(() => {

                        var oggDrop = $('.dropify').dropify({
                            messages: {
                                default: 'Drag',
                                replace: /* s */ '',
                                remove: 'Rimuovi',
                                error: 'File troppo grande'
                            }
                        });

                        // $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                        $(".dropify-clear").remove();

                        $(".dropify-render").append("<img>");

                        oggDrop.on('dropify.afterClear', function (event, element) {

                            console.log(event, element);


                        });




                        var val_url3 = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                val_url3 = $(this).attr("url-doc");

                            }

                        });

                        console.log("PDF DOCUMENTO : ", val_url3);


                        if (val_url3 != "") {

                            $('.dropify-render').append("<i class='dropify-font-file'></i>");
                            $('.dropify-render').append("<span class='dropify-extension'>pdf</span>");

                            // dropify-font-file
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                            this.viewDeleteDoc = true;

                            this.editPdfDoc = 1;

                        }








                    }, 200);


                    break;




                case "btn_down_doc":


                    var val_url2 = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            val_url2 = $(this).attr("url-doc");

                        }

                    });

                    console.log("URL VALUE: ", val_url2);


                    var url_call2 = this.getUrlDomain("TwoRecruitCvRicDownloadApi") + "?f=" + val_url2;

                    console.log("URL TO CALL: ", url_call2);


                    window.open(url_call2, "_blank");


                    break;



                default:
                    break;

            }
        });


        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        // var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */


        setTimeout(() => {

            pointerVue.setupGrid();

        }, 200);



        this.hideShowButtons(false);

    },
    data: () => ({


        anni: [{ "id": 2020, "nome": 2020 }, { "id": 2021, "nome": 2021 }, { "id": 2022, "nome": 2022 }, { "id": 2023, "nome": 2023 }],


        sheetAnni: false,

        posAssocModuli: "",

        editPdfRel: 0,
        editPdfDoc: 0,

        viewDeleteRel: false,

        viewDeleteDoc: false,


        disChk: false,


        isReadOnly: 0,

        moduli: [],
        idModuloSel: [],

        nomeArgomento: "",
        codiceArgomento: "",

        idPermSel: [],

        argomenti: [],

        viewRow: false,

        dateSelected: "",
        timeFrom: "",
        timeTo: "",
        totalHours: "",

        totalHoursSelected: "",

        dateCorso: [],
        dateSel: null,



        aziende: [],

        sheetAziende: false,
        search: "",

        textSearch: "",

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')


        },

    },



    methods: {

        manageClickAnni: async function (id_anno) {

            console.log("ANNO SEL: ", id_anno);

            this.sheetAnni = false;

            window.curAnno = id_anno;


            // this.setMesi(window.curCorso, window.curAnno);


            // window.$cookies.set("sel_anno_ore_lul", id_anno, "9y");



            // this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            $(".nome_anno").html("Anno: " + id_anno);



            // $(".nome_anno").html("Anno: " + id_anno);

            window.table.ajax.reload();


        },




        updatePosAssoc: async function (v_pos, v_id) {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("VALORE POS: ", v_pos);
            console.log("ID CORSO: ", ids);
            console.log("ID FIELD: ", v_id);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.setPosModuloAssoc(
                ids,
                v_pos,
                v_id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setPosModuloAssoc", res);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        handleUpdateItem: function (evt) {

            console.log("TARGET: ", evt.target);


            console.log("BLUR: ", evt.target.value);

            this.updatePosAssoc(evt.target.value, evt.target.id);


        },


        filterKeys: function (evt) {

            try {

                evt = (evt) ? evt : window.event;
                let expect = evt.target.value.toString() + evt.key.toString();

                if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
                    evt.preventDefault();
                } else {
                    return true;
                }

            } catch (error) {

                console.log(error);

            }




        },


        btnDeleteFileDoc: function () {

            this.deleteDocumento();

        },

        btnDeleteFileRel: function () {

            this.deleteRelazione();

        },

        btnCancelUploadDoc: function () {

            this.$modal.hide('popupUploadDoc');

        },

        btnConfirmUploadDoc: function () {

            var errore = "";

            if (this.editPdfDoc == 0) {

                if ($('#input-file-logo')[0].files.length == 0) {

                    errore = "Allegato richiesto";


                }


            }


            if (errore == "") {

                if (this.editPdfDoc == 0) {

                    if ($('#input-file-logo')[0].files.length > 0) {

                        var v_file = $('#input-file-logo')[0].files[0];

                        var elements = v_file.name.split('.');
                        var extension = elements[elements.length - 1];

                        if (extension.toString().toUpperCase() != "PDF") {

                            errore = "Formati del file supportati PDF";


                        }

                    }

                }


            }



            if (errore == "") {

                console.log("ok");

                this.saveUploadDoc();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }


        },









        btnCancelUploadRel: function () {

            this.$modal.hide('popupUploadPdf');

        },

        btnConfirmUploadRel: function () {

            var errore = "";

            console.log("VAL PDF REL: ", this.editPdfRel);

            if (this.editPdfRel == 0) {

                if ($('#input-file-logo')[0].files.length == 0) {

                    errore = "Allegato richiesto";


                }
            }



            if (errore == "") {

                if (this.editPdfRel == 0) {

                    if ($('#input-file-logo')[0].files.length > 0) {

                        var v_file = $('#input-file-logo')[0].files[0];

                        var elements = v_file.name.split('.');
                        var extension = elements[elements.length - 1];

                        if (extension.toString().toUpperCase() != "PDF") {

                            errore = "Formati del file supportati PDF";


                        }

                    }

                }



            }



            if (errore == "") {

                console.log("ok");

                this.saveUpload();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }


        },


        saveUploadDoc: async function () {

            this.$modal.hide('popupUploadDoc');

            var that = this;

            var v_token = window.$cookies.get("token");
            var id_azienda = window.$cookies.get("sel_filter_corsi");

            var ids = "";

            $(".val_status").each(function (index, val) {

                if ($(val).prop("checked")) {

                    var id = $(val).attr("data-id");

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + id;

                }

            });


            console.log("TOKEN: ", v_token);
            console.log("ID CORSO: ", ids);
            console.log("ID AZIENDA: ", id_azienda);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.uploadAllegatoCorsoDocumento(
                v_token,
                id_azienda,
                ids,
                $('#input-file-logo')[0].files[0]
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from uploadAllegatoCorsoDocumento", res);


                that.$swal({
                    icon: "success",
                    text: "Allegato documento caricato con successo",
                    showConfirmButton: false,
                    timer: 3000
                });


                window.table.ajax.reload();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },





        saveUpload: async function () {

            this.$modal.hide('popupUploadPdf');

            var that = this;

            var v_token = window.$cookies.get("token");
            var id_azienda = window.$cookies.get("sel_filter_corsi");

            var ids = "";

            $(".val_status").each(function (index, val) {

                if ($(val).prop("checked")) {

                    var id = $(val).attr("data-id");

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + id;

                }

            });


            console.log("TOKEN: ", v_token);
            console.log("ID CORSO: ", ids);
            console.log("ID AZIENDA: ", id_azienda);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.uploadAllegatoCorsoRelazione(
                v_token,
                id_azienda,
                ids,
                $('#input-file-logo')[0].files[0]
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from uploadAllegatoCorsoRelazione", res);


                that.$swal({
                    icon: "success",
                    text: "Allegato relazione caricato con successo",
                    showConfirmButton: false,
                    timer: 3000
                });


                window.table.ajax.reload();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        btnCancelAssocModuli: function () {

            var that = this;

            this.$modal.hide('popupCorsoModuli');


            setTimeout(() => {

                that.dialogMsg = false;
                that.setupButtons();

                that.hideShowButtons(false);

                window.table.ajax.reload();

            }, 200);


        },

        chkSelMod: async function () {

            // console.log("ID: ", $(this));

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("ID MOD SEL: ", that.idModuloSel);

            console.log("ID CORSO SEL: ", ids);

            var response = await apicorsi.setModulo(
                ids,
                that.idModuloSel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setModulo", res);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },

        chkSelArg: function () {

            var errore = "";


            var that = this;

            console.log("ID SEL TOT: ", that.idPermSel);


            var v_tot_min_sel = 0;


            $(".contCheck input").each(function (index, val) {

                console.log("INDEX: ", index);
                console.log("VAL: ", val);

                var v_id = $(this).val();

                //console.log("ID: ", v_id);

                for (var xx = 0; xx < that.idPermSel.length; xx++) {

                    if (parseInt(v_id) == parseInt(that.idPermSel[xx])) {

                        var v_tot = $(this).attr("data_tot");

                        var v_min_val = that.getMinutesTotal(v_tot, ",");

                        //console.log("MIN SEL: ", v_min_val);


                        v_tot_min_sel = v_tot_min_sel + v_min_val;

                    }

                }


            });

            that.disChk = true;

            $(".btn-cancel-search").hide();

            console.log("MIN TOT: ", v_tot_min_sel);

            if (v_tot_min_sel > 480) // al massimo 8 ore di corso nella giornata
            {

                errore = "Selezionare al massimo 8 ore";

            }


            if (errore == "") {

                var v_tot_sel = parseFloat(that.getHMTotal(v_tot_min_sel));

                console.log("HH MM TOT: ", v_tot_sel);


                that.totalHours = v_tot_sel.toFixed(2).toString().replace(".", ",");

                that.totalHoursSelected = v_tot_sel.toFixed(2).toString().replace(".", ",");

            }


            if (errore == "") {

                console.log("ok");





                setTimeout(() => {

                    that.assicGiorniCorsoSave(v_tot_min_sel, v_tot_sel);

                }, 200);




            }
            else {

                that.disChk = false;

                $(".btn-cancel-search").show();

                console.log("ARRAY ERR 1: ", that.idPermSel);
                var element = that.idPermSel.pop();
                console.log("LAST ELEMENT: ", element);
                console.log("ARRAY ERR 2: ", that.idPermSel);



                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }


        },

        assicGiorniCorsoSave: async function (v_tot_min_sel, v_tot_sel) {

            var that = this;

            console.log("ID DATE SEL: ", $("#dateSel").val());

            console.log("NOME ARG: ", that.nomeArgomento);
            console.log("CODICE ARG: ", that.codiceArgomento);

            var str_id = "";
            for (var xx = 0; xx < that.idPermSel.length; xx++) {
                if (str_id != "") {
                    str_id = str_id + ",";
                }
                str_id = str_id + that.idPermSel[xx].toString();

            }

            console.log("IDS: ", str_id);


            var txtDataSel = "";

            for (var xx2 = 0; xx2 < that.dateCorso.length; xx2++) {

                if (parseInt(that.dateCorso[xx2].id) == parseInt($("#dateSel").val())) {
                    txtDataSel = that.dateCorso[xx2].data;
                }

            }

            console.log("DATE SEL: ", txtDataSel);

            console.log("TOT HH SEL: ", v_tot_sel);

            console.log("TOT MIN SEL: ", v_tot_min_sel);


            // id corso selezionato
            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.assocGiorniSave(
                $("#dateSel").val(),
                that.codiceArgomento,
                that.nomeArgomento,
                str_id,
                txtDataSel,
                v_tot_sel,
                v_tot_min_sel,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from assocGiorniSave", res);


                that.timeFrom = res.data.TimeStart;
                that.timeTo = res.data.TimeEnd;


                that.disChk = false;

                $(".btn-cancel-search").show();

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );





        },


        getHMTotal: function (v_m_tot) {

            var result = "";

            var val_hh = parseInt(v_m_tot / 60);

            var val_mm = v_m_tot % 60;

            if (parseInt(val_mm) < 10) {
                val_mm = "0" + val_mm.toString();
            }

            result = val_hh + "." + val_mm;

            return result;


        },

        getTotal: function () {

            var v_tot_min_sel = 0;

            var that = this;

            $(".contCheck input:checked").each(function (index, val) {

                console.log("INDEX: ", index);
                console.log("VAL: ", val);


                var v_tot = $(this).attr("data_tot");

                var v_min_val = that.getMinutesTotal(v_tot, ",");

                v_tot_min_sel = v_tot_min_sel + v_min_val;

            });

            console.log("MIN TOT: ", v_tot_min_sel);

            var v_tot_sel = parseFloat(that.getHMTotal(v_tot_min_sel));

            console.log("HH MM TOT: ", v_tot_sel);


            that.totalHours = v_tot_sel.toFixed(2).toString().replace(".", ",");

            that.totalHoursSelected = v_tot_sel.toFixed(2).toString().replace(".", ",");


        },


        getMinutesTotal: function (v_h_m, val_split) {


            var result = 0;

            var elements = v_h_m.split(val_split);

            result = (parseInt(elements[0]) * 60) + parseInt(elements[1]);

            return result;


        },

        assocModuliGetArgs: async function (v_id_data, v_date_sel) {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });


            console.log("ID CORSO: ", ids);
            console.log("ID DATA: ", v_id_data);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.assocGiorniGetArgs(
                v_id_data,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from assocGiorniGetArgs", res);

                console.log("DATE SEL: ", v_date_sel);

                that.dateSelected = v_date_sel;

                that.timeFrom = res.data.ValOreDalle;
                that.timeTo = res.data.ValOreAlle;

                that.argomenti = res.data.ValArgomenti;


                that.idPermSel = [];

                // 238,239,240,241

                // that.idPermSel.push(238);
                // that.idPermSel.push(239);
                // that.idPermSel.push(240);
                // that.idPermSel.push(241);

                if (res.data.IdElemsSel != null) {

                    console.log("ID PERM SEL 1");


                    if (res.data.IdElemsSel.length > 0) {

                        console.log("ID PERM SEL 2");

                        var v_array = res.data.IdElemsSel.split(",");

                        console.log("ARRAY: ", v_array);

                        for (var xsel = 0; xsel < v_array.length; xsel++)
                        {

                            that.idPermSel.push(parseInt(v_array[xsel]));

                        }

                        //that.idPermSel = res.data.IdElemsSel.split(",");


                    }

                }

                that.viewRow = true;



                setTimeout(() => {




                    that.getTotal();




                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );



        },


        btnCancelModuliGiornate: function () {

            this.$modal.hide('popupModuliGiornate');


        },


        assocModuliGiorni: async function () {

            var that = this;

            that.viewRow = false;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.assocGiorniGetDate(
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from assocGiorniGetDate", res);

                that.dateCorso = res.data.ValDate;


                that.nomeArgomento = res.data.ValNomeArgomento;

                that.codiceArgomento = res.data.ValCodiceArgomento;

                // that.disChk = true;

                setTimeout(() => {


                    $(".contpopupmoduligiornate").show();

                    this.$modal.show('popupModuliGiornate');

                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );







        },


        reportPdf: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var id_azi = window.$cookies.get("sel_filter_corsi");

            var response = await apicorsi.reportPdf(
                ids,
                id_azi
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from reportPdf", res);



                var file_path = res.data.ValUrl;
                var a = document.createElement('A');
                a.href = file_path;
                a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        getModuliCorso: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.getModuli(
                v_token,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getModuli", res);

                that.moduli = res.data.Result;

                that.idModuloSel = res.data.StrModAssoc.split(",");


                setTimeout(() => {


                    $(".contpopupcorsomoduli").show();

                    this.$modal.show('popupCorsoModuli');


                    setTimeout(() => {


                        if (res.data.StrPosModAssoc != null) {

                            var v_array = res.data.StrPosModAssoc.split(",");

                            console.log("ARRAY POS: ", v_array);

                            for (var xx = 0; xx < v_array.length; xx++) {

                                if (v_array[xx] != "") {


                                    var v_array2 = v_array[xx].split("|");


                                    $("#" + v_array2[0]).val(v_array2[1]);

                                }






                            }






                        }






                    }, 300);






                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },






        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziendeSF(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziendeSF", res);

                this.aziende = res.data.Result;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;

            window.$cookies.set("sel_filter_corsi", azienda.companyid, "9y");

            var desc_azi_sel = "";

            for (var xx = 0; xx <= this.aziende.length - 1; xx++) {


                if (parseInt(this.aziende[xx].companyid) == parseInt(azienda.companyid)) {

                    desc_azi_sel = this.aziende[xx].fld_name;
                }


            }

            window.$cookies.set("sel_azi_name", desc_azi_sel, "9y");

            window.NomeAziendaSel = desc_azi_sel;


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            $(".nome_azienda").html(desc_azi_sel);

            window.table.ajax.reload();


        },





        hideShowButtonsSelRow: function () {

            var tot = 0;
            var totPadre = 0;
            var has_rel = 0;
            // var has_doc = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    var ispadre = $(this).attr("is-padre");

                    has_rel = $(this).attr("has-rel");

                    // has_doc = $(this).attr("has-doc");

                    if (ispadre == 1) {

                        totPadre = totPadre + 1;
                    }


                }

            });

            console.log("TOT SEL: ", tot);

            var that = this;

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push(

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                            // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Aggiungi"


                        }

                    );




                }



                if (tot == 1) {


                    if (has_rel == 1) {


                        pulsantis.push(

                            {
                                text: "Add",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/download_pdf_smartform.png",
                                link: "/settings",
                                id: "btn_down_pdf",
                                disabled: false,
                                width: 30,
                                title: "Download Pdf Relazione"


                            }

                        );



                    }




                    pulsantis.push(

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/_lib/img/upload_pdf_smartform.png",
                            link: "/info",
                            id: "btn_upload_pdf",
                            disabled: false,
                            width: 22,
                            title: "Upload PDF Relazione"


                        }

                    );


                    // if (has_doc == 1) {


                    //     pulsantis.push(

                    //         {
                    //             text: "Add",
                    //             icon: "mdi-home-circle",
                    //             image: "https://app.emtool.eu/public/_lib/img/download_doc_smartform.png",
                    //             link: "/settings",
                    //             id: "btn_down_doc",
                    //             disabled: false,
                    //             width: 30,
                    //             title: "Download Pdf Documento"


                    //         }

                    //     );



                    // }


                    if (totPadre == 0) {

                        pulsantis.push(

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                                link: "/info",
                                id: "btn_edit",
                                disabled: false,
                                width: 30,
                                title: "Modifica"


                            },

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/moduli_smartform.png",
                                link: "/info",
                                id: "btn_assoc_moduli",
                                disabled: false,
                                width: 30,
                                title: "Associa Moduli"


                            },


                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/_lib/img/arg_days_smartform.png",
                                link: "/info",
                                id: "btn_assoc",
                                disabled: false,
                                width: 36,
                                title: "Associa Moduli Ai Giorni"


                            },

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/_lib/img/pdf-smartform.png",
                                id: "btn_report",
                                disabled: false,
                                width: 27,
                                title: "Report"


                            },

                            // {
                            //     text: "Edit",
                            //     icon: "mdi-home-circle",
                            //     image: "https://app.emtool.eu/_lib/img/upload_pdf_smartform.png",
                            //     link: "/info",
                            //     id: "btn_upload_pdf",
                            //     disabled: false,
                            //     width: 22,
                            //     title: "Upload PDF Relazione"


                            // },

                            // {
                            //     text: "Edit",
                            //     icon: "mdi-home-circle",
                            //     image: "https://app.emtool.eu/_lib/img/upload_doc_smartform.png",
                            //     link: "/info",
                            //     id: "btn_upload_doc",
                            //     disabled: false,
                            //     width: 22,
                            //     title: "Upload Documento"


                            // },


                        );

                    }

                    if (totPadre > 0) {

                        pulsantis.push(

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                                link: "/info",
                                id: "btn_edit",
                                disabled: false,
                                width: 30,
                                title: "Modifica"


                            },


                        );






                    }




                }

                if (tot > 0) {


                    pulsantis.push(

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            width: 30,
                            title: "Elimina"


                        }

                    );












                }






                that.$root.$children[0].bnavbuttons = pulsantis;






                setTimeout(() => {


                    $('#btn_down_pdf').prop('disabled', false)
                    $('#btn_down_pdf img').css("opacity", "1");



                    if (that.isReadOnly == 1) {


                        $('#btn_add').prop('disabled', true)
                        $('#btn_add img').css("opacity", "0.5");

                        $('#btn_edit').prop('disabled', true)
                        $('#btn_edit img').css("opacity", "0.5");

                        $('#btn_assoc_moduli').prop('disabled', true)
                        $('#btn_assoc_moduli img').css("opacity", "0.5");

                        $('#btn_assoc').prop('disabled', true)
                        $('#btn_assoc img').css("opacity", "0.5");

                        $('#btn_upload_pdf').prop('disabled', true)
                        $('#btn_upload_pdf img').css("opacity", "0.5");

                        $('#btn_upload_doc').prop('disabled', true)
                        $('#btn_upload_doc img').css("opacity", "0.5");

                        $('#btn_delete').prop('disabled', true)
                        $('#btn_delete img').css("opacity", "0.5");

                    }



                }, 200);









            }, 100);



        },



        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;


            var filtri = `<img src='/smartform/img/aziende_smartform.png' style='border:0!important;' class='selazienda' title='Aziende' /><img src='/smartform/img/calendario-giorni-smartform.png' style='border:0!important;' class='selanno' title='Anno' />`;




            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                var v_company = window.$cookies.get("sel_filter_corsi");

                window.curCompany = v_company;
                window.curStatus = "S"; // in forza

                window.columns = [{
                    data: 'sel_row',
                    orderable: false,
                    searchable: false,
                    name: 'sel_row',
                    width: '1%',
                },

                {
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },


                {
                    data: 'nome',
                    orderable: false,
                    name: 'nome',
                    width: '4%'
                },

                {
                    data: 'data_inizio',
                    orderable: false,
                    name: 'data_inizio',
                    width: '4%'
                },

                {
                    data: 'data_fine',
                    orderable: false,
                    name: 'data_fine',
                    width: '4%'
                },

                {
                    data: 'giorni_tot',
                    orderable: false,
                    name: 'giorni_tot',
                    width: '4%'
                },

                {
                    data: 'gg_moduli',
                    orderable: false,
                    name: 'gg_moduli',
                    width: '4%'
                },

                {
                    data: 'file_relazione',
                    orderable: false,
                    name: 'file_relazione',
                    width: '1%'
                },

                    // {
                    //     data: 'file_documento',
                    //     orderable: false,
                    //     name: 'file_documento',
                    //     width: '1%'
                    // },

                ];


                $(".spin").show();



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("SFGetCorsiApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curCompany = window.curCompany;
                            valori.curStatus = window.curStatus;

                            valori.curSrcText = window.curSrcText;

                            valori.curAnno = window.curAnno;

                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);






                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {


                            pointerVue.$root.$children[0].showProgress = false;


                        }, 700);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("SFGetCorsiApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();


                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Corsi</div>
                    ` + filtri + `
                    <div class="nome_azienda">Azienda: ` + window.NomeAziendaSel + `</div>
                    <div class="nome_anno">Anno: ` + window.curAnno + `</div>


                </div>
                <div><img src='/smartform/img/ricerca.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();
                    window.curStatus = this.value;



                    window.table.ajax.reload();

                });



                $('body').on('click', '.selpartecipanti', function () {


                    var ids = "";

                    $(".val_status").each(function (index, val) {

                        if ($(val).prop("checked")) {

                            var id = $(val).attr("data-id");

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + id;

                        }

                    });


                    console.log("IDS: ", ids);


                    if (ids != "") {

                        console.log("ok");

                        pointerVue.initPartecipanti(ids);

                    }
                    else {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Selezionare uno o più dipendenti/collaboratori",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }


                });







                $('body').on('click', '.cont_corsi_smartform tr', function () {


                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }



                });



                window.parentiAddedEvents = true;

            });

        },

        initPartecipanti: async function (list_id) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apirisorse.initPartecipanti(
                list_id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initPartecipanti", res);

                router.push({
                    path: "/partecipanti"
                });

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                            //class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Aggiungi"

                        },


                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                            //class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Aggiungi"

                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                            //class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            width: 30,
                            title: "Modifica"

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            width: 30,
                            title: "Elimina"

                        },




                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;




                setTimeout(() => {



                    if (this.isReadOnly == 1) {


                        $('#btn_add').prop('disabled', true)
                        $('#btn_add img').css("opacity", "0.5");

                    }



                }, 200);



            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                        // class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30,
                        title: "Aggiungi"

                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                        // class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30,
                        title: "Modifica"

                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                        //class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30,
                        title: "Elimina"

                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },



        deleteRelazione: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare il file della relazione ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {


                        that.$modal.hide('popupUploadPdf');




                        var id_azienda = window.$cookies.get("sel_filter_corsi");

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicorsi.deleteFileRelazione(
                            ids,
                            id_azienda
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteFileRelazione", res);

                            that.$swal({
                                icon: "success",
                                text: "Relazione eliminata correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },





        deleteDocumento: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare il file del documento ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {


                        that.$modal.hide('popupUploadDoc');




                        var id_azienda = window.$cookies.get("sel_filter_corsi");

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicorsi.deleteFileDocumento(
                            ids,
                            id_azienda
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteFileDocumento", res);

                            that.$swal({
                                icon: "success",
                                text: "Documento eliminato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },












        deleteRow: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicorsi.deleteCorso(
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteCorso", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.contpopupmoduligiornate .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}

.contpopupmoduligiornate .cl-sec-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}

.contpopupmoduligiornate .v-input.cl-checkbox.theme--light.v-input--selection-controls.v-input--checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}



.contSecCheck {
    align-items: top;
}



.cont_corsi_smartform .dataTable tbody tr,
.cont_corsi_smartform .dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
    /* padding-bottom: 4px !important; */
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #00a6ff;

}

.pay a {
    color: #00a6ff;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #00a6ff;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}


ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_corsi_smartform .val_status {
    width: 10px !important;
}


.cont_corsi_smartform #myTable {
    margin-right: 3px;
    width: 685px !important;
}


.cont_corsi_smartform table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_corsi_smartform table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    /* padding-top: 5px !important;
    vertical-align: middle; */
}

.cont_corsi_smartform th {
    padding-left: 5px !important;
}

.cont_corsi_smartform ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_corsi_smartform .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}

.cont_corsi_smartform #myTable_filter {
    top: 66px;
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #0084f3;
}

.cont_corsi_smartform div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_corsi_smartform #myTable_wrapper {
    min-width: initial;
    min-width: 700px;
    max-width: 700px;
    width: 700px;
}

.cont_corsi_smartform .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_corsi_smartform .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_corsi_smartform .selectstatusall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/emt/img/Filtro.png);
    text-indent: -119px;
    background-size: 24px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -5px !important;

}

.cont_corsi_smartform .nome_azienda {
    color: black;
    font-weight: bold;
    margin-left: 35px;
}

.cont_corsi_smartform .nome_anno {
    color: black;
    font-weight: bold;
    margin-left: 35px;
}

.cont_corsi_smartform .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: black !important;
}

.cont_corsi_smartform .cl_checkall {
    margin-left: -14px
}


.cont_corsi_smartform .cl_checkall {
    padding-left: 15px;
    padding-top: 2px;
}

.cont_corsi_smartform .checkall {
    width: 13px;
    height: 13px;
}


.cont_corsi_smartform .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_corsi_smartform .selpartecipanti {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_corsi_smartform .selanno {
    max-width: 30px;
    min-height: 30px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 30px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


.cont_corsi_smartform .selazienda {
    max-width: 30px;
    min-height: 30px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 30px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemAzienda {
    margin-left: 15px;
}


@media screen and (max-width: 960px) {

    .cont_corsi_smartform .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_corsi_smartform #myTable {
        /* margin-top:54px; */
    }

    .cont_corsi_smartform #myTable_filter {
        top: 56px;
        position: fixed;

    }



}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_corsi_smartform #myTable {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_corsi_smartform #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_corsi_smartform .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_corsi_smartform #myTable_wrapper {
        min-width: initial;
        min-width: 95vw !important;
        max-width: 95vw !important;
        width: 95vw !important;
        overflow-x: hidden !important;
    }

    .cont_corsi_smartform .cl_num {
        margin-left: -3px !important;
    }

    .cl_n_matricola {
        display: inline-flex;
    }

    .cl_desc_project {
        display: inline-flex;
    }

    .cl_costo_giornaliero {
        display: inline-flex;
    }

    .cl_diaria {
        display: inline-flex;
    }

    .cl_ticket {
        display: inline-flex;
    }

    .cl_piano_orario {
        display: inline-flex;
    }

    .cl_ebadge {
        display: inline-flex;
    }

    .cl_totale_ferie {
        display: inline-flex;
    }

    .cont_corsi_smartform .cl_checkall {
        margin-left: -14px
    }


}
</style>
