<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_partecipanti_corso">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Cognome</th>
                                        <th class='desktop'>Nome</th>
                                        <th class='desktop'>Tipo Contratto</th>
                                        <th class='desktop' style="text-align:center">Insegnante</th>
                                        <th class='desktop' style="text-align:center">Esterno</th>

                                        <!-- <th class='desktop' style="text-align:right;padding-right:5px">Costo D (€)</th> -->

                                        <th class='desktop' style="text-align:right;padding-right:5px">Costo H (€)</th>

                                        <!-- <th class='desktop' style="text-align:center">GG Prev.</th> -->

                                        <th class='desktop' style="text-align:center">HH Prev.</th>

                                        <!-- <th class='desktop' style="text-align:center">GG Eff.</th> -->

                                        <th class='desktop' style="text-align:center">HH Eff.</th>

                                        <!-- <th class='all' style="text-align:right;padding-right:5px">Tot. Pr. GG (€)</th> -->

                                        <th class='all' style="text-align:right;padding-right:5px">Tot. Pr. HH (€)</th>


                                        <th class='desktop' style="text-align:right;padding-right:5px">Perc. Ric.</th>

                                        <!-- <th class='all' style="text-align:right;padding-right:5px">Tot. Cr. Pr. GG (€)</th> -->

                                        <th class='all' style="text-align:right;padding-right:5px">Tot. Cr. Pr. HH (€)
                                        </th>



                                        <!-- <th class='all' style="text-align:right;padding-right:5px">Tot. Eff. GG (€)</th> -->

                                        <th class='all' style="text-align:right;padding-right:5px">Tot. Eff. HH (€)</th>

                                        <!-- <th class='all' style="text-align:right;padding-right:5px">Tot. Cr. Eff. GG (€)</th> -->

                                        <th class='all' style="text-align:right;padding-right:5px">Tot. Cr. Eff. HH (€)
                                        </th>

                                        <th class='desktop'>CV</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>
                                            <div class="div_n">&nbsp;</div>
                                        </th>

                                        <th class='all col_footer'>Totale (€)</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <!-- <th class='desktop col_footer'>&nbsp;</th> -->

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <!-- <th class='desktop col_footer'>&nbsp;</th> -->

                                        <th class='desktop col_footer_center' id="v_tot_ore_prev">0,00</th>

                                        <!-- <th class='desktop col_footer'>&nbsp;</th> -->

                                        <th class='desktop col_footer_center' id="v_tot_min_ore_eff">0,00</th>

                                        <!-- <th class='all col_footer_right' id="v_tot_totale">0,00</th> -->

                                        <th class='all col_footer_right' id="v_tot_totale_ore_prev">0,00</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <!-- <th class='all col_footer_right' id="v_tot_credito">0,00</th> -->

                                        <th class='all col_footer_right' id="v_tot_credito_ore_prev">0,00</th>

                                        <!-- <th class='desktop col_footer_right' id="v_tot_eff">0,00</th> -->

                                        <th class='all col_footer_right' id="v_tot_ore_eff">0,00</th>

                                        <!-- <th class='desktop col_footer_right' id="v_tot_cred_eff">0,00</th> -->

                                        <th class='all col_footer_right' id="v_tot_cred_ore_eff">0,00</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                    </tr>


                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/smartform_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende_smartform.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpopupuploadzip" style="display:none">

            <template>
                <modal name="popupUploadZip" :clickToClose="false" :width="400" :height="415">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-left: 10px;margin-top: 10px;
    font-weight: bold;">
                            Upload Zip LUL
                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="max-width: 378px;">


                                <span class="files">
                                    <input type="file" id="input-file-logo" name="logo" accept=".zip" class="dropify"
                                        data-max-width="6610" data-max-height="6200" />
                                </span>

                            </div>



                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload-zip" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelUploadZip" />

                                <v-img alt="" class="btn-confirm-upload-zip" contain
                                    src="@/assets/btn_confirm_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmUploadZip" />

                                <v-img v-if="viewDeleteZip" alt="" class="btn-delete-file-zip" contain
                                    src="@/assets/delete_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Elimina"
                                    @click="btnDeleteFileZip" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupuploadcv" style="display:none">

            <template>
                <modal name="popupUploadCV" :clickToClose="false" :width="400" :height="415">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-left: 10px;margin-top: 10px;
font-weight: bold;">
                            Upload CV
                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="max-width: 378px;">


                                <span class="files">
                                    <input type="file" id="input-file-logo" name="logo" accept=".pdf" class="dropify"
                                        data-max-width="6610" data-max-height="6200" />
                                </span>

                            </div>



                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload-cv" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelUploadCV" />

                                <v-img alt="" class="btn-confirm-upload-cv" contain src="@/assets/btn_confirm_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;margin-right:10px"
                                    transition="scale-transition" title="Conferma" @click="btnConfirmUploadCV" />

                                <v-img v-if="viewDeleteCV" alt="" class="btn-delete-file-cv" contain
                                    src="@/assets/delete_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Elimina"
                                    @click="btnDeleteFileCV" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




    </div>
</template>

<script>

import apirisorse from "../utils/risorse/apirisorse";
import apiusers from "../utils/users/apiusers";
import apicorsi from "../utils/corsi/apicorsi";

import {
    bus
} from "../main";
import router from ".././router";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        this.isReadOnly = this.$root.$children[0].getValueReadOnly("partecipanti2_smartform_ro");

        console.log("IS RO: ", this.isReadOnly);

        window.curSrcText = "";
        window.curCompany = window.$cookies.get("sel_filter_corsi");

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");


        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('change', '#input-file-logo', function () {

                console.log(("EVENTO CHANGE INPUTFILELOG"));


                pointerVue.editZipLul = 0;

                pointerVue.editPdfCV = 0;


            });

            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        // setTimeout(() => {

        //     this.syncAziende();

        // }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;

            var ids = "";

            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editRisorsa/0"
                    });
                    break;

                case "btn_edit":




                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editRisorsa/" + ids
                    });


                    // if (window.id_par_sel == 0) {

                    //     this.$swal({
                    //         icon: "error",
                    //         text: "Selezionare la riga da modifcare",
                    //         showConfirmButton: false,
                    //         timer: 2000
                    //     });

                    // }

                    // if (window.id_par_sel > 0) {

                    //     router.push({
                    //         path: "/editRisorsa/" + window.id_par_sel
                    //     });

                    // }

                    break;

                case "btn_back":

                    router.push({
                        path: "/filterPartecipanti"
                    });

                    break;


                case "btn_upload_zip":


                    this.viewDeleteZip = false;

                    this.editZipLul = 0;


                    $(".contpopupuploadzip").show();

                    this.$modal.show('popupUploadZip');

                    setTimeout(() => {

                        var oggDrop = $('.dropify').dropify({
                            messages: {
                                default: 'Drag',
                                replace: /* s */ '',
                                remove: 'Rimuovi',
                                error: 'File troppo grande'
                            }
                        });

                        //$(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")

                        $(".dropify-clear").remove();
                        $(".dropify-render").append("<img>");

                        oggDrop.on('dropify.afterClear', function (event, element) {

                            console.log(event, element);


                        });



                        var val_url2 = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                val_url2 = $(this).attr("url-id");

                            }

                        });

                        console.log("PDF RELAZIONE: ", val_url2);


                        if (val_url2 != "") {

                            $('.dropify-render').append("<i class='dropify-font-file'></i>");
                            $('.dropify-render').append("<span class='dropify-extension'>zip</span>");

                            // dropify-font-file
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                            this.viewDeleteZip = true;


                            this.editZipLul = 1;


                        }





                    }, 200);


                    break;



                case "btn_down_zip":


                    var val_url = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            val_url = $(this).attr("url-id");

                        }

                    });

                    console.log("URL VALUE: ", val_url);


                    var url_call = this.getUrlDomain("TwoRecruitCvRicDownloadApi") + "?f=" + val_url;

                    console.log("URL TO CALL: ", url_call);


                    window.open(url_call, "_blank");


                    break;


                case "btn_upload_cv":

                    this.viewDeleteCV = false;

                    this.editPdfCV = 0;

                    $(".contpopupuploadcv").show();

                    this.$modal.show('popupUploadCV');

                    setTimeout(() => {

                        var oggDrop = $('.dropify').dropify({
                            messages: {
                                default: 'Drag',
                                replace: /* s */ '',
                                remove: 'Rimuovi',
                                error: 'File troppo grande'
                            }
                        });

                        $(".dropify-clear").remove();

                        $(".dropify-render").append("<img>");

                        oggDrop.on('dropify.afterClear', function (event, element) {

                            console.log(event, element);


                        });


                        var val_url3 = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                val_url3 = $(this).attr("url-cv");

                            }

                        });

                        console.log("PDF RELAZIONE: ", val_url3);


                        if (val_url3 != "") {

                            $('.dropify-render').append("<i class='dropify-font-file'></i>");
                            $('.dropify-render').append("<span class='dropify-extension'>pdf</span>");

                            // dropify-font-file
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                            this.viewDeleteCV = true;


                            this.editPdfCV = 1;


                        }


                    }, 200);


                    break;


                case "btn_down_cv":


                    var val_url2 = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            val_url2 = $(this).attr("url-cv");

                        }

                    });

                    console.log("URL VALUE: ", val_url2);


                    var file_path = val_url2;
                    var a = document.createElement('A');
                    a.href = file_path;
                    a.target = "_blank";
                    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);


                    // var url_call2 = this.getUrlDomain("TwoRecruitCvRicDownloadApi") + "?f=" + val_url2;

                    // console.log("URL TO CALL: ", url_call2);


                    // window.open(url_call2, "_blank");


                    break;




                default:
                    break;

            }
        });


        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({

        editPdfCV: 0,
        editZipLul: 0,


        viewDeleteCV: false,

        viewDeleteZip: false,

        isReadOnly: 0,

        aziende: [],

        sheetAziende: false,
        search: "",

        textSearch: "",

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')


        },

    },



    methods: {


        convOreInMinuti: function (v_ore) {

            console.log("VAL ORE: " + v_ore);

            var elements = v_ore.split(",");

            console.log(elements);

            var result = (parseInt(elements[0]) * 60) + parseInt(elements[1]);

            console.log("CON ORE RESULT: " + result);

            return result;

        },

        convMinutiInOre: function (v_minuti) {

            var v_hours = Math.floor(v_minuti / 60);
            var v_minutes = v_minuti % 60;
            if (v_minutes < 10) {
                v_minutes = "0" + v_minutes.toString();
            }
            var result = v_hours.toString() + "." + v_minutes.toString();

            return result;
        },


        btnDeleteFileCV: function () {

            this.deleteCV();

        },

        deleteCV: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare il cv ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {


                        that.$modal.hide('popupUploadCV');


                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicorsi.deleteFileCV(
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteFileCV", res);

                            that.$swal({
                                icon: "success",
                                text: "CV eliminato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },



        deleteZip: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare il file zip lul ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {


                        that.$modal.hide('popupUploadZip');




                        // var id_azienda = window.$cookies.get("sel_filter_corsi");

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicorsi.deleteZipLul(
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteZipLul", res);

                            that.$swal({
                                icon: "success",
                                text: "Zip lul eliminato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },





        btnDeleteFileZip: function () {

            this.deleteZip();

        },


        btnCancelUploadCV: function () {

            this.$modal.hide('popupUploadCV');

        },

        btnConfirmUploadCV: function () {

            var errore = "";

            if (this.editPdfCV == 0) {

                if ($('#input-file-logo')[0].files.length == 0) {

                    errore = "Allegato richiesto";


                }

            }


            if (errore == "") {

                if (this.editPdfCV == 0) {

                    if ($('#input-file-logo')[0].files.length > 0) {

                        var v_file = $('#input-file-logo')[0].files[0];

                        var elements = v_file.name.split('.');
                        var extension = elements[elements.length - 1];

                        if (extension.toString().toUpperCase() != "PDF") {

                            errore = "Formati del file supportati PDF";


                        }

                    }

                }



            }



            if (errore == "") {

                console.log("ok");

                this.saveUploadCV();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }


        },












        btnCancelUploadZip: function () {

            this.$modal.hide('popupUploadZip');

        },

        btnConfirmUploadZip: function () {

            var errore = "";

            if (this.editZipLul == 0) {

                if ($('#input-file-logo')[0].files.length == 0) {

                    errore = "Allegato richiesto";


                }


            }


            if (errore == "") {

                if (this.editZipLul == 0) {

                    if ($('#input-file-logo')[0].files.length > 0) {

                        var v_file = $('#input-file-logo')[0].files[0];

                        var elements = v_file.name.split('.');
                        var extension = elements[elements.length - 1];

                        if (extension.toString().toUpperCase() != "ZIP") {

                            errore = "Formati del file supportati ZIP";


                        }

                    }


                }


            }



            if (errore == "") {

                console.log("ok");

                this.saveUpload();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }


        },


        saveUpload: async function () {

            this.$modal.hide('popupUploadZip');

            var that = this;

            var v_token = window.$cookies.get("token");
            var id_corso = window.$cookies.get("id_sel_corso");
            var id_azienda = window.$cookies.get("sel_filter_corsi");

            var ids = "";

            $(".val_status").each(function (index, val) {

                if ($(val).prop("checked")) {

                    var id = $(val).attr("data-id");

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + id;

                }

            });


            console.log("TOKEN: ", v_token);
            console.log("ID CORSO: ", id_corso);
            console.log("ID PARTECIPANTE: ", ids);
            console.log("ID AZIENDA: ", id_azienda);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.uploadAllegatoPartAssign(
                v_token,
                id_corso,
                id_azienda,
                ids,
                $('#input-file-logo')[0].files[0]
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from uploadAllegatoPartAssign", res);


                that.$swal({
                    icon: "success",
                    text: "Allegato LUL caricato con successo",
                    showConfirmButton: false,
                    timer: 3000
                });


                window.table.ajax.reload();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        saveUploadCV: async function () {

            this.$modal.hide('popupUploadCV');

            var that = this;

            var v_token = window.$cookies.get("token");
            var id_corso = window.$cookies.get("id_sel_corso");
            var id_azienda = window.$cookies.get("sel_filter_corsi");

            var ids = "";

            $(".val_status").each(function (index, val) {

                if ($(val).prop("checked")) {

                    var id = $(val).attr("data-id");

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + id;

                }

            });


            console.log("TOKEN: ", v_token);
            console.log("ID CORSO: ", id_corso);
            console.log("ID PARTECIPANTE: ", ids);
            console.log("ID AZIENDA: ", id_azienda);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.uploadAllegatoCVPartAssign(
                v_token,
                id_corso,
                id_azienda,
                ids,
                $('#input-file-logo')[0].files[0]
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from uploadAllegatoCVPartAssign", res);


                that.$swal({
                    icon: "success",
                    text: "Allegato CV caricato con successo",
                    showConfirmButton: false,
                    timer: 3000
                });


                window.table.ajax.reload();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },






        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziende(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);

                try {

                    this.aziende = res.data.company;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },





        hideShowButtonsSelRow: function () {

            var tot = 0;
            var has_lul = 0;
            var has_cv = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;


                    has_lul = $(this).attr("has-lul");

                    has_cv = $(this).attr("has-cv");

                }

            });

            console.log("TOT SEL: ", tot);

            setTimeout(() => {

                var pulsantis = [];


                if (tot == 1) {

                    if (has_lul == 1) {

                        console.log("ok");


                        // pulsantis.push(

                        //     {
                        //         text: "Add",
                        //         icon: "mdi-home-circle",
                        //         image: "https://app.emtool.eu/public/_lib/img/download_zip.png",
                        //         link: "/settings",
                        //         id: "btn_down_zip",
                        //         disabled: false,
                        //         width: 25,
                        //         title: "Download Zip LUL"


                        //     }

                        // );



                    }




                    if (has_cv == 1) {


                        pulsantis.push(

                            {
                                text: "Add",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/download_cv.png",
                                link: "/settings",
                                id: "btn_down_cv",
                                disabled: false,
                                width: 25,
                                title: "Download CV"


                            }

                        );



                    }








                    // pulsantis.push(

                    //     {
                    //         text: "Add",
                    //         icon: "mdi-home-circle",
                    //         image: "https://app.emtool.eu/public/_lib/img/upload_zip_smartform.png",
                    //         link: "/settings",
                    //         id: "btn_upload_zip",
                    //         disabled: false,
                    //         width: 22,
                    //         title: "Upload Zip LUL"


                    //     }

                    // );


                    pulsantis.push(

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/upload_cv_smartform.png",
                            link: "/settings",
                            id: "btn_upload_cv",
                            disabled: false,
                            width: 22,
                            title: "Upload CV"


                        }

                    );





                }




                pulsantis.push(

                    {
                        text: "Parenti",
                        icon: "mdi-arrow-left",
                        link: "/tipologiapagamento",
                        id: "btn_back",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/back_smartform.png",
                        title: "Indietro",
                        width: 30


                    }

                );


                this.$root.$children[0].bnavbuttons = pulsantis;


                setTimeout(() => {


                    // btn_down_zip
                    $('#btn_down_zip').prop('disabled', false)
                    $('#btn_down_zip img').css("opacity", "1");


                    if (this.isReadOnly == 1) {


                        $('#btn_upload_zip').prop('disabled', true)
                        $('#btn_upload_zip img').css("opacity", "0.5");

                        $('#btn_upload_cv').prop('disabled', true)
                        $('#btn_upload_cv img').css("opacity", "0.5");

                    }



                }, 200);




            }, 100);



        },



        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;



            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                var v_id_corso = window.$cookies.get("id_sel_corso");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: 'sel_row',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'cognome',
                        orderable: false,
                        name: 'cognome',
                        width: '4%'
                    },


                    {
                        data: 'nome',
                        orderable: false,
                        name: 'nome',
                        width: '4%'
                    },


                    {
                        data: 'contratto',
                        orderable: false,
                        name: 'contratto',
                        width: '5%'
                    },

                    {
                        data: 'icn_ins',
                        orderable: false,
                        name: 'icn_ins',
                        width: '1%'
                    },

                    {
                        data: 'icn_est',
                        orderable: false,
                        name: 'icn_est',
                        width: '1%'
                    },

                    // {
                    //     data: 'costo_giornaliero',
                    //     orderable: false,
                    //     name: 'costo_giornaliero',
                    //     width: '4%'
                    // },

                    {
                        data: 'costo_ora',
                        orderable: false,
                        name: 'costo_ora',
                        width: '4%'
                    },

                    // {
                    //     data: 'giorni',
                    //     orderable: false,
                    //     name: 'giorni',
                    //     width: '2%'
                    // },

                    {
                        data: 'ore_prev',
                        orderable: false,
                        name: 'ore_prev',
                        width: '2%'
                    },



                    // {
                    //     data: 'giorni_effettivi',
                    //     orderable: false,
                    //     name: 'giorni_effettivi',
                    //     width: '2%'
                    // },

                    {
                        data: 'ore_effettive',
                        orderable: false,
                        name: 'ore_effettive',
                        width: '2%'
                    },





                    // {
                    //     data: 'totale',
                    //     orderable: false,
                    //     name: 'totale',
                    //     width: '5%'
                    // },

                    {
                        data: 'totale_ore_prev',
                        orderable: false,
                        name: 'totale_ore_prev',
                        width: '5%'
                    },

                    {
                        data: 'perc_riconosciuta',
                        orderable: false,
                        name: 'perc_riconosciuta',
                        width: '4%'
                    },

                    // {
                    //     data: 'credito',
                    //     orderable: false,
                    //     name: 'credito',
                    //     width: '6%'
                    // },

                    {
                        data: 'credito_ore_prev',
                        orderable: false,
                        name: 'credito_ore_prev',
                        width: '6%'
                    },



                    // {
                    //     data: 'totale_effettivo',
                    //     orderable: false,
                    //     name: 'totale_effettivo',
                    //     width: '5%'
                    // },

                    {
                        data: 'totale_ore_effettivo',
                        orderable: false,
                        name: 'totale_ore_effettivo',
                        width: '5%'
                    },


                    // {
                    //     data: 'credito_effettivo',
                    //     orderable: false,
                    //     name: 'credito_effettivo',
                    //     width: '6%'
                    // },

                    {
                        data: 'credito_ore_effettivo',
                        orderable: false,
                        name: 'credito_ore_effettivo',
                        width: '6%'
                    },

                    {
                        data: 'cv_allegato',
                        orderable: false,
                        name: 'cv_allegato',
                        width: '1%'
                    },

                ];


                $(".spin").show();



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("SFCorsiPartAssocApi") + '?username=' + v_token + "&id=" + v_id_corso,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curCompany = window.curCompany;
                            valori.curStatus = window.curStatus;

                            valori.curSrcText = window.curSrcText;


                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);



                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {


                            pointerVue.$root.$children[0].showProgress = false;


                        }, 700);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                        $('.p_costo_giornaliero, .p_totale, .p_perc_ric, .p_credito, .p_giorni_effettivi, .p_totale_eff, .p_credito_eff, .p_giorni_prev, .p_costo_ora, .p_ore_prev, .p_totale_ore_prev, .p_credito_ore_prev, .p_ore_effettive, .p_totale_ore_eff, .p_credito_ore_eff').number(true, 2, ',', '.');


                        var totale = 0;
                        $(".p_totale").each(function () {
                            totale = totale + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var totale_credito = 0;
                        $(".p_credito").each(function () {
                            totale_credito = totale_credito + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var totale_effettivo = 0;
                        $(".p_totale_eff").each(function () {
                            totale_effettivo = totale_effettivo + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var totale_credito_eff = 0;
                        $(".p_credito_eff").each(function () {
                            totale_credito_eff = totale_credito_eff + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });




                        var totale_ore_prev = 0;
                        $(".p_totale_ore_prev").each(function () {
                            totale_ore_prev = totale_ore_prev + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });


                        var totale_credito_ore_prev = 0;
                        $(".p_credito_ore_prev").each(function () {
                            totale_credito_ore_prev = totale_credito_ore_prev + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });


                        var totale_ore_effettivo = 0;
                        $(".p_totale_ore_eff").each(function () {
                            totale_ore_effettivo = totale_ore_effettivo + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });


                        var totale_credito_ore_eff = 0;
                        $(".p_credito_ore_eff").each(function () {
                            totale_credito_ore_eff = totale_credito_ore_eff + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });



                        var totale_minuti_ore_prev = 0;
                        $(".p_ore_prev").each(function () {
                            totale_minuti_ore_prev = totale_minuti_ore_prev + pointerVue.convOreInMinuti($(this).text());
                        });

                        console.log("SUM MIN ORE PREV: ", totale_minuti_ore_prev);

                        var totale_min_ore_prev = pointerVue.convMinutiInOre(totale_minuti_ore_prev);

                        console.log("CONV MIN IN ORE ORE PREV: ", totale_min_ore_prev);




                        var totale_minuti_ore_eff = 0;
                        $(".p_ore_effettive").each(function () {
                            totale_minuti_ore_eff = totale_minuti_ore_eff + pointerVue.convOreInMinuti($(this).text());
                        });


                        console.log("SUM MIN ORE EFF: ", totale_minuti_ore_eff);

                        var totale_min_ore_eff = pointerVue.convMinutiInOre(totale_minuti_ore_eff);

                        console.log("CONV MIN IN ORE ORE EFF: ", totale_min_ore_eff);






                        $("#v_tot_totale").text(totale.toString().replace(".", ","));
                        $('#v_tot_totale').number(true, 2, ',', '.');

                        $("#v_tot_credito").text(totale_credito.toString().replace(".", ","));
                        $('#v_tot_credito').number(true, 2, ',', '.');

                        $("#v_tot_eff").text(totale_effettivo.toString().replace(".", ","));
                        $('#v_tot_eff').number(true, 2, ',', '.');

                        $("#v_tot_cred_eff").text(totale_credito_eff.toString().replace(".", ","));
                        $('#v_tot_cred_eff').number(true, 2, ',', '.');

                        $("#v_tot_totale_ore_prev").text(totale_ore_prev.toString().replace(".", ","));
                        $('#v_tot_totale_ore_prev').number(true, 2, ',', '.');

                        $("#v_tot_credito_ore_prev").text(totale_credito_ore_prev.toString().replace(".", ","));
                        $('#v_tot_credito_ore_prev').number(true, 2, ',', '.');

                        $("#v_tot_ore_eff").text(totale_ore_effettivo.toString().replace(".", ","));
                        $('#v_tot_ore_eff').number(true, 2, ',', '.');


                        $("#v_tot_cred_ore_eff").text(totale_credito_ore_eff.toString().replace(".", ","));
                        $('#v_tot_cred_ore_eff').number(true, 2, ',', '.');


                        $("#v_tot_ore_prev").text(totale_min_ore_prev.toString().replace(".", ","));
                        $('#v_tot_ore_prev').number(true, 2, ',', '.');


                        $("#v_tot_min_ore_eff").text(totale_min_ore_eff.toString().replace(".", ","));
                        $('#v_tot_min_ore_eff').number(true, 2, ',', '.');





                        if (window.innerWidth <= 768) {


                            $("#codice_corso_mob").html(window.$cookies.get("code_sel_corso"));
                            $("#nome_corso_mob").html(window.$cookies.get("name_sel_corso"));
                            $("#inizio_corso_mob").html(window.$cookies.get("data_inizio_sel_corso"));
                            $("#fine_corso_mob").html(window.$cookies.get("data_fine_sel_corso"));
                            $("#nome_azienda_mob").html(window.$cookies.get("nome_azi_sel_corso"));



                        }
                        else {

                            $("#codice_corso").html(window.$cookies.get("code_sel_corso"));
                            $("#nome_corso").html(window.$cookies.get("name_sel_corso"));
                            $("#inizio_corso").html(window.$cookies.get("data_inizio_sel_corso"));
                            $("#fine_corso").html(window.$cookies.get("data_fine_sel_corso"));
                            $("#nome_azienda").html(window.$cookies.get("nome_azi_sel_corso"));



                        }




                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("SFCorsiPartAssocApi") + '?username=' + v_token + "&id=" + v_id_corso,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();


                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="header-no-mobile">
                        
                        <div class="text_title">Partecipanti</div>

                        <div class="info-corso">
                            <div style="font-weight:bold;margin-left:32px">Azienda:</div>
                            <div style="font-weight:bold;margin-left:5px" id="nome_azienda"></div>
                            <div class="clDivisor"></div>
                            <div style="font-weight:bold">Codice Corso:</div>
                            <div style="font-weight:bold;margin-left:5px" id="codice_corso"></div>
                            <div class="clDivisor"></div>
                            <div style="font-weight:bold">Nome Corso:</div>
                            <div style="font-weight:bold;margin-left:5px" id="nome_corso"></div>
                            <div class="clDivisor"></div>
                            <div style="font-weight:bold">Data Inizio Corso:</div>
                            <div style="font-weight:bold;margin-left:5px" id="inizio_corso"></div>
                            <div class="clDivisor"></div>
                            <div style="font-weight:bold">Data Fine Corso:</div>
                            <div style="font-weight:bold;margin-left:5px" id="fine_corso"></div>
                        </div>
    
                        
                    </div>

                    <div class="header-mobile">

                        <div class="text_title">Partecipanti</div>

                        <div class="info-corso-mobile">
                            <div style="display:table-row">
                                <div style="display:table-cell;font-weight:bold;font-size:9px;width:60px">Azienda:</div>
                                <div style="display:table-cell;font-weight:bold;font-size:9px;width:77px" id="nome_azienda_mob"></div>
                            </div>
                            <div style="display:table-row">
                                <div style="display:table-cell;font-weight:bold;font-size:9px;width:60px">Cod. Corso:</div>
                                <div style="display:table-cell;font-weight:bold;font-size:9px;width:77px" id="codice_corso_mob"></div>
                                <div style="display:table-cell;font-weight:bold;font-size:9px;width:60px">Nome Corso:</div>
                                <div style="display:table-cell;font-weight:bold;font-size:9px;width:91px" id="nome_corso_mob"></div>
                            </div>
                            <div style="display:table-row">
                                <div style="display:table-cell;font-weight:bold;font-size:9px;width:60px">Inizio Corso:</div>
                                <div style="display:table-cell;font-weight:bold;font-size:9px;width:77px" id="inizio_corso_mob"></div>
                                <div style="display:table-cell;font-weight:bold;font-size:9px;width:60px">Fine Corso:</div>
                                <div style="display:table-cell;font-weight:bold;font-size:9px;width:56px" id="fine_corso_mob"></div>
                            </div>
                         </div>
                        
                        
                    </div>
                   
             
                </div>
                <div><img src='/smartform/img/ricerca.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();
                    window.curStatus = this.value;



                    window.table.ajax.reload();

                });



                $('body').on('click', '.selpartecipanti', function () {


                    var ids = "";

                    $(".val_status").each(function (index, val) {

                        if ($(val).prop("checked")) {

                            var id = $(val).attr("data-id");

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + id;

                        }

                    });


                    console.log("IDS: ", ids);


                    if (ids != "") {

                        console.log("ok");

                        pointerVue.initPartecipanti(ids);

                    }
                    else {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Selezionare uno o più dipendenti/collaboratori",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }


                });







                $('body').on('click', '.cont_partecipanti_corso tr', function () {


                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }



                });



                window.parentiAddedEvents = true;

            });

        },

        initPartecipanti: async function (list_id) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apirisorse.initPartecipanti(
                list_id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initPartecipanti", res);

                router.push({
                    path: "/partecipanti"
                });

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Parenti",
                            icon: "mdi-arrow-left",
                            link: "/tipologiapagamento",
                            id: "btn_back",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/back_smartform.png",
                            title: "Indietro",
                            width: 30
                        },





                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Parenti",
                            icon: "mdi-arrow-left",
                            link: "/tipologiapagamento",
                            id: "btn_back",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/back_smartform.png",
                            title: "Indietro",
                            width: 30
                        },



                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                        // class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30,
                        title: "Aggiungi"

                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                        // class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30,
                        title: "Modifica"

                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                        //class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30,
                        title: "Elimina"

                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        deleteRow: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok",
                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apirisorse.deleteRisorsa(
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteRisorsa", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.header-no-mobile {
    display: flex;
    align-items: center;
}

.info-corso {
    display: flex;
}

.info-corso-mobile {
    display: none;
}


.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #00a6ff;

}

.pay a {
    color: #00a6ff;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #00a6ff;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}


ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/smartb/img/FiltroNucleo.png);
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_partecipanti_corso .val_status {
    width: 10px !important;
}


.cont_partecipanti_corso #myTable {
    margin-right: 3px;
    width: 1785px !important;
}


.cont_partecipanti_corso table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_partecipanti_corso table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-top: 5px !important;
    vertical-align: middle;
}

.cont_partecipanti_corso th {
    padding-left: 5px !important;
}

.cont_partecipanti_corso ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_partecipanti_corso .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}

.cont_partecipanti_corso #myTable_filter {
    top: 66px;
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #0084f3;
}

.cont_partecipanti_corso div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_partecipanti_corso #myTable_wrapper {
    padding-top: 0px;
    min-width: 1800px;
    max-width: 1800px;
    width: 1800px;
}

.cont_partecipanti_corso .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_partecipanti_corso .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_partecipanti_corso .selectstatusall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/emt/img/Filtro.png);
    text-indent: -119px;
    background-size: 24px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -5px !important;

}


.cont_partecipanti_corso .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: black !important;
}

.cont_partecipanti_corso .cl_checkall {
    margin-left: -14px
}


.cont_partecipanti_corso .cl_checkall {
    padding-left: 15px;
    padding-top: 2px;
}

.cont_partecipanti_corso .checkall {
    width: 13px;
    height: 13px;
}


.cont_partecipanti_corso .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_partecipanti_corso {
    overflow-x: auto !important;
}

.cont_partecipanti_corso .selpartecipanti {
    max-width: 28px;
    min-height: 28px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 28px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 1px;

}

.cont_partecipanti_corso .selazienda {
    max-width: 30px;
    min-height: 30px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 30px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_partecipanti_corso .img_centered {
    width: 100% !important;
    text-align: center !important;
    min-width: 36px;
}

.cont_partecipanti_corso .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_partecipanti_corso .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}

.cont_partecipanti_corso .col_footer_center {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: center;
    padding-right: 6px;
}

.cont_partecipanti_corso .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cont_partecipanti_corso .text_right2 {
    width: 100% !important;
    text-align: right !important;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemAzienda {
    margin-left: 15px;
}



.cont_partecipanti_corso .clDivisor {
    margin-left: 10px;
    border: 1px solid black;
    margin-right: 10px;
}

.header-mobile {
    display: none;
}


@media screen and (max-width: 960px) {

    .cont_partecipanti_corso .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_partecipanti_corso #myTable {
        /* margin-top:54px; */
    }

    .cont_partecipanti_corso #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .info-corso {
        display: none;
    }

    .info-corso-mobile {
        display: table;
        margin-left: 2px;
    }



}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_partecipanti_corso #myTable {
        max-width: 99vw !important;
        margin-right: 0px;
        min-width: 99vw !important;
        position: relative;
        width: 99vw !important;
        margin-top: 67px !important;

    }

    .cont_partecipanti_corso #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_partecipanti_corso .table-responsive {
        padding: 0px;
        min-width: 99vw !important;
        max-width: 99vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_partecipanti_corso #myTable_wrapper {
        min-width: initial;
        min-width: 99vw !important;
        max-width: 99vw !important;
        width: 99vw !important;
        overflow-x: hidden !important;
    }

    .cont_partecipanti_corso .cl_num {
        margin-left: 16px !important;
    }

    .cl_n_matricola {
        display: inline-flex;
    }

    .cl_desc_project {
        display: inline-flex;
    }

    .cl_costo_giornaliero {
        display: inline-flex;
    }

    .cl_diaria {
        display: inline-flex;
    }

    .cl_ticket {
        display: inline-flex;
    }

    .cl_piano_orario {
        display: inline-flex;
    }

    .cl_ebadge {
        display: inline-flex;
    }

    .cl_totale_ferie {
        display: inline-flex;
    }

    .cont_partecipanti_corso .cl_checkall {
        margin-left: 4px
    }

    .cont_partecipanti_corso {
        overflow-x: hidden;
    }

    .header-no-mobile {
        display: none;
    }

    .header-mobile {
        display: block;
    }


}
</style>
