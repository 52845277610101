<template>
    <v-container class="w-container" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                                                                                    background-color: white;
                                                                                    color: black;
                                                                                    font-weight: bold;
                                                                                    padding: 4px;
                                                                                    font-size: 10px;
                                                                                    min-width:100vw;
                                                                                    border-color: #0084f3">{{ titolo }}
                </div>

            </v-col>

        </v-row>


        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">


                    <div id="cont_id_risposta" style="cursor:pointer">
                        <ion-item id="itemRisposta"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Risposta </ion-label>

                            <ion-select id="id_risposta" name="id_risposta" class='id_risposta' cancel-Text="Chiudi"
                                done-Text="" :value="peso_risposta.id_risposta" interface="action-sheet" disabled
                                readonly>

                                <ion-select-option v-for="item in risposte" :key="item.id" :value='item.id'> {{
                                    item.descrizione }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <label style="font-size:12px;margin-left:8px;">Peso</label>

                    <div id="d_ore_popup" style="cursor:pointer"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;"></label>

                                    <!-- <vue-autonumeric v-model="peso_risposta.peso" :options="'integer'" ref="fldOreModulo"
                                        id="fldOreModulo"></vue-autonumeric> -->

                                    <vue-autonumeric v-model="peso_risposta.peso" :options="{
                                        digitGroupSeparator: '',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '',
                                        currencySymbol: '',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0',
                                        decimalPlaces: '0',
                                        modifyValueOnWheel: false,
                                    }"></vue-autonumeric>



                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>



                </v-col>

            </v-row>





        </v-form>


        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetRisposte" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Risposte
                            <v-btn class='closeSheet' icon @click="sheetRisposte = !sheetRisposte">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchRisposte"></v-text-field>

                            </v-toolbar>

                        </div>


                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredRisposte" :key="item.companyid"
                                    @click="manageClickRisposte(item)">

                                    <v-img alt="" src="@/assets/corsi_online_smartform.png" max-width="24"
                                        max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.descrizione }}</v-list-item-title>
                                </v-list-item>

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>





    </v-container>
</template>

<script>

import apicorsi from "../utils/corsi/apicorsi";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import Vue from "vue";
import VModal from 'vue-js-modal';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        this.$root.$children[0].checkLogout();

        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;


        this.setupButtons();

        if (this.$route.params.id == 0) {

            this.titolo = "Aggiungi Peso Risposta";

            this.initInsert();

        }



        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Peso Risposta";

            setTimeout(() => {
                this.syncPesoRisposta();
            }, 100);

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/pesiRisposteCorsiOnline"
                    });
                    break;

                default:
                    break;
            }
        });



        $(document).ready(function () {


            $('body').on('click', '#cont_id_risposta', function (event) {
                console.log(event);

                pointerVue.sheetRisposte = true;

            });

        });





    },

    computed: {

        filteredRisposte() {

            return _.orderBy(this.risposte.filter(item => {
                if (!this.searchRisposte) return this.risposte;
                return (item.descrizione.toLowerCase().includes(this.searchRisposte.toLowerCase()));
            }), 'headline')
        },



    },



    components: {
        VueAutonumeric,
    },


    data: () => ({

        sheetRisposte: false,
        searchRisposte: "",


        risposte: [],

        tipologie: [],

        fasi: [],

        showPicker2: false,

        oreModulo: null,
        posizioneModulo: null,

        currentFile: null,
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",
        titolo: "Aggiungi Risposta",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        modulo: {},

        step_flusso: { id_corso: "", id_step_flusso: "", ordinamento: "" },

        corso: { codice: "", nome: "", data_inizio: "", data_fine: "", descrizione: "", luogo: "" },


        risposta: { id_domanda: "", descrizione: "", corretta: "N" },

        peso_risposta: { id_risposta: "", peso: "" },


        luoghi: [
            { id: "Ufficio", name: "Ufficio" },
            { id: "Smartworking", name: "Smartworking" }
        ],

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        codeRules: [
            (v) => !!v || "Codice richiesto",
        ],

        descRules: [
            (v) => !!v || "Descrizione richiesta",
        ],

        select: null,
        checkbox: false,
    }),

    methods: {

        manageClickRisposte: async function (risposta) {

            console.log("RISPOSTA SEL: ", risposta);

            this.sheetRisposte = false;

            this.peso_risposta.id_risposta = risposta.id;



        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/alertduedate/" + nome_metodo;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/salva_smartform.png",
                    title: "salva",
                    width: 30
                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/back_smartform.png",
                    title: "Indietro",
                    width: 30
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            var errore = "";


            console.log("PESO: ", that.peso_risposta);


            if (that.peso_risposta.id_risposta == "") {
                errore = "Specificare la risposta";
            }


            if (errore == "") {
                if (that.peso_risposta.peso == "") {
                    errore = "Specificare il peso";
                }
            }


            if (errore == "") {
                if ((that.peso_risposta.peso < 0) || (that.peso_risposta.peso > 10)) {
                    errore = "Il valore del peso deve essre da 1 a 10";
                }
            }


            if (errore == "") {

                console.log("ok");


                that.saveData();


            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }



        },

        saveData: async function () {


            var that = this;

            var id_rec = that.$route.params.id;


            if (id_rec == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apicorsi.insertPesoRisposta(
                    that.peso_risposta
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertPesoRisposta", res);


                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/pesiRisposteCorsiOnline"
                            });

                        }, 200);

                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });


                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }

            if (id_rec > 0) {

                console.log("ok");

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response2 = await apicorsi.updatePesoRisposta(
                    that.peso_risposta,
                    id_rec
                ).then((res) => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updatePesoRisposta", res);

                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/pesiRisposteCorsiOnline"
                            });

                        }, 200);

                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });


                    }

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }



        },



        initInsert: async function () {


            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.initInsertPesoRisposta(
                window.$cookies.get("sel_filter_domanda")
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsertPesoRisposta", res);

                that.risposte = res.data.Result;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncPesoRisposta: async function () {


            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.editPesoRisposta(
                window.$cookies.get("sel_filter_domanda"),
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editPesoRisposta", res);

                that.risposte = res.data.Risposte;

                that.peso_risposta = res.data.PesoRisposta;

                $("#itemRisposta").addClass("item-has-value");


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
