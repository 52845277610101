import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APITimesheet {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async calcOvtAbs(val_day, val_forzatura, hour_ordinario, cur_year, cur_month, user_sel)
  {

    let formData = new FormData();

    formData.append("v_forzatura",  val_forzatura);
    formData.append("v_day",  val_day);
    formData.append("v_user_sel",  user_sel);
    formData.append("v_h_ord",  hour_ordinario);
    formData.append("v_cur_year",  cur_year);
    formData.append("v_cur_month",  cur_month);
   
    return axios.post(this.getUrlDomain("EmtCalcOvertimeAbsenceApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async saveAndOpen(cur_year, cur_month, user_sel, timesheet)
  {

    let formData = new FormData();

    formData.append("v_user_sel",  user_sel);
    formData.append("v_cur_year",  cur_year);
    formData.append("v_cur_month",  cur_month);
    formData.append("v_timesheet",  timesheet);
   
    return axios.post(this.getUrlDomain("EmtSaveDataAndOpenApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async saveAndClose(cur_year, cur_month, user_sel, timesheet)
  {

    let formData = new FormData();

    formData.append("v_user_sel",  user_sel);
    formData.append("v_cur_year",  cur_year);
    formData.append("v_cur_month",  cur_month);
    formData.append("v_timesheet",  timesheet);
   
    return axios.post(this.getUrlDomain("EmtSaveDataAndCloseApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async saveData(cur_year, cur_month, user_sel, timesheet)
  {

    let formData = new FormData();

    formData.append("v_user_sel",  user_sel);
    formData.append("v_cur_year",  cur_year);
    formData.append("v_cur_month",  cur_month);
    formData.append("v_timesheet",  timesheet);
   
    return axios.post(this.getUrlDomain("EmtSaveDataApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async pdfAmministrativo(cur_year, cur_month, login_res_sel, user)
  {

    let formData = new FormData();

    formData.append("v_year",  cur_year);
    formData.append("v_month",  cur_month);
    formData.append("v_login_res",  login_res_sel);
    formData.append("v_user",  user);
   
    return axios.post(this.getUrlDomain("tsGetPdfAmmApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async pdfCompleto(cur_year, cur_month, login_res_sel, user)
  {

    let formData = new FormData();

    formData.append("v_year",  cur_year);
    formData.append("v_month",  cur_month);
    formData.append("v_login_res",  login_res_sel);
    formData.append("v_user",  user);
   
    return axios.post(this.getUrlDomain("tsGetPdfFullApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getDipendenti(azienda, user, year, month)
  {

    let formData = new FormData();

    formData.append("v_azienda",  azienda);
    formData.append("v_user",  user);
    formData.append("v_year",  year);
    formData.append("v_month",  month);
   
   
    return axios.post(this.getUrlDomain("tsGetDipendenti") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getDipendentiByUser(user, year, month)
  {

    let formData = new FormData();

    formData.append("v_user",  user);
    formData.append("v_year",  year);
    formData.append("v_month",  month);
   
   
    return axios.post(this.getUrlDomain("tsGetDipendentiByUser") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getAziendaByUser(user)
  {

    let formData = new FormData();

    formData.append("v_user",  user);
   
   
    return axios.post(this.getUrlDomain("tsGetAziendaByUser") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




}

export default new APITimesheet({
  url: "https://services.ebadge.it/public/api/"
})
