<template>
    <v-container class="w-container v-overflow editParente"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);overflow-x:hidden">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                        background-color: white;
                        color: black;
                        font-weight: bold;
                        padding: 4px;
                        font-size: 10px;
                        min-width:100vw;
                        border-color: #0084f3">{{ titolo }}</div>

            </v-col>


        </v-row>

        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Corso </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi" done-Text=""
                                :value="selCorso" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in corsi" :key="item.id" :value='item.id'> {{
                                    item.full_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>


                </v-col>

            </v-row>

        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetCorsi" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetCorsi" fixed style="position:fixed;">
                            Corsi
                            <v-btn class='closeSheet' icon @click="sheetCorsi = !sheetCorsi">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="search"></v-text-field>

                            </v-toolbar>

                        </div>


                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredCorsi" :key="item.id"
                                    @click="manageClickCorsi(item)">

                                    <v-img alt="" src="@/assets/corsi_smartform.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.full_name }}</v-list-item-title>
                                </v-list-item>

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

</v-container>
</template>

<script>

import apicalendario from "../utils/calendario/apicalendario";

import {
    bus
} from "../main";

import router from ".././router";
import $ from 'jquery';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        var pointerVue = this;

        this.setupButtons();

        setTimeout(() => {

            this.syncCorsi();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/medici"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetCorsi = true;

            });

        });

        pointerVue.hideShowButtons(false);

    },

    computed: {

        filteredCorsi() {

            return _.orderBy(this.corsi.filter(item => {
                if (!this.search) return this.corsi;
                return (item.full_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        corsi: [],

        titolo: "Calendario",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selCorso: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetCorsi: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        // nucleiRules

        select: null,

        //gruppi: window.$cookies.get("gen_settings").gruppi,

        checkbox: false,
    }),

    methods: {

        manageClickCorsi: async function (corso) {

            console.log("CORSO SEL: ", corso);

            this.sheetCorsi = false;

            this.selCorso = corso.id;

            this.hideShowButtons(true);

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {

                    pulsantis = [{
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: "https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png",
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        // class: "inverted",
                        title: "Conferma",
                        width: 30
                    },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/conferma_smartform.png",
                    link: "/timesheet",
                    id: "btn_confirm",
                    disabled: false,
                    //class: "inverted",
                    title: "Conferma",
                    width: 30
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            console.log("AZIENDA: ", that.selCorso);

            window.$cookies.set("sel_filter_corsi", that.selCorso, "9y");


            // var desc_azi_sel = "";

            // for (var xx = 0; xx <= that.aziende.length - 1; xx++) {

             
            //     if (parseInt(that.aziende[xx].companyid) == parseInt(that.selAzienda)) {


                  

            //         desc_azi_sel = that.aziende[xx].fld_name;
            //     }


            // }


            // console.log("AZI: ", desc_azi_sel);


            // window.$cookies.set("sel_azi_name", desc_azi_sel, "9y");




            // router.push({
            //     path: "/corsi"
            // });

        },



        syncCorsi: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicalendario.getCorsi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getCorsi", res);


                this.corsi = res.data.corsi;

                // try {

                //     this.aziende = res.data.company;

                // } catch (error) {
                //     console.log(error);
                // }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetCorsi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editParente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editParente .v-input {
    font-size: 1.2em;
}

.editParente .v-select {
    font-size: 1.2em;
}

.editParente .v-label {
    font-size: 1em;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
