<template>
    <v-container class="w-container v-overflow editParente"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);overflow-x:hidden">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle"
                    style="
                                                                                                                background-color: white;
                                                                                                                color: black;
                                                                                                                font-weight: bold;
                                                                                                                padding: 4px;
                                                                                                                font-size: 10px;
                                                                                                                min-width:100vw;
                                                                                                                border-color: #0084f3">
                    {{
                        titolo }}
                </div>

            </v-col>


        </v-row>

        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Corso </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi" done-Text=""
                                :value="selCorso" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in corsi" :key="item.id" :value='item.id'> {{
                                    item.full_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>


                </v-col>

            </v-row>

            <v-row v-if="showRow">

                <v-col cols="12" md="12">

                    <v-text-field v-model="codiceCorso" label="Codice Corso" readonly>
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row v-if="showRow">

                <v-col cols="12" md="12">

                    <v-text-field v-model="nomeCorso" label="Nome Corso" readonly>
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row v-if="showRow">

                <v-col cols="12" md="12">

                    <v-text-field v-model="dataInizioCorso" label="Data Inizio Corso" readonly>
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row v-if="showRow">

                <v-col cols="12" md="12">

                    <v-text-field v-model="dataFineCorso" label="Data Fine Corso" readonly>
                    </v-text-field>

                </v-col>

            </v-row>


            <v-row v-if="showRow">

                <v-col cols="12" md="12" style="text-align:center!important;">

                    <v-date-picker v-model="selDays" multiple no-title locale="it"></v-date-picker>


                </v-col>

            </v-row>










        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetCorsi" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetCorsi" fixed style="position:fixed;">
                            Corsi
                            <v-btn class='closeSheet' icon @click="sheetCorsi = !sheetCorsi">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="search"></v-text-field>

                            </v-toolbar>

                        </div>


                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredCorsi" :key="item.id"
                                    @click="manageClickCorsi(item)">

                                    <v-img alt="" src="@/assets/corsi_smartform.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.full_name }}</v-list-item-title>
                                </v-list-item>

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </v-container>
</template>

<script>

import apicalendario from "../utils/calendario/apicalendario";

import {
    bus
} from "../main";

import router from ".././router";
import $ from 'jquery';

export default ({
    mounted() {

        /*eslint-disable no-undef*/
        
        this.$root.$children[0].checkLogout();

        this.isReadOnly = this.$root.$children[0].getValueReadOnly("calendario_smartform_ro");

        console.log("IS RO: ", this.isReadOnly);


        var pointerVue = this;


        setTimeout(() => {

            this.syncCorsi();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/medici"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetCorsi = true;

            });

        });

        //  pointerVue.hideShowButtons(false);

        this.setupButtons();


    },

    computed: {

        filteredCorsi() {

            return _.orderBy(this.corsi.filter(item => {
                if (!this.search) return this.corsi;
                return (item.full_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        isReadOnly: 0,

        selDays: [],

        showRow: false,

        corsi: [],

        titolo: "Calendario",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selCorso: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetCorsi: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        // nucleiRules

        select: null,

        //gruppi: window.$cookies.get("gen_settings").gruppi,

        checkbox: false,
    }),

    methods: {


        saveData: async function () {

            var that = this;

            console.log("ID CORSO: ", that.selCorso);
            console.log("GIORNI SEL: ", that.selDays);

            var str_days = "";

            for (var xday = 0; xday < that.selDays.length; xday++) {

                var elements = that.selDays[xday].split("-");

                var v_date = elements[2] + "-" + elements[1] + "-" + elements[0];

                if (str_days != "") {
                    str_days = str_days + ",";
                }

                str_days = str_days + v_date;

            }

            console.log("STR GIORNI SEL: ", str_days);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicalendario.saveDays(
                that.selCorso,
                str_days
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveDays", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 4000
                    });


                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );





        },


        manageClickCorsi: async function (corso) {

            console.log("CORSO SEL: ", corso);

            this.sheetCorsi = false;

            this.selCorso = corso.id;

            this.getInfoDaysCorso(corso.id);

        },

        getInfoDaysCorso: async function (id_corso) {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicalendario.getInfoDays(
                id_corso
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getInfoDays", res);

                that.codiceCorso = res.data.corso.codice;
                that.nomeCorso = res.data.corso.nome;
                that.dataInizioCorso = res.data.corso.data_inizio;
                that.dataFineCorso = res.data.corso.data_fine;






                that.selDays = [];


                if (res.data.days_selected != "") {

                    var days_selected = res.data.days_selected.split(",");

                    console.log("DAYS SELECTED", days_selected);

                    for (var xday = 0; xday < days_selected.length; xday++) {

                        console.log("DAY: ", xday);

                        var day_part = days_selected[xday].split("-");

                        var v_date = day_part[2] + "-" + day_part[1] + "-" + day_part[0];

                        that.selDays.push(v_date);

                    }


                }

                that.showRow = true;






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {

                    pulsantis = [{
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: "https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png",
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        // class: "inverted",
                        title: "Conferma",
                        width: 30
                    },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/salva_smartform.png",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    //class: "inverted",
                    title: "Salva",
                    width: 30
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {



                    if (this.isReadOnly == 1) {


                        $('#btn_save').prop('disabled', true)
                        $('#btn_save img').css("opacity", "0.5");

                    }



                }, 200);


            }, 100);

        },

        validate() {

            var that = this;

            console.log("ID CORSO: ", that.selCorso);
            console.log("SEL GIORNI: ", that.selDays);

            var errore = "";

            if (that.selCorso == "") {

                errore = "Selezionare il corso";

            }

            if (errore == "") {
                if (that.selCorso == null) {

                    errore = "Selezionare il corso";

                }

            }


            if (errore == "") {

                console.log("ok");

                that.saveData();


            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },



        syncCorsi: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicalendario.getCorsi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getCorsi", res);


                this.corsi = res.data.corsi;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.calendar {
    display: flex;
    justify-content: center;
    min-width: 100%;
}


.itemAzienda {
    margin-left: 15px;
}

.headerSheetCorsi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editParente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editParente .v-input {
    font-size: 1.2em;
}

.editParente .v-select {
    font-size: 1.2em;
}

.editParente .v-label {
    font-size: 1em;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
