<template>
    <v-container class="w-container v-overflow" style="padding-top:59px">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle"
                    style="
                                                                                                    background-color: white;
                                                                                                    color: black;
                                                                                                    font-weight: bold;
                                                                                                    padding: 4px;
                                                                                                    font-size: 10px;
                                                                                                    min-width:100vw;
                                                                                                    border-color: #0084f3">
                    {{
                        titolo }}
                </div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field v-model="nomeAziendaPartecipante" label="Azienda" id="nome_azienda"
                        readonly></v-text-field>
                </v-col>
            </v-row>


            <v-row v-if="viewRisorsa">
                <v-col cols="12" md="12">
                    <v-text-field v-model="nomeRisorsaPartecipante" label="Risorsa" id="nome_risorsa"
                        readonly></v-text-field>
                </v-col>
            </v-row>



            <v-row v-if="viewRisorsa" style="visibility: hidden;height: 0px;">

                <v-col cols="12" md="12">

                    <v-text-field v-model="partecipante.email_risorsa" label="E-Mail" id="email_risorsa"
                        readonly></v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_insegnate" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Insegnante </ion-label>

                            <ion-select id="insegnante" name="insegnante" class='insegnante' cancel-Text="Chiudi"
                                done-Text="" :value="partecipante.insegnante" interface="action-sheet">

                                <ion-select-option v-for="item in valuesSiNo" :key="item.id" :value='item.id'> {{
                                    item.nome
                                }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_esterno" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Esterno </ion-label>

                            <ion-select id="esterno" name="esterno" class='esterno' cancel-Text="Chiudi" done-Text=""
                                :value="partecipante.esterno" interface="action-sheet">

                                <ion-select-option v-for="item in valuesSiNo" :key="item.id" :value='item.id'> {{
                                    item.nome
                                }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>



            <v-row v-if="viewOreEff" style="visibility: hidden;height: 0px;">

                <v-col cols="12" md="12">

                    <div id="d_ore_effettive"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Ore Effettive</label>

                                    <vue-autonumeric v-model="partecipante.ore_effettive" :disabled="true" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0'
                                    }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>







            <v-row style="visibility: hidden;height: 0px;">


                <v-col cols="12" md="12">

                    <div id="d_percentuale"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Percentuale
                                        Riconosciuta</label>

                                    <vue-autonumeric v-model="partecipante.perc_riconosciuta" :disabled="true"
                                        :options="'integer'"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>


            <!-- <v-row v-if="viewCostoGiorn">

                <v-col cols="12" md="12">

                    <div id="d_costo_giorn"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Costo Giornaliero</label>

                                    <vue-autonumeric v-model="partecipante.costo_giornaliero" :disabled="true" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '\u00a0€',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0'
                                    }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row> -->


            <v-row v-if="viewCostoOra">

                <v-col cols="12" md="12">

                    <div id="d_costo_ora"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Costo All'Ora</label>

                                    <vue-autonumeric v-model="partecipante.costo_ora" :disabled="true" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '\u00a0€',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0'
                                    }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>



        </v-form>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende_smartform.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>


        <div class="contrisorse">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetRisorse" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetRisorse" fixed style="position:fixed;">
                                Risorse
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetRisorse = !sheetRisorse">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchRisorse">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredRisorse" :key="item.resourceid"
                                        @click="manageClickRisorse(item)">

                                        <v-img alt="" src="@/assets/profilo_smartform.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemRisorsa'>{{ item.full_name }}</v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contpopuppercentuale">

            <template>
                <modal name="popupPercentuale" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Percentuale
                                Riconosciuta</label>

                            <div id="d_percentuale_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePercentuale" :options="'integer'"
                                                ref="fldPercentuale" id="fldPercentuale"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-percentuale" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelPercentuale" />

                                <v-img alt="" class="btn-confirm-percentuale" contain
                                    src="@/assets/btn_confirm_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" @click="btnConfirmPercentuale" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopuporeeff">

            <template>
                <modal name="popupOreEff" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Ore Effettive</label>

                            <div id="d_ore_eff_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreOreEffettive" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldDaPagare" id="fldDaPagare"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-ore-eff" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelOreEff" />

                                <v-img alt="" class="btn-confirm-ore-eff" contain src="@/assets/btn_confirm_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmOreEff" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupcostogiorn">

            <template>
                <modal name="popupCostoGiorn" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Costo Giornaliero</label>

                            <div id="d_costo_giorn_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreCostoGiorn" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldDaPagare" id="fldDaPagare"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-costo" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelCosto" />

                                <v-img alt="" class="btn-confirm-costo" contain src="@/assets/btn_confirm_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmCosto" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupcostoora">

            <template>
                <modal name="popupCostoOra" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Costo All'Ora</label>

                            <div id="d_costo_ora_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreCostoOra" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldDaPagare" id="fldDaPagare"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-costo" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelCostoOra" />

                                <v-img alt="" class="btn-confirm-costo" contain src="@/assets/btn_confirm_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmCostoOra" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>








    </v-container>
</template>

<script>
//import apipagamento from "../utils/pagamento/apipagamento";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import apipartecipanti from "../utils/partecipanti/apipartecipanti";


import {
    bus
} from "../main";

import router from ".././router";
// import Vue from "vue";
// import VModal from 'vue-js-modal';

export default ({
    mounted() {

        /*eslint-disable no-undef*/



       

        var pointerVue = this;


        this.setupButtons();

      


        if (this.$route.params.id == 0) {

            setTimeout(() => {

                this.initInsert();

            }, 100);

        }


        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Paretcipante";

            setTimeout(() => {

                this.initEdit();

            }, 100);


        }



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/partecipanti"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {


            $('body').on('click', '#d_percentuale', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPercentuale');

            });


            $('body').on('click', '#d_ore_effettive', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupOreEff');

            });


            $('body').on('click', '#d_costo_giorn', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupCostoGiorn');

            });

            $('body').on('click', '#d_costo_ora', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupCostoOra');

            });


            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '#cont_resourceid', function (event) {
                console.log(event);

                pointerVue.sheetRisorse = true;

            });



            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });



            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');

                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });



        });

    },

    components: {
        VueAutonumeric,
    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredRisorse() {

            return _.orderBy(this.risorse.filter(item => {
                if (!this.searchRisorse) return this.risorse;
                return (item.full_name.toLowerCase().includes(this.searchRisorse.toLowerCase()));
            }), 'headline')
        },



        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({


        nomeAziendaPartecipante: "",

        nomeRisorsaPartecipante: "",

        valoreCostoGiorn: null,

        valoreCostoOra: null,

        viewCostoGiorn: false,

        viewCostoOra: false,


        viewOreEff: false,

        valorePercentuale: null,
        valoreOreEffettive: null,

        sheetRisorse: false,
        searchRisorse: "",

        viewRisorsa: false,

        risorse: [],
        risorseTemp: [],



        attachPresent: false,

        viewRowAzienda: true,

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Aggiungi Partecipante",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        // tipologie: [],

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        valuesSiNo: [{
            "id": "N",
            "nome": "No"
        }, {
            "id": "S",
            "nome": "Si"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: "",
            nome: "",
            companyid: "",
            data_scadenza: "",
            id_tipologia: "",
            importo: "",
            descrizione: ""
        },

        partecipante: { companyid: "", resourceid: "", nome_risorsa: "", cognome_risorsa: "", contratto_risorsa: "", email_risorsa: "", insegnante: "N", esterno: "N", ore_effettive: "", costo_giornaliero: "", costo_ora: "" },


        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],



    }),

    methods: {


        btnCancelCostoOra: function () {

            this.$modal.hide('popupCostoOra');

        },

        btnConfirmCostoOra: function () {

            this.partecipante.costo_ora = this.valoreCostoOra;

            this.$modal.hide('popupCostoOra');

        },









        btnCancelCosto: function () {

            this.$modal.hide('popupCostoGiorn');

        },

        btnConfirmCosto: function () {

            this.partecipante.costo_giornaliero = this.valoreCostoGiorn;

            this.$modal.hide('popupCostoGiorn');

        },





        btnCancelOreEff: function () {

            this.$modal.hide('popupOreEff');

        },

        btnConfirmOreEff: function () {

            this.partecipante.ore_effettive = this.valoreOreEffettive;

            this.$modal.hide('popupOreEff');

        },


        btnCancelPercentuale: function () {

            this.$modal.hide('popupPercentuale');

        },

        btnConfirmPercentuale: function () {

            this.partecipante.perc_riconosciuta = this.valorePercentuale;

            this.$modal.hide('popupPercentuale');

        },

        getResourceDetails: async function (id_risorsa) {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipartecipanti.getResourceDetails(
                v_token,
                id_risorsa
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getResourceDetails", res);

                that.partecipante.nome_risorsa = res.data.ValRes.name;
                that.partecipante.cognome_risorsa = res.data.ValRes.surname;
                that.partecipante.contratto_risorsa = res.data.DescContr;
                that.partecipante.email_risorsa = res.data.EMail;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },




        setRisorse: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("RIS TMP: ", this.risorseTemp);

            var risorseAzienda = [];

            for (var i = 0; i < this.risorseTemp.length; i++) {

                if (parseInt(this.risorseTemp[i].companyid) == parseInt(id_azienda)) {

                    risorseAzienda.push({
                        resourceid: this.risorseTemp[i].resourceid,
                        full_name: this.risorseTemp[i].full_name,
                        companyid: this.risorseTemp[i].companyid
                    });

                }

            }

            console.log("RIS SEL: ", risorseAzienda);

            this.risorse = risorseAzienda;

        },


        formatDecimal(e) {


            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);


            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.partecipante.companyid = azienda.companyid;

            this.setRisorse(azienda.companyid);

            this.viewRisorsa = true;

        },


        manageClickRisorse: async function (risorsa) {

            console.log("RES SEL: ", risorsa);

            this.sheetRisorse = false;

            this.partecipante.resourceid = risorsa.resourceid;

            //  this.partecipante.resourceid = 16188

            this.getResourceDetails(risorsa.resourceid);

        },



        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/salva_smartform.png",
                    title: "salva",
                    width: 30
                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/back_smartform.png",
                    title: "Indietro",
                    width: 30
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            var errore = "";

            console.log("PARTECIPANTE: ", that.partecipante);

            if (that.partecipante.companyid == "") {
                errore = "Specificare l'azienda";
            }

            if (errore == "") {
                if (that.partecipante.companyid == null) {
                    errore = "Specificare l'azienda";
                }
            }

            if (errore == "") {
                if (that.partecipante.resourceid == "") {
                    errore = "Specificare la risorsa";
                }
            }

            if (errore == "") {
                if (that.partecipante.resourceid == null) {
                    errore = "Specificare la risorsa";
                }
            }


            if (this.$route.params.id > 0) {

               
                if (errore == "") {
                    if (that.partecipante.costo_giornaliero == "") {
                        errore = "Specificare il costo giornaliero";
                    }
                }


                if (errore == "") {
                    if (that.partecipante.costo_giornaliero == null) {
                        errore = "Specificare il costo giornaliero";
                    }
                }

                if (errore == "") {
                    if (that.partecipante.costo_giornaliero == 0) {
                        errore = "Specificare il costo giornaliero";
                    }
                }



                if (errore == "") {
                    if (that.partecipante.costo_ora == "") {
                        errore = "Specificare il costo all'ora";
                    }
                }


                if (errore == "") {
                    if (that.partecipante.costo_ora == null) {
                        errore = "Specificare il costo all'ora";
                    }
                }

                if (errore == "") {
                    if (that.partecipante.costo_ora == 0) {
                        errore = "Specificare il costo all'ora";
                    }
                }




            }


            if (errore == "") {


                console.log("ok");

                that.saveData();


            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }


        },

        validateOld() {

            var that = this;

            var v_choose = window.$cookies.get("sel_tipo_soggetto_pagamento");


            var errore = "";

            console.log("PAGAMENTO: ", that.pagamento);


            if (errore == "") {

                if (that.pagamento.descrizione == "") {
                    errore = "Nome / Descrizione valore richiesto";
                }

            }



            if (errore == "") {

                if (v_choose == "A") {

                    if (that.pagamento.companyid == "") {
                        errore = "Azienda valore richiesto";
                    }

                }

            }

            if (errore == "") {

                if (that.pagamento.data_scadenza == "") {
                    errore = "Data scadenza valore richiesto";
                }

            }

            if (errore == "") {

                if (that.pagamento.id_tipologia == "") {
                    errore = "Tipologia valore richiesto";
                }

            }

            if (errore == "") {

                if (that.pagamento.importo == "") {
                    errore = "Da pagare valore richiesto";
                }

            }

            if (errore == "") {

                if (that.pagamento.importo_pagato != null) {

                    if (that.pagamento.importo_pagato != 0) {

                        if (that.pagamento.data_pagamento == null) {

                            errore = "Specificare la data di pagamento";

                        }

                    }

                }

            }

            if (that.importoRimanente == 0) {

                if (errore == "") {

                    if (that.pagamento.importo_pagato != null) {

                        if (parseFloat(that.pagamento.importo_pagato) > parseFloat(that.pagamento.importo)) {

                            errore = "Il valore del pagato non pùo essere superiore al valore da pagare";

                        }

                    }

                }

            } else {

                if (errore == "") {

                    if (that.pagamento.importo_pagato != null) {

                        if (parseFloat(that.pagamento.importo_pagato) > parseFloat(that.importoRimanente)) {

                            var v_rim = parseFloat(that.importoRimanente);

                            errore = "Il valore del pagato non pùo essere superiore al valore rimanente da pagare di € " + v_rim.toFixed(2);

                        }

                    }

                }

            }

            if (that.disField == true) {

                if (that.pagamento.data_pagamento == null) {

                    errore = "Specificare la data di pagamento";

                }

            }

            if (errore == "") {

                if (that.disField == true) {

                    if (that.pagamento.importo_pagato == null) {

                        errore = "Specificare l'importo pagato";

                    } else {
                        if (that.pagamento.importo_pagato == 0) {

                            errore = "Specificare l'importo pagato";

                        }
                    }

                }

            }

            console.log("ALL: ", $('#input-file-logo')[0].files.length);


            if (errore == "") {

                if ($('#input-file-logo')[0].files.length == 0) {

                    if (that.attachPresent == false) {

                        errore = "Allegato richiesto";

                    }


                }

            }

            if (errore == "") {

                if ($('#input-file-logo')[0].files.length > 0) {

                    var v_file = $('#input-file-logo')[0].files[0];

                    var elements = v_file.name.split('.');
                    var extension = elements[elements.length - 1];

                    if (extension.toString().toUpperCase() != "PNG") {
                        if (extension.toString().toUpperCase() != "JPG") {

                            if (extension.toString().toUpperCase() != "PDF") {

                                if (extension.toString().toUpperCase() != "JPEG") {

                                    errore = "Formati dell'immagine supportati PNG, JPG, JPEG, PDF";

                                }

                            }

                        }
                    }

                }

            }






            if (errore == "") {


                console.log("ok");

                that.saveData();


            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }





        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },


        initInsert: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipartecipanti.initInsert(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);

                that.aziende = res.data.aziende;

                that.risorseTemp = res.data.resources;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        initEdit: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipartecipanti.initEdit(
                v_token,
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initEdit", res);

                that.aziende = res.data.aziende;

                that.risorseTemp = res.data.resources;

                that.setRisorse(res.data.partecipante.companyid);

                that.viewRisorsa = true;

                that.partecipante.companyid = res.data.partecipante.companyid;

                that.partecipante.resourceid = res.data.partecipante.resourceid;

                that.viewOreEff = true;

                that.viewCostoGiorn = true;

                that.viewCostoOra = true;

                that.partecipante.insegnante = res.data.partecipante.insegnante;

                that.partecipante.esterno = res.data.partecipante.esterno;

                that.partecipante.perc_riconosciuta = res.data.partecipante.perc_riconosciuta;

                that.partecipante.ore_effettive = res.data.partecipante.ore_effettive;

                that.partecipante.costo_giornaliero = res.data.partecipante.costo_giornaliero;

                that.partecipante.costo_ora = res.data.partecipante.costo_ora;

                $("#itemAzienda").addClass("item-has-value");
                $("#itemRisorsa").addClass("item-has-value");

                setTimeout(() => {

                    that.getResourceDetails(res.data.partecipante.resourceid);

                }, 200);







                var nomeAzienda = "";

                for (var xx = 0; xx < that.aziende.length; xx++) {

                    if (parseInt(that.aziende[xx].companyid) == parseInt(res.data.partecipante.companyid)) {

                        nomeAzienda = that.aziende[xx].fld_name;

                    }

                }

                that.nomeAziendaPartecipante = nomeAzienda;



                var nomeRisorsa = "";

                for (var xx2 = 0; xx2 < that.risorseTemp.length; xx2++) {

                    if (parseInt(that.risorseTemp[xx2].resourceid) == parseInt(res.data.partecipante.resourceid)) {

                        nomeRisorsa = that.risorseTemp[xx2].full_name;

                    }

                }

                that.nomeRisorsaPartecipante = nomeRisorsa;




                // se il costo all'ora non è impostato di default propongo quello calcolato
                // if (res.data.partecipante.costo_ora == null)
                // {

                //     if (res.data.partecipante.costo_giornaliero != null)
                //     {


                //         var v_costo_ora = parseFloat(res.data.partecipante.costo_giornaliero) / 8;

                //         console.log("COSTO ORA CALC: ", v_costo_ora.toFixed(2));

                //         res.data.partecipante.costo_ora = v_costo_ora.toFixed(2);



                //     }




                // }













            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        saveData: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = null;

            if (this.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apipartecipanti.insertPartecipante(
                    that.partecipante,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertPartecipante", res);

                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/partecipanti"
                            });

                        }, 200);


                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.Error,
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );


            }

            if (this.$route.params.id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;



                if ($(".insegnante").val() != "") {
                    that.partecipante.insegnante = $(".insegnante").val();
                }

                if ($(".esterno").val() != "") {
                    that.partecipante.esterno = $(".esterno").val();
                }



                response = await apipartecipanti.updatePartecipante(
                    that.partecipante,
                    v_token,
                    this.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updatePartecipante", res);

                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/partecipanti"
                            });

                        }, 200);


                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.Error,
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );


            }



        },


        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
    z-index: 3;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetRisorse {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}



.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemRisorsa {
    margin-left: 15px;
}


.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}
</style>
