import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIRisorse {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initInsertRisorsa(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtInitAddRisorsaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async insertRisorsa(risorsa, user) {

    let formData = new FormData();

    formData.append("username", user);

    for (var key in risorsa) {
      formData.append(key, risorsa[key]);
    }

    return axios.post(this.getUrlDomain("EmtInsertRisorsaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initEditRisorsa(id, user)
  {

    return axios.get(this.getUrlDomain("EmtInitEditRisorsaApi") + "/" + id + "?api=1&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async updateRisorsa(risorsa, id, user)
  {

    let formData = new FormData();


    for ( var key in risorsa ) {
       formData.append(key, risorsa[key]);
    }

    return axios.post(this.getUrlDomain("EmtUpdateRisorsaApi") + "/" + id + "?username=" + user, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async deleteRisorsa(id)
  {


    return axios.get(this.getUrlDomain("EmtDeleteRisorsaApi") + "?id=" + id,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async pianoOrarioAzienda(id_azienda)
  {

    let formData = new FormData();

    formData.append("companyid", id_azienda);

    return axios.post(this.getUrlDomain("EmtPianoOrarioRisorsaApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async initPartecipanti(list_id, user)
  {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", list_id);

    return axios.post(this.getUrlDomain("SFInitPartecipantiApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }





}

export default new APIRisorse({
  url: "https://services.ebadge.it/public/api/"
})
