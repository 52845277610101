import axios from 'axios'

function getUrlDomainGeneral(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


}

var main_domain = getUrlDomainGeneral("");

class APIDocumenti {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    async insertDocumento(revisore, user, file) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("file", file);

        for (var key in revisore) {
            formData.append(key, revisore[key]);
        }

        return axios.post(this.getUrlDomain("SFDocumentiInsDocApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async editDocumento(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("SFDocumentiEditDocApi"), formData, { 'Content-Type': 'multipart/form-data' });


    }

    async updateDocumento(revisore, user, file, v_id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("file", file);
        formData.append("v_id", v_id);

        for (var key in revisore) {
            formData.append(key, revisore[key]);
        }

        return axios.post(this.getUrlDomain("SFDocumentiUpdDocApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteDocumenti(id) {

        let formData = new FormData();

        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("SFDocumentiDelDocApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getFilters(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("SFGetFiltersModuliApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getNomiByCodice(id_codice, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id_codice", id_codice);

        return axios.post(this.getUrlDomain("SFGetNomiByCodiceModuliApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getTotalHour(id_codice, nome, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id_codice", id_codice);
        formData.append("nome", nome);

        return axios.post(this.getUrlDomain("SFGetTotHoursModuliApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async reportPdf(id, id_azi) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("v_id_azi", id_azi);

        return axios.post(this.getUrlDomain("SFCorsiPdfReportApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async assocGiorniGetDate(id) {

        let formData = new FormData();

        formData.append("id", id);

        return axios.post(this.getUrlDomain("SFCorsiAssocGiorniGetDateApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async assocGiorniGetArgs(id_data, id_corso) {

        let formData = new FormData();

        formData.append("v_id_data", id_data);
        formData.append("v_id", id_corso);

        return axios.post(this.getUrlDomain("SFCorsiAssocGiorniGetArgsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async assocGiorniSave(v_id_data, v_codice_argomento, v_nome_argomento, v_ids, v_txt_data, v_tot_h_args, v_tot_min_args, v_id_corso) {

        let formData = new FormData();

        formData.append("v_id_data", v_id_data);
        formData.append("v_codice_argomento", v_codice_argomento);
        formData.append("v_nome_argomento", v_nome_argomento);
        formData.append("v_ids", v_ids);
        formData.append("v_txt_data", v_txt_data);
        formData.append("v_tot_h_args", v_tot_h_args);
        formData.append("v_tot_min_args", v_tot_min_args);

        formData.append("v_id_corso", v_id_corso);


        return axios.post(this.getUrlDomain("SFCorsiAssocGiorniSaveApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getDataCorsiSel(ids) {

        let formData = new FormData();

        formData.append("ids", ids);
    
        return axios.post(this.getUrlDomain("SFCorsiGetDataCorsoMasterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async insertCorsoCappello(corso, user, ids) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("ids", ids);

        for (var key in corso) {
            formData.append(key, corso[key]);
        }

        return axios.post(this.getUrlDomain("SFCorsiInsertCorsoMasterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async editCorsoCappello(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);


        return axios.post(this.getUrlDomain("SFCorsiEditCorsoMasterApi"), formData, { 'Content-Type': 'multipart/form-data' });


    }

    async updateCorsoCappello(corso, id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);

        for (var key in corso) {
            formData.append(key, corso[key]);
        }

        return axios.post(this.getUrlDomain("SFCorsiUpdateCorsoMasterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async getModuli(user, ids) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("ids", ids);

        return axios.post(this.getUrlDomain("SFCorsiGetModuliApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async setModulo(ids, idmodsel) {

        let formData = new FormData();

        formData.append("ids", ids);
        formData.append("idmodsel", idmodsel);

        return axios.post(this.getUrlDomain("SFCorsiAssocModuliApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async uploadAllegatoPartAssign(user, idcorso, idazienda, idpart, file) {

        let formData = new FormData();

        formData.append("user", user);
        formData.append("idcorso", idcorso);
        formData.append("idazienda", idazienda);
        formData.append("idpart", idpart);
        formData.append("file", file);

        return axios.post(this.getUrlDomain("SFCorsiUplAllagetaPartAssocApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async uploadAllegatoCorsoRelazione(user, idazienda, idcorso, file) {

        let formData = new FormData();

        formData.append("user", user);
        formData.append("idcorso", idcorso);
        formData.append("idazienda", idazienda);
        formData.append("file", file);

        return axios.post(this.getUrlDomain("SFCorsiUplRelCorsoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async uploadAllegatoCVPartAssign(user, idcorso, idazienda, idpart, file) {

        let formData = new FormData();

        formData.append("user", user);
        formData.append("idcorso", idcorso);
        formData.append("idazienda", idazienda);
        formData.append("idpart", idpart);
        formData.append("file", file);

        return axios.post(this.getUrlDomain("SFCorsiUplCVPartAssocApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async uploadAllegatoCorsoDocumento(user, idazienda, idcorso, file) {

        let formData = new FormData();

        formData.append("user", user);
        formData.append("idcorso", idcorso);
        formData.append("idazienda", idazienda);
        formData.append("file", file);

        return axios.post(this.getUrlDomain("SFCorsiUplDocCorsoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async setAziendeForUtente(idutente, ids) {

        let formData = new FormData();

        formData.append("idutente", idutente);
        formData.append("ids", ids);
       

        return axios.post(this.getUrlDomain("SFSetAziendeForUtentiApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteFileRelazione(id, idazienda) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("v_idazienda", idazienda);

        return axios.post(this.getUrlDomain("SFDelFileRelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteFileDocumento(id, idazienda) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("v_idazienda", idazienda);

        return axios.post(this.getUrlDomain("SFDelFileDocApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteZipLul(id) {

        let formData = new FormData();

        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("SFDelZipLulApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteFileCV(id) {

        let formData = new FormData();

        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("SFDelCVPartApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getInfoPartecipanti(id) {

        let formData = new FormData();

        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("SFGetInfoPartApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updateOre(idpart, oretotali, idcorso, anno, mese) {

        let formData = new FormData();

        formData.append("idpart", idpart);
        formData.append("oretotali", oretotali);
        formData.append("idcorso", idcorso);
        formData.append("anno", anno);
        formData.append("mese", mese);

        return axios.post(this.getUrlDomain("SFUpdOreLULApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


}

export default new APIDocumenti({
    url: main_domain
})
