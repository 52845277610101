<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>


        <div class="row cont_partecipanti">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Cognome</th>

                                        <th class='all'>Nome</th>
                                        <th class='desktop'>Tipo Contratto</th>
                                        <th class='all' style="text-align: center;">Insegnante</th>
                                        <th class='desktop' style="text-align: center;">Esterno</th>
                                        <!-- <th class='desktop' style="text-align: right;">Cost. D (€)</th> -->
                                        <th class='desktop' style="text-align: right;">Cost. H (€)</th>
                                        <th class='desktop' style="text-align: right;">Perc. Ric.</th>
                                        <th class='all' style="text-align: right;">Ore Prev.</th>
                                        <!-- <th class='all' style="text-align: right;">GG Prev.</th> -->

                                        <!-- <th class='desktop' style="text-align: right;">Cost. Tot. Pr. GG (€)</th> -->

                                        <th class='desktop' style="text-align: right;">Cost. Tot. Pr. HH (€)</th>

                                        <!-- <th class='all' style="text-align: right;">Tot. Cred. Pr. GG (€)</th> -->


                                        <th class='all' style="text-align: right;">Tot. Cred. Pr. HH (€)</th>

                                    </tr>
                                </thead>


                                <tfoot>

                                    <tr>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>
                                            <div class="div_n">&nbsp;</div>
                                        </th>

                                        <th class='all col_footer'>{{ strTotali }}</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <!-- <th class='desktop col_footer'>&nbsp;</th> -->

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='all col_footer_right' id="v_tot_ore">0,00</th>

                                        <!-- <th class='all col_footer_right' id="v_tot_gg_prev">0,00</th> -->

                                        <!-- <th class='desktop col_footer_right' id="v_tot_costo_prev">0,00</th> -->

                                        <th class='desktop col_footer_right' id="v_tot_costo_hh_prev">0,00</th>

                                        <!-- <th class='all col_footer_right' id="v_tot_perc_costo_prev">0,00</th> -->

                                        <th class='all col_footer_right' id="v_tot_perc_costo_hh_prev">0,00</th>

                                    </tr>
                                </tfoot>





                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/smartform_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende_smartform.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


        <div class="contpopupcorsiassoc" style="display:none">

            <template>
                <modal name="popupCorsiAssoc" :clickToClose="false" :width="663" :height="375">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">
                            Associa Corsi
                        </v-col>

                    </v-row>

                    <v-row style="margin-top:0px">

                        <v-col cols="12" md="12" style="display:flex">

                            <div style="font-weight:bold;margin-left:10px;width:85px">Partecipante:</div>
                            <div style="font-weight:bold">
                                {{ partecipanteSelezionato }}
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="    font-weight: bold;
                                                margin-left: 10px;
                                                background: rgba(128, 128, 128, 0.55);
                                                margin-right: 11px;
                                                height: 20px;
                                                padding-left: 5px;
                                                padding-top: 3px;">Corsi</div>

                        </v-col>


                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display:flex">

                            <div style="margin-left:10px;width:50px">&nbsp;</div>
                            <div style="font-weight:bold;width:80px">Codice</div>
                            <div style="font-weight:bold;width:200px">Descrizione</div>
                            <div style="font-weight:bold;width:100px">Data Inizio</div>
                            <div style="font-weight:bold;width:100px">Data Fine</div>
                            <div style="font-weight:bold;width:60px;text-align:right">Ore</div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top:0px">

                        <v-col cols="12" md="12" style="margin-top:0px">


                            <v-list style="overflow-y:auto;max-height: 166px;min-height: 166px;">

                                <v-list-tile v-for="el in corsi" :key="el.id">

                                    <div class="contSecCheck" style="display:flex!important;height:30px;margin-top:4px">

                                        <div style="margin-left:10px;width:50px;margin-top:-30px">
                                            <v-checkbox v-model="idResSel" multiple class="cl-checkbox selsec"
                                                :value="el.id" />

                                        </div>


                                        <div style="width:80px">
                                            {{ el.codice }}
                                        </div>

                                        <div style="width:200px">
                                            {{ el.descrizione }}
                                        </div>

                                        <div style="width:100px">
                                            {{ el.data_inizio }}
                                        </div>

                                        <div style="width:100px">
                                            {{ el.data_fine }}
                                        </div>

                                        <div style="width:60px;text-align: right">
                                            {{ el.ore_totali }}
                                        </div>

                                    </div>

                                </v-list-tile>


                            </v-list>


                        </v-col>


                    </v-row>







                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-assoc-corsi" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelAssocCorsi" />

                                <v-img v-if="isReadOnly == 0" alt="" class="btn-confirm-assoc-corsi" contain
                                    src="@/assets/btn_confirm_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmAssocCorsi" />

                                <v-img v-if="isReadOnly == 1" alt="" class="btn-confirm-assoc-corsi" contain
                                    src="@/assets/btn_confirm_smartform.png" max-width="30" max-height="30"
                                    style="cursor:default;opacity: 0.5;" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmAssocCorsi" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupdatefrom" style="display:none">

            <template>
                <modal name="popupDateFrom" :clickToClose="false" :width="300" :height="222">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataDa" v-model="dataDa" label="Dal" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-from" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelDateFrom" />


                                <v-img alt="" class="btn-reset-date-from" contain src="@/assets/reset.png" max-width="25"
                                    max-height="25" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" @click="btnResetDateFrom" />

                                <v-img alt="" class="btn-confirm-date-from" contain src="@/assets/btn_confirm_smartform.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmDateFrom" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupdateto" style="display:none">

            <template>
                <modal name="popupDateTo" :clickToClose="false" :width="300" :height="222">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataAl" v-model="dataAl" label="Al" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataAl" no-title @input="showPicker4 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-to" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelDateTo" />


                                <v-img alt="" class="btn-reset-date-to" contain src="@/assets/reset.png" max-width="25"
                                    max-height="25" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" @click="btnResetDateTo" />

                                <v-img alt="" class="btn-confirm-date-to" contain src="@/assets/btn_confirm_smartform.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmDateTo" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contcorsi">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetCorsi" class="vcorsisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Corsi
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetCorsi = !sheetCorsi">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="searchCorsi">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredCorsi" :key="item.codice"
                                        @click="manageClickCorsi(item)">

                                        <v-img alt="" src="@/assets/corsi_smartform.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.descrizione }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpopupperc" style="display:none">

            <template>
                <modal name="popupPerc" :clickToClose="false" :width="300" :height="222">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Percentuale Riconosciuta

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div id="d_percentuale"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute"></label>

                                            <vue-autonumeric v-model="percRiconosciuta"
                                                :options="'integer'"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-perc" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelPerc" />


                                <v-img alt="" class="btn-confirm-perc" contain src="@/assets/btn_confirm_smartform.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmPerc" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </div>
</template>

<script>

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';


import apirisorse from "../utils/risorse/apirisorse";
import apiusers from "../utils/users/apiusers";


import {
    bus
} from "../main";
import router from ".././router";
import apipartecipanti from "../utils/partecipanti/apipartecipanti";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    components: {
        VueAutonumeric,
    },


    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.isReadOnly = this.$root.$children[0].getValueReadOnly("partecipanti_smartform_ro");

        console.log("IS RO: ", this.isReadOnly);

        var curYear = new Date().getFullYear();
        var curMonth = new Date().getMonth() + 1;

        console.log("CUR YEAR: ", curYear);

        console.log("CUR MONTH: ", curMonth);

        this.strTotali = "Totali";

        if (window.innerWidth <= 768) {

            this.strTotali = "";


        }

        window.curYear = curYear;
        window.curMonth = curMonth;

        window.periodoDal = "";
        window.periodoAl = "";

        window.curCorso = "";

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        window.curSrcText = "";
        window.curCompany = window.$cookies.get("sel_filter_partecipanti");

        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {


            $('body').on('click', '.selperiododal', function (event) {
                console.log(event);


                $(".contpopupdatefrom").show();

                pointerVue.$modal.show('popupDateFrom');



            });



            $('body').on('click', '.selperiodoal', function (event) {
                console.log(event);


                $(".contpopupdateto").show();

                pointerVue.$modal.show('popupDateTo');



            });





            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

            });



            $('body').on('click', '.selcorso', function () {

                console.log(("EVENTO CLICK SELCORSO"));

                pointerVue.sheetCorsi = true;

            });







            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });


            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {

            this.syncAziende();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;

            var ids = "";

            console.log(data);
            switch (data) {

                case "btn_delete":


                    this.deleteRow();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editPartecipante/0"
                    });
                    break;

                case "btn_edit":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editPartecipante/" + ids
                    });

                    break;

                case "btn_back":
                    router.push({
                        path: "/filterRisorse"
                    });
                    break;

                case "btn_corsi":

                    this.associaCorsi();

                    break;


                case "btn_perc":

                    this.percRiconosciuta = null;

                    $(".contpopupperc").show();

                    this.$modal.show('popupPerc');


                    break;





                default:
                    break;

            }
        });


        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({

        isReadOnly: 0,

        percRiconosciuta: null,

        strTotali: "",

        sheetCorsi: false,

        dataDa: null,
        showPicker3: false,

        dataAl: null,
        showPicker4: false,

        idResSel: [],

        corsi: [],

        partecipanteSelezionato: "",

        aziende: [],

        sheetAziende: false,
        search: "",

        textSearch: "",

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredCorsi() {
            return _.orderBy(this.corsi.filter(item => {
                if (!this.searchCorsi) return this.corsi;
                return (item.descrizione.toLowerCase().includes(this.searchCorsi.toLowerCase()));
            }), 'headline')
        },




    },

    methods: {

        convOreInMinuti: function (v_ore) {

            console.log("VAL ORE: " + v_ore);

            var elements = v_ore.split(",");

            console.log(elements);

            var result = (parseInt(elements[0]) * 60) + parseInt(elements[1]);

            console.log("CON ORE RESULT: " + result);

            return result;

        },

        convMinutiInOre: function (v_minuti) {

            var v_hours = Math.floor(v_minuti / 60);
            var v_minutes = v_minuti % 60;
            if (v_minutes < 10) {
                v_minutes = "0" + v_minutes.toString();
            }
            var result = v_hours.toString() + "." + v_minutes.toString();

            return result;
        },


        btnCancelPerc: function () {

            this.$modal.hide('popupPerc');

        },


        btnConfirmPerc: function () {

            var errore = "";

            if (this.percRiconosciuta == null) {

                errore = "Specificare un valore";

            }

            if (errore == "") {

                if (this.percRiconosciuta == 0) {

                    errore = "Il valore deve essere maggiore di 0 e minore o uguale a 100";

                }

            }

            if (errore == "") {

                if (this.percRiconosciuta > 100) {

                    errore = "Il valore deve essere maggiore di 0 e minore o uguale a 100";

                }

            }

            if (errore == "") {

                console.log("ok");

                this.setPerc();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }



        },




        btnCancelDateFrom: function () {

            this.$modal.hide('popupDateFrom');

        },

        btnResetDateFrom: function () {

            this.dataDa = null;


        },

        btnConfirmDateFrom: function () {

            window.periodoDal = this.dataDa;

            this.$modal.hide('popupDateFrom');

        },




        btnCancelDateTo: function () {

            this.$modal.hide('popupDateTo');

        },

        btnResetDateTo: function () {

            this.dataAl = null;


        },

        btnConfirmDateTo: function () {

            window.periodoAl = this.dataAl;

            this.$modal.hide('popupDateTo');

        },






        associaCorsi: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipartecipanti.getCorsiAssoc(
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getCorsiAssoc", res);


                that.partecipanteSelezionato = res.data.partecipante.nome + " " + res.data.partecipante.cognome;

                that.corsi = res.data.array_corsi;


                that.idResSel = [];

                for (var x = 0; x <= res.data.array_corsi.length - 1; x++) {

                    if (res.data.array_corsi[x].selected == 1) {

                        that.idResSel.push(res.data.array_corsi[x].id);


                    }


                }

                console.log("ID SELECTED: ", that.idResSel);


                setTimeout(() => {

                    $(".contpopupcorsiassoc").show();

                    that.$modal.show('popupCorsiAssoc');


                    // setTimeout(() => {


                    //     if (that.isReadOnly == 1) {


                    //         $("#btn-confirm-assoc-corsi").css("cursor", "default");



                    //     }



                    // }, 300);








                }, 200);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        btnCancelAssocCorsi: function () {

            this.$modal.hide('popupCorsiAssoc');

        },

        btnConfirmAssocCorsi: function () {

            if (this.isReadOnly == 0) {

                this.saveAssocCorsi();

            }


        },


        saveAssocCorsi: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });


            console.log("ID PARTECIPANTE: ", ids);

            var id_corsi_sel = "";

            for (var x = 0; x <= that.idResSel.length - 1; x++) {

                if (id_corsi_sel != "") {
                    id_corsi_sel = id_corsi_sel + ",";
                }
                id_corsi_sel = id_corsi_sel + that.idResSel[x].toString();

            }

            console.log("ID CORSI SEL: ", id_corsi_sel);

            that.$modal.hide('popupCorsiAssoc');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipartecipanti.saveCorsiAssoc(
                id_corsi_sel,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveCorsiAssoc", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );





        },


        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziendeSF(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziendeSF", res);

                try {

                    this.aziende = res.data.Result;
                    this.corsi = res.data.corsi;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;



            window.$cookies.set("sel_filter_partecipanti", azienda.companyid, "9y");



            var vDescAzi = "";

            for (var xx = 0; xx < this.aziende.length; xx++) {

                if (parseInt(this.aziende[xx].companyid) == parseInt(azienda.companyid)) {

                    vDescAzi = this.aziende[xx].fld_name;

                }


            }


            window.$cookies.set("sel_name_azi_partecipanti", vDescAzi, "9y");



            window.table.ajax.reload();


        },


        manageClickCorsi: async function (corso) {

            console.log("CORSO SEL: ", corso);

            this.sheetCorsi = false;

            window.curCorso = corso.codice;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            var desc_corso = "Corso: " + corso.descrizione.toString().toUpperCase();

            $("#corsoSel").html(desc_corso);

            //var desc_corso2 = "Periodo Dal " + corso.data_inizio + " Al " + corso.data_fine; 

            var desc_corso2 = "Periodo Dal " + corso.data_inizio;



            $("#perCorsoSelFrom").html(desc_corso2);



            var desc_corso3 = "Periodo Al " + corso.data_fine;



            $("#perCorsoSelTo").html(desc_corso3);





            // corsoSel





            window.table.ajax.reload();


        },


        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;


                }

            });

            console.log("TOT SEL: ", tot);

            setTimeout(() => {

                var pulsantis = [];


                if (tot == 1) {


                    pulsantis.push(

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            width: 30,
                            title: "Modifica"


                        }

                    );

                    pulsantis.push(

                        {
                            text: "Corsi",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/corsi_smartform.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_corsi",
                            disabled: false,
                            width: 30,
                            title: "Associa Corsi",

                        }

                    );

                }

                if (tot > 0) {


                    pulsantis.push(

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                            //   class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            width: 30,
                            title: "Elimina"


                        }

                    );


                    pulsantis.push(

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/perc_smartform.png",
                            //   class: "inverted",
                            link: "/info",
                            id: "btn_perc",
                            disabled: false,
                            width: 20,
                            title: "Percentuale"


                        }

                    );

                }






                this.$root.$children[0].bnavbuttons = pulsantis;


                setTimeout(() => {



                    if (this.isReadOnly == 1) {


                        $('#btn_add').prop('disabled', true)
                        $('#btn_add img').css("opacity", "0.5");


                        $('#btn_edit').prop('disabled', true)
                        $('#btn_edit img').css("opacity", "0.5");

                        $('#btn_delete').prop('disabled', true)
                        $('#btn_delete img').css("opacity", "0.5");

                        $('#btn_perc').prop('disabled', true)
                        $('#btn_perc img').css("opacity", "0.5");

                    }



                }, 200);



            }, 100);



        },


        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);

        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;

            var filtri = ``;

            if (window.innerWidth <= 768) {


                filtri = `<img src='/smartform/img/aziende_smartform.png' style='border:0!important;' class='selazienda' title='Aziende' />`;

            }
            else {


                filtri = `<img src='/smartform/img/aziende_smartform.png' style='border:0!important;' class='selazienda' title='Aziende' /><div id='aziSel' style='font-weight:bold;margin-left:15px'></div><div id='corsoSel' style='font-weight:bold;margin-left:45px'></div><div id='perCorsoSelFrom' style='font-weight:bold;margin-left:45px'></div><div id='perCorsoSelTo' style='font-weight:bold;margin-left:45px'></div>`;

            }



            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                // var v_company = window.$cookies.get("sel_filter_risorse");

                // window.curCompany = v_company;
                window.curStatus = "S"; // in forza

                window.columns = [{
                    data: 'sel_row',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },

                {
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },

                {
                    data: 'cognome',
                    orderable: false,
                    name: 'cognome',
                    width: '4%'
                },

                {
                    data: 'nome',
                    orderable: false,
                    name: 'nome',
                    width: '4%'
                },



                {
                    data: 'tipo_contratto_impiegato',
                    orderable: false,
                    name: 'tipo_contratto_impiegato',
                    width: '4%'
                },

                {
                    data: 'insegnante',
                    orderable: false,
                    name: 'insegnante',
                    width: '1%'
                },

                {
                    data: 'esterno',
                    orderable: false,
                    name: 'esterno',
                    width: '1%'
                },

                // {
                //     data: 'costo_giornaliero',
                //     orderable: false,
                //     name: 'costo_giornaliero',
                //     width: '3%'
                // },

                {
                    data: 'costo_ora',
                    orderable: false,
                    name: 'costo_ora',
                    width: '3%'
                },

                {
                    data: 'perc_riconosciuta',
                    orderable: false,
                    name: 'perc_riconosciuta',
                    width: '3%'
                },


                {
                    data: 'ore_prev',
                    orderable: false,
                    name: 'ore_prev',
                    width: '3%'
                },

                // {
                //     data: 'giorni_prev',
                //     orderable: false,
                //     name: 'giorni_prev',
                //     width: '3%'
                // },

                // {
                //     data: 'totale_prev',
                //     orderable: false,
                //     name: 'totale_prev',
                //     width: '5%'
                // },

                {
                    data: 'totale_hh_prev',
                    orderable: false,
                    name: 'totale_hh_prev',
                    width: '5%'
                },

                // {
                //     data: 'perc_totale_prev',
                //     orderable: false,
                //     name: 'perc_totale_prev',
                //     width: '5%'
                // },


                {
                    data: 'perc_totale_hh_prev',
                    orderable: false,
                    name: 'perc_totale_hh_prev',
                    width: '5%'
                },

                ];


                $(".spin").show();



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("SFGetPartecipantiApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curCompany = window.curCompany;
                            valori.curStatus = window.curStatus;

                            valori.curSrcText = window.curSrcText;

                            valori.curCompany = window.curCompany;

                            valori.curCorso = window.curCorso;

                            valori.periodoDal = window.periodoDal;

                            valori.periodoAl = window.periodoAl;

                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);



                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {


                            pointerVue.$root.$children[0].showProgress = false;


                        }, 700);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();



                        $('.p_costo_prev, .p_ore_prev, .p_giorni_prev, .p_totale_prev, .p_perc_totale_prev, .p_totale_hh_prev, .p_perc_totale_hh_prev').number(true, 2, ',', '.');


                        // var tot_ore_prev = 0;
                        // $(".p_ore_prev").each(function () {
                        //     tot_ore_prev = tot_ore_prev + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        // });

                        var totale_minuti_ore_prev = 0;
                        $(".p_ore_prev").each(function () {
                            totale_minuti_ore_prev = totale_minuti_ore_prev + pointerVue.convOreInMinuti($(this).text());
                        });

                        console.log("SUM MIN ORE PREV: ", totale_minuti_ore_prev);

                        var totale_min_ore_prev = pointerVue.convMinutiInOre(totale_minuti_ore_prev);

                        console.log("CONV MIN IN ORE ORE PREV: ", totale_min_ore_prev);








                        var tot_gg_prev = 0;
                        $(".p_giorni_prev").each(function () {
                            tot_gg_prev = tot_gg_prev + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var tot_costo_prev = 0;
                        $(".p_totale_prev").each(function () {
                            tot_costo_prev = tot_costo_prev + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var tot_perc_costo_prev = 0;
                        $(".p_perc_totale_prev").each(function () {
                            tot_perc_costo_prev = tot_perc_costo_prev + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });


                        var tot_costo_hh_prev = 0;
                        $(".p_totale_hh_prev").each(function () {
                            tot_costo_hh_prev = tot_costo_hh_prev + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });



                        var tot_perc_costo_hh_prev = 0;
                        $(".p_perc_totale_hh_prev").each(function () {
                            tot_perc_costo_hh_prev = tot_perc_costo_hh_prev + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });






                        $("#v_tot_ore").text(totale_min_ore_prev.toString().replace(".", ","));
                        $('#v_tot_ore').number(true, 2, ',', '.');





                        $("#v_tot_gg_prev").text(tot_gg_prev.toString().replace(".", ","));
                        $('#v_tot_gg_prev').number(true, 2, ',', '.');

                        $("#v_tot_costo_prev").text(tot_costo_prev.toString().replace(".", ","));
                        $('#v_tot_costo_prev').number(true, 2, ',', '.');

                        $("#v_tot_perc_costo_prev").text(tot_perc_costo_prev.toString().replace(".", ","));
                        $('#v_tot_perc_costo_prev').number(true, 2, ',', '.');

                        $("#v_tot_costo_hh_prev").text(tot_costo_hh_prev.toString().replace(".", ","));
                        $('#v_tot_costo_hh_prev').number(true, 2, ',', '.');

                        $("#v_tot_perc_costo_hh_prev").text(tot_perc_costo_hh_prev.toString().replace(".", ","));
                        $('#v_tot_perc_costo_hh_prev').number(true, 2, ',', '.');

                        setTimeout(() => {

                            var vDescAzi = window.$cookies.get("sel_name_azi_partecipanti");

                            $("#aziSel").html("Azienda: " + vDescAzi);


                        }, 200);









                    }

                });





                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("SFGetPartecipantiApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();

                if (window.innerWidth <= 768) {

                    //         $("#myTable_filter").prepend(`
                    //     <div class="cont_buttons">

                    //         <div class="text_title">Previsionale</div>
                    //         ` + filtri + `

                    //     </div>
                    //     <div><img src='/smartform/img/ricerca.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

                    // `);

                    $("#myTable_filter").prepend(`
                <div style='display:table'>
                    <div style='display:table-row'>
                        <div style='display:table-cell'>
                            <div style='display:flex'>
                                <div class="cont_buttons">

                    <div class="text_title">Previsionale</div>
                    ` + filtri + `

                </div>
                <div style='transform: translateX(187px);'><img src='/smartform/img/ricerca.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>
                </div></div></div><div style='display:table-row'><div style='display:table-cell'><div style='display:flex'><div id='aziSel' style='font-weight:bold;margin-left:10px'></div><div id='corsoSel' style='font-weight:bold;margin-left:34px'></div></div></div></div></div>
              

            `);


                }
                else {

                    $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Previsionale</div>
                    ` + filtri + `

                </div>
                <div><img src='/smartform/img/ricerca.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);


                }



                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();
                    window.curStatus = this.value;



                    window.table.ajax.reload();

                });


                $('body').on('click', '.checkall', function () {

                    console.log("CHECKALL: ", $(this).prop("checked"));

                    $(".val_status").prop("checked", $(this).prop("checked"));



                });


                $('body').on('click', '.selpartecipanti', function () {


                    var ids = "";

                    $(".val_status").each(function (index, val) {

                        if ($(val).prop("checked")) {

                            var id = $(val).attr("data-id");

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + id;

                        }

                    });


                    console.log("IDS: ", ids);


                    if (ids != "") {

                        console.log("ok");

                        pointerVue.initPartecipanti(ids);

                    }
                    else {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Selezionare uno o più dipendenti/collaboratori",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }


                });







                $('body').on('click', '.cont_partecipanti tr', function () {

                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }



                    // setTimeout(() => {

                    //     var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                    //     var id_sel = $(this).find(".id_row").attr("data-id");

                    //     $("tr").removeClass("active-row");

                    //     var set_cls_active = 0;

                    //     if (last_id_sel == "undefined") {
                    //         set_cls_active = 1;
                    //     } else {
                    //         if (last_id_sel != id_sel) {
                    //             set_cls_active = 1;
                    //         }
                    //     }

                    //     if (set_cls_active == 1) {

                    //         $(this).addClass("active-row");

                    //         window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                    //         console.log("ID: ", window.id_par_sel);

                    //         pointerVue.hideShowButtons(true);

                    //     } else {

                    //         pointerVue.hideShowButtons(false);

                    //     }

                    // }, 100);

                });



                window.parentiAddedEvents = true;

            });

        },

        initPartecipanti: async function (list_id) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apirisorse.initPartecipanti(
                list_id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initPartecipanti", res);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];



                if (v_enable == true) {

                    pulsantis = [


                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            width: 30,
                            title: "Modifica"

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            width: 30,
                            title: "Elimina"

                        },



                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    // {
                    //     text: "Add",
                    //     icon: "mdi-home-circle",
                    //     image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                    //     //class: "inverted",
                    //     link: "/settings",
                    //     id: "btn_add",
                    //     disabled: false,
                    //     width: 30,
                    //     title: "Aggiungi"

                    // },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                        // class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30,
                        title: "Modifica"

                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                        // class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30,
                        title: "Elimina"

                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },



        setPerc: async function () {


            var that = this;

            that.$modal.hide('popupPerc');

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipartecipanti.setPerc(
                ids,
                that.percRiconosciuta
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setPerc", res);

                that.$swal({
                    icon: "success",
                    text: "Percentuale impostata correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        deleteRow: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apipartecipanti.deletePartecipanti(
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deletePartecipanti", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #00a6ff;

}

.pay a {
    color: #00a6ff;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #00a6ff;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}


ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.colcontTable {
    overflow-x: auto !important;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_partecipanti #myTable {
    margin-right: 3px;
}

.cont_partecipanti table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_partecipanti table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_partecipanti th {
    padding-left: 5px !important;
}

.cont_partecipanti ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_partecipanti .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}

.cont_partecipanti #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #0084f3;
}

.cont_partecipanti div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_partecipanti #myTable_wrapper {
    padding-top: 0px;
}

.cont_partecipanti .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_partecipanti .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_partecipanti .selectstatusall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/emt/img/Filtro.png);
    text-indent: -119px;
    background-size: 24px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -5px !important;

}


.cont_partecipanti .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: black !important;
}


.cont_partecipanti .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_partecipanti .checkall {
    width: 13px;
    height: 13px;
}

.cont_partecipanti .val_status {
    width: 10px !important;
}


.cont_partecipanti .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cl-checkbox i {
    font-size: 13px !important;
}

.cont_partecipanti .selazienda {
    max-width: 30px;
    min-height: 30px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 30px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemAzienda {
    margin-left: 15px;
}


.selpartecipanti {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_partecipanti .selanno {
    max-width: 24px !important;
    min-height: 27px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 22px !important;
    text-indent: -119px !important;
    background-size: 27px !important;
    background-repeat: no-repeat !important;
    background-image: url(/smartform/img/calendario-giorni-smartform.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_partecipanti .selmese {
    max-width: 24px !important;
    min-height: 27px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 22px !important;
    text-indent: -119px !important;
    background-size: 27px !important;
    background-repeat: no-repeat !important;
    background-image: url(/smartform/img/calendario-mesi-smartform.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_partecipanti .selcorso {
    max-width: 32px;
    min-height: 32px;
    margin-left: 11px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 32px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 1px;
}



.cont_partecipanti .selperiododal {
    max-width: 27px;
    min-height: 27px;
    margin-left: 11px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 27px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: --2px;
}

.cont_partecipanti .selperiodoal {
    max-width: 27px;
    min-height: 27px;
    margin-left: 11px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 27px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: --2px;
}


.cont_partecipanti .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_partecipanti .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}



@media screen and (max-width: 960px) {

    .cont_partecipanti .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_partecipanti #myTable {
        /* margin-top:54px; */
    }

    .cont_partecipanti #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_partecipanti .val_status {
        width: 10px !important;
        margin-top: 3px !important
    }


}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_partecipanti #myTable {
        max-width: 99vw !important;
        margin-right: 0px;
        min-width: 99vw !important;
        position: relative;
        width: 99vw !important;

    }

    .cont_partecipanti #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_partecipanti .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_partecipanti #myTable_wrapper {
        min-width: initial;
        min-width: 99vw !important;
        max-width: 99vw !important;
        width: 99vw !important;
        overflow-x: hidden !important;
    }

    .cont_partecipanti .cl_num {
        margin-left: -3px !important;
    }

    .cl_n_matricola {
        display: inline-flex;
    }

    .cl_desc_project {
        display: inline-flex;
    }

    .cl_costo_giornaliero {
        display: inline-flex;
    }

    .cl_diaria {
        display: inline-flex;
    }

    .cl_ticket {
        display: inline-flex;
    }

    .cl_piano_orario {
        display: inline-flex;
    }

    .cl_ebadge {
        display: inline-flex;
    }

    .cl_totale_ferie {
        display: inline-flex;
    }

    .cont_partecipanti .cl_checkall {
        margin-left: 19px
    }


}
</style>