import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIPianoOrario {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async initInsert(user)
  {

    let formData = new FormData();

    formData.append("username",  user);
    
   
    return axios.post(this.getUrlDomain("EmtInitAddPianoOrarioApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async insPianoOrario(pianoorario)
  {

    let formData = new FormData();

    for ( var key in pianoorario ) {
      formData.append(key, pianoorario[key]);
   }
    
   
    return axios.post(this.getUrlDomain("EmtInsPianoOrarioApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getPianoOrario(id, user)
  {

    return axios.get(this.getUrlDomain("EmtGetPianoOrarioApi") + "/" + id + "?api=1&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updPianoOrario(pianoorario, id)
  {

    let formData = new FormData();

    for ( var key in pianoorario ) {
      formData.append(key, pianoorario[key]);
   }
    
   
    return axios.post(this.getUrlDomain("EmtUpdPianoOrarioApi") + "/" + id, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deletePianoOrario(id)
  {


    return axios.get(this.getUrlDomain("EmtDelPianoOrarioApi") + "?id=" + id,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getAziende(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

   
    return axios.post(this.getUrlDomain("EmtPianoOrarioGetAziendeApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



}

export default new APIPianoOrario({
  url: "https://services.ebadge.it/public/api/"
})
