<template>
    <div>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">

                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <!-- START -->
                                <div class="sidemenu__sub-items-container" id="menu-12">


                                    <div class="sidemenu__group-container">
                                        <div v-if="viewModuli == 1" class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezArgomenti" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Argomenti</div>


                                        </div>

                                        <div style="border-top: 1px solid #00a6ff;" v-if="viewSezArgomenti == 'S'">

                                            <ul class="group__items">


                                                <a v-if="viewModuli == 1" @click="goto('/moduli')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/moduli_smartform.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Moduli</span>

                                                </a>


                                            </ul>

                                        </div>


                                    </div>


                                    <div class="sidemenu__group-container">
                                        <div v-if="viewDipCollab == 1" class="group__text clRowSectionMenu">


                                            <div>
                                                <v-switch v-model="viewSezDipColl" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Dipendenti / Collaboratori
                                            </div>


                                        </div>

                                        <div style="border-top: 1px solid #00a6ff;" v-if="viewSezDipColl == 'S'">

                                            <ul class="group__items">



                                                <a v-if="viewDipCollab == 1" @click="goto('/filterRisorse')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/dipendenti_smartform.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Dip. / Collab.</span>

                                                </a>



                                            </ul>


                                        </div>


                                    </div>

                                    <div class="sidemenu__group-container">
                                        <div v-if="((viewCorsiOnline == 1) || (viewFlussoCorsiOnline == 1) || (viewConfigDomandeCorsiOnline == 1) || (viewConfigRisposteCorsiOnline == 1) || (viewConfigPesiRisposteCorsiOnline == 1))" class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezCorsiOnline" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Corsi Online</div>


                                        </div>

                                        <div style="border-top: 1px solid #00a6ff;" v-if="viewSezCorsiOnline == 'S'">

                                            <ul class="group__items">



                                                <a v-if="viewCorsiOnline == 1" @click="goto('/initCorsiOnlineUser')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/corsi_online_smartform.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Corsi</span>

                                                </a>


                                                <a v-if="viewFlussoCorsiOnline == 1" @click="goto('/filterCorsiFlusso')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/flusso_corsi_online_smartform.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Flusso</span>

                                                </a>



                                                <a v-if="viewConfigDomandeCorsiOnline == 1" @click="goto('/filterCorsiDomande')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/corsi_online_domande_smartform.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Config. Domande</span>

                                                </a>


                                                <a v-if="viewConfigRisposteCorsiOnline == 1" @click="goto('/filterCorsiRisposte')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/corsi_online_risposte_smartform.png"
                                                        max-width="32" max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Config. Risposte</span>

                                                </a>


                                                <a v-if="viewConfigPesiRisposteCorsiOnline == 1" @click="goto('/filterCorsiPesiRisposte')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/corsi_online_risposte_pesi_smartform.png"
                                                        max-width="32" max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Config. Pesi Risposte</span>

                                                </a>



                                            </ul>


                                        </div>


                                    </div>



                                    <div class="sidemenu__group-container">
                                        <div v-if="viewCalendario == 1 || viewCorsi == 1 || viewRevisore == 1 || viewDocumenti == 1"
                                            class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezFormazione" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Formazione</div>


                                        </div>

                                        <div style="border-top: 1px solid #00a6ff;" v-if="viewSezFormazione == 'S'">

                                            <ul class="group__items">

                                                <a v-if="viewCalendario == 1" @click="goto('/calendario')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/calendario_smartform.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Calendario</span>

                                                </a>

                                                <a v-if="viewCorsi == 1" @click="goto('/filterCorsi')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/corsi_smartform.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Corsi</span>

                                                </a>




                                                <a v-if="viewDocumenti == 1" @click="goto('/filterDocumenti')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/documenti_smartform.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Documenti</span>

                                                </a>


                                            </ul>

                                        </div>


                                    </div>


                                    <div class="sidemenu__group-container">
                                        <div v-if="viewUtentiAziende == 1" class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezImpostazioni" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Impostazioni</div>


                                        </div>

                                        <div style="border-top: 1px solid #00a6ff;" v-if="viewSezImpostazioni == 'S'">

                                            <ul class="group__items">


                                                <a v-if="viewUtentiAziende == 1" @click="goto('/filterUtentiAziende')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/utenti_aziende_smartform.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Utenti / Aziende</span>

                                                </a>





                                            </ul>

                                        </div>


                                    </div>



                                    <div class="sidemenu__group-container">
                                        <div v-if="viewOreLUL == 1" class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezLUL" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">LUL</div>


                                        </div>

                                        <div style="border-top: 1px solid #00a6ff;" v-if="viewSezLUL == 'S'">

                                            <ul class="group__items">



                                                <a v-if="viewOreLUL == 1" @click="goto('/filterOreLUL')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/partecipanti_smartform2.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Ore</span>

                                                </a>



                                            </ul>

                                        </div>


                                    </div>





                                    <div class="sidemenu__group-container">
                                        <div v-if="viewDipCollab == 1 || viewPrevisionale == 1 || viewCostiAziendali == 1"
                                            class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezPartecipanti" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Partecipanti</div>


                                        </div>

                                        <div style="border-top: 1px solid #00a6ff;" v-if="viewSezPartecipanti == 'S'">

                                            <ul class="group__items">


                                                <a v-if="viewCostiAziendali == 1" @click="goto('/filterCostiAziendali')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/costo_azi_smartform.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Costi Aziendali</span>

                                                </a>


                                                <a v-if="viewPartecipanti == 1" @click="goto('/filterPartecipanti')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/partecipanti_smartform2.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Partecipanti</span>

                                                </a>

                                                <a v-if="viewPrevisionale == 1" @click="goto('/filterPrevisionale')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/partecipanti_smartform.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Previsionale</span>

                                                </a>





                                            </ul>

                                        </div>


                                    </div>









                                    <div class="sidemenu__group-container">
                                        <div class="group__text" style="border-color: #00a6ff;display:flex;justify-items: center;height: 40px;padding-top: 10px;">
                                            <div style="margin-left:5px">Applicazione</div>
                                        </div>
                                        <ul class="group__items">

                                            <a @click="goto('/dash')" mid="mnu_gest_company" class="sidemenu__subitem"
                                                href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/home_smartform.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">Home</span>
                                            </a>

                                            <a @click="goto('/info')" mid="mnu_gest_company" class="sidemenu__subitem"
                                                href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/info_smartform.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Info</span>
                                            </a>


                                            <a @click="logout" mid="mnu_gest_company" class="sidemenu__subitem"
                                                href="#">
                                                <v-img alt="Logout" class="  " contain src="@/assets/exit_smartform.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Exit</span>
                                            </a>

                                        </ul>
                                    </div>

                                </div>

                                <!-- END -->

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>
</template>

<script>
//import $ from 'jquery';
import _ from 'lodash';

export default {
    name: "menubottom",
    props: {},

    mounted: function () {

        this.setGlobal();

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }

        if (this.isSU == 0) {

            this.viewCalendario = this.getEnableConf("calendario_smartform");
            this.viewCorsi = this.getEnableConf("corsi_smartform");
            this.viewModuli = this.getEnableConf("moduli_smartform");
            this.viewDipCollab = this.getEnableConf("dip_collab_smartform");
            this.viewPrevisionale = this.getEnableConf("partecipanti_smartform");
            this.viewPartecipanti = this.getEnableConf("partecipanti2_smartform");
            this.viewUtentiAziende = this.getEnableConf("utenti_azi_smartform");
            this.viewRevisore = this.getEnableConf("revisore_smartform");
            this.viewCostiAziendali = this.getEnableConf("costi_azi_smartform");
            this.viewOreLUL = this.getEnableConf("ore_lul_smartform");
            this.viewCorsiOnline = this.getEnableConf("corsi_online_corsi");
            this.viewFlussoCorsiOnline = this.getEnableConf("corsi_online_flusso");
            this.viewConfigDomandeCorsiOnline = this.getEnableConf("corsi_online_conf_domande");
            this.viewConfigRisposteCorsiOnline = this.getEnableConf("corsi_online_conf_risposte");
            this.viewConfigPesiRisposteCorsiOnline = this.getEnableConf("corsi_online_conf_pesi_risp");      
            this.viewDocumenti = this.getEnableConf("formazione_documenti");
            this.viewCalendario = this.getEnableConf("formazione_calendario");
            this.viewCorsi = this.getEnableConf("formazione_corsi");




        }
        else {

            this.viewCalendario = 1;
            this.viewCorsi = 1;
            this.viewModuli = 1;
            this.viewDipCollab = 1;
            this.viewPrevisionale = 1;
            this.viewPartecipanti = 1;
            this.viewUtentiAziende = 1;
            this.viewRevisore = 1;
            this.viewCostiAziendali = 1;
            this.viewOreLUL = 1;
            this.viewDocumenti = 1;
            this.viewCorsiOnline = 1;
            this.viewFlussoCorsiOnline = 1;
            this.viewConfigDomandeCorsiOnline = 1;
            this.viewConfigRisposteCorsiOnline = 1;
            this.viewConfigPesiRisposteCorsiOnline = 1;
     
        }




    },
    data() {

        return {

            viewSezArgomenti: "N",
            viewSezDipColl: "N",
            viewSezCorsiOnline: "N",
            viewSezFormazione: "N",
            viewSezImpostazioni: "N",
            viewSezLUL: "N",
            viewSezPartecipanti: "N",


            viewCalendario: 0,
            viewCorsi: 0,
            viewModuli: 0,
            viewDipCollab: 0,
            viewPrevisionale: 0,
            viewPartecipanti: 0,
            viewUtentiAziende: 0,
            viewRevisore: 0,
            viewCostiAziendali: 0,
            viewOreLUL: 0,
            viewDocumenti: 0,


            viewCorsiOnline: 0,
            viewFlussoCorsiOnline: 0,
            viewConfigDomandeCorsiOnline: 0,
            viewConfigRisposteCorsiOnline: 0,
            viewConfigPesiRisposteCorsiOnline: 0,



            isAdmin: 0,
            isSU: 0,
            sheetAziende: false,
            currentFile: null,
            selecting: 0,
            currItem: {},
            currGruppo: {},
            switch1: true,
            nomeCorrente: "",
            titolo: "",
            body: "",
            aziende: [],
            gruppi: [],
            globalArrAziende: [],
            dipendenti: [],
            search: '',
            sheetGruppi: false,
            sheetEmpty: false,
            sheetDipendenti: false,
            searchDipendenti: '',
            searchGruppi: '',

        };
    },

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },
    created() {
        this.intervalHandle = setInterval(this.tick, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalHandle);
    },
    methods: {


        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            if (v_product_permissions != null) {


                var elements = v_product_permissions.split("|");

                for (var x = 0; x <= elements.length - 1; x++) {

                    if (elements[x] == v_codice) {

                        result = 1;

                    }


                }


            }



            return result;

        },



        logout: function () {
            this.sheetAziende = false;
            this.$root.$children[0].logout();
        },
        goto: function (link) {

            this.sheetAziende = false;
            return this.$router.push(link);
            //  this.$router.push('/profile')

        },
        setGlobal: function () {

            this.aziende = this.$root.$children[0].aziende;
            this.dipendenti = this.$root.$children[0].dipendenti;
            this.gruppi = this.$root.$children[0].gruppi;
            this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        },

        openmenu: function () {

            this.setGlobal();

            this.sheetAziende = true;
        },
        manageClose: function () {

            // this.setupButtons();
            this.sheetAziende = false;
            // this.sheetEmpty = false;
            this.sheetDipendenti = false;
            // this.sheetGruppi = false;

            this.$root.$children[0].bnavbuttons = this.$root.$children[0].old_bnavbuttons;

        },

    }
};
</script>

<style scoped>
/****** MENU ******/
.menu-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.menu__item .title,
.menu-icon .title {
    display: block;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .05em;
}

.menu__settings-icon {
    height: 26px !important;
    width: 26px !important;
    margin-bottom: 4px !important;
    margin-top: 2px !important;
}

#sidemenu {

    z-index: 2;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    position: fixed;
    top: 54px;
    left: 0;
    background: transparent;
    bottom: 56px;
    padding: 28px 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

#sidemenu.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

#sidemenu .sidemenu__inner-wrapper {
    overflow: hidden;
    background: white;
    height: 100%;
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
}

.sidemenu__top-level-item {
    width: 83px;
    text-align: center;
    border-bottom: 1px solid #efefef;
    border-right: 1px solid #efefef;
    opacity: 1;
}

sidemenu__top-level-item a {
    width: 80px;
}

.sidemenu__top-level-item>a {
    height: 80px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    padding: 8px;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
}

.sidemenu__top-level-item>a:before {
    position: absolute;
    top: 0;
    left: -3px;
    height: 100%;
    width: 3px;
    z-index: 1;
    content: '';
    display: block;
}

.sidemenu__top-level-item>a:hover {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    opacity: .7;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    display: block;
    margin-bottom: 4px;
    width: 30px;
}

.sidemenu__top-level-item:hover {
    background-color: #f9f9f9;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__icon {
    -webkit-filter: none;
    filter: none;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__text {
    opacity: 1;
}

.sidemenu__sub-items-container .group__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -1px;
    position: relative;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem,
.sidemenu__top-level-item>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    width: 84px
        /* 84px */
    ;
    height: 80px;
    position: relative;
    margin-right: -1px;
    margin-bottom: -1px;
    cursor: pointer;
}

.sidemenu__subitem {
    width: 120px !important;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem:hover {
    background: #f9f9f9;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem img,
.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    width: 28px;
    margin-bottom: 20px;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    margin-bottom: 20px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem .subitem__text,
.sidemenu__top-level-item .sidemenu__top-level-item__text {
    position: absolute;
    top: 48px;
    max-width: 123px;
    min-width: 123px;
    font-size: 9.5px;
    line-height: 1.05em;
    font-weight: 500;
    color: #404040;
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    top: 50px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    line-height: 1.1em;
    font-weight: 600;
    font-size: 9px;
}

.sidemenu__sub-items-container .group__text {
    background-color: #f9f9f9;
    font-weight: 700;
    padding: 20px 16px 6px 16px;
    padding: 1px;

    font-size: 12px;
    color: rgba(0, 0, 0, 0.78);
    letter-spacing: 0px;
    border-bottom: 2px solid #f3f3f3;
}

.sidemenu__sub-items-container.hidden {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.sidemenu__sub-items-container {
    z-index: 1;

    position: absolute;
    position: fixed;
    background: white;
    overflow-y: auto;
    width: 100vw;
    bottom: 0px;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-content: inherit;


    justify-content: start;

    bottom: 56px;

    max-height: calc(100vh - 120px);

}

.sidemenu__group-container ul {
    padding-left: 0px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container img {
    max-width: 26px;
}

.v-image__image.v-image__image--contain {
    transform: translateY(-6px);
}

.sidemenu__group-container .v-image.v-responsive.theme--light {
    margin-bottom: 22px;
}
</style>


<style>
.clRowSectionMenu {
    display: flex;
    justify-items: center;
    height: 40px;
    padding-top: 7px !important;
    border-color: #f9f9f9;
}

.v-input--switch__track {
    border-radius: 3px !important;
    width: 35px !important;
    height: 7px !important;
    top: 10px !important;
    left: 6px !important;
}

.v-input--switch__thumb {
    left: 6px !important;
    height: 15px !important;
    width: 15px !important;
    top: 5px !important;
}

.custom-switch .v-input__slot .v-label {
    left: 6px !important
}

.v-input--selection-controls__ripple {
    height: 0 !important;
    width: 0 !important;
}
</style>